import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import storyArcService from './storyArcService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  storyArcs: [],
  storyArc: {},
  storyArcError: false,
  storyArcSuccess: false,
  storyArcLoading: false,
  message: '',
}

// Create storyArc
export const createStoryArc = createAsyncThunk(
  'storyArcs/create',
  async (storyArc, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await storyArcService.createStoryArc(storyArc, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get all storyArcs
export const getStoryArcs = createAsyncThunk(
  'storyArcs/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await storyArcService.getStoryArcs(token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get storyArc
export const getStoryArc = createAsyncThunk(
  'storyArcs/get',
  async (storyArcId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await storyArcService.getStoryArc(storyArcId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Update storyArc
export const updateStoryArc = createAsyncThunk(
  'storyArcs/update',
  async (storyArc, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await storyArcService.updateStoryArc(
        storyArc.storyArcId,
        storyArc.storyArcData,
        token
      )
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Delete storyArc
export const deleteStoryArc = createAsyncThunk(
  'storyArcs/delete',
  async (storyArcId, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await storyArcService.deleteStoryArc(storyArcId, token)
  }
)

export const storyArcSlice = createSlice({
  name: 'storyArc',
  initialState,
  reducers: {
    reset: state => initialState,
    addNewStoryArcToState: (state, action) => {
      state.storyArcs.push(action.payload)
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createStoryArc.pending, state => {
        state.storyArcLoading = true
      })
      .addCase(createStoryArc.fulfilled, (state, action) => {
        state.storyArcLoading = false
        state.storyArcSuccess = true
        storyArcSlice.caseReducers.addNewStoryArcToState(state, action)
        state.storyArc = action.payload
      })
      .addCase(createStoryArc.rejected, (state, action) => {
        state.storyArcLoading = false
        state.storyArcError = true
        state.message = action.payload
        state.storyArc = null
      })
      .addCase(getStoryArcs.pending, state => {
        state.storyArcLoading = true
      })
      .addCase(getStoryArcs.fulfilled, (state, action) => {
        state.storyArcLoading = false
        state.storyArcSuccess = true
        state.storyArcs = action.payload
      })
      .addCase(getStoryArcs.rejected, (state, action) => {
        state.storyArcLoading = false
        state.storyArcError = true
        state.message = action.payload
      })
      .addCase(getStoryArc.pending, state => {
        state.storyArcLoading = true
      })
      .addCase(getStoryArc.fulfilled, (state, action) => {
        state.storyArcLoading = false
        state.storyArcSuccess = true
        state.storyArc = action.payload
      })
      .addCase(getStoryArc.rejected, (state, action) => {
        state.storyArcLoading = false
        state.storyArcError = true
        state.message = action.payload
      })
      .addCase(updateStoryArc.pending, state => {
        state.storyArcLoading = true
      })
      .addCase(updateStoryArc.fulfilled, (state, action) => {
        state.storyArcLoading = false
        state.storyArcSuccess = true
        state.storyArc = action.payload
      })
      .addCase(updateStoryArc.rejected, (state, action) => {
        state.storyArcLoading = false
        state.storyArcError = true
        state.message = action.payload
      })
      .addCase(deleteStoryArc.fulfilled, state => {
        state.storyArcSuccess = true
      })
  },
})

export const { reset, addNewStoryArcToState } = storyArcSlice.actions
export default storyArcSlice.reducer
