import axios from 'axios'

const backendURL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${backendURL}/api/v1/images/`

// Upload image
const uploadImage = async (file, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': file.type, // Set the Content-Type based on the file type
    },
  }

  const response = await axios.post(API_URL, file, config) // Pass the file directly as the request body
  return response.data
}

const imageService = {
  uploadImage,
}

export default imageService
