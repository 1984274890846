import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import eventService from './eventService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  events: [],
  event: {},
  eventError: false,
  eventSuccess: false,
  eventLoading: false,
  message: '',
}

// Create event
export const createEvent = createAsyncThunk(
  'events/create',
  async (event, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await eventService.createEvent(event, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get all events
export const getEvents = createAsyncThunk(
  'events/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await eventService.getEvents(token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get event
export const getEvent = createAsyncThunk(
  'events/get',
  async (eventId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await eventService.getEvent(eventId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Update event
export const updateEvent = createAsyncThunk(
  'events/update',
  async (event, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await eventService.updateEvent(
        event.eventId,
        event.eventData,
        token
      )
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Delete event
export const deleteEvent = createAsyncThunk(
  'events/delete',
  async (eventId, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await eventService.deleteEvent(eventId, token)
  }
)

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    reset: state => initialState,
    addNewEventToState: (state, action) => {
      state.events.push(action.payload)
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createEvent.pending, state => {
        state.eventLoading = true
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.eventLoading = false
        state.eventSuccess = true
        eventSlice.caseReducers.addNewEventToState(state, action)
        state.event = action.payload
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.eventLoading = false
        state.eventError = true
        state.message = action.payload
        state.event = null
      })
      .addCase(getEvents.pending, state => {
        state.eventLoading = true
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.eventLoading = false
        state.eventSuccess = true
        state.events = action.payload
      })
      .addCase(getEvents.rejected, (state, action) => {
        state.eventLoading = false
        state.eventError = true
        state.message = action.payload
      })
      .addCase(getEvent.pending, state => {
        state.eventLoading = true
      })
      .addCase(getEvent.fulfilled, (state, action) => {
        state.eventLoading = false
        state.eventSuccess = true
        state.event = action.payload
      })
      .addCase(getEvent.rejected, (state, action) => {
        state.eventLoading = false
        state.eventError = true
        state.message = action.payload
      })
      .addCase(updateEvent.pending, state => {
        state.eventLoading = true
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.eventLoading = false
        state.eventSuccess = true
        state.event = action.payload
      })
      .addCase(updateEvent.rejected, (state, action) => {
        state.eventLoading = false
        state.eventError = true
        state.message = action.payload
      })
      .addCase(deleteEvent.fulfilled, state => {
        state.eventSuccess = true
      })
  },
})

export const { reset, addNewEventToState } = eventSlice.actions
export default eventSlice.reducer
