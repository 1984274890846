import React, { useState } from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { BiSolidPlusCircle } from 'react-icons/bi';
import { HiXCircle } from 'react-icons/hi';

const BookFormats = () => {
  const [bookFormats, setBookFormats] = useState(['']);

  //   const updateBookFormats = (index, value) => {
  //     const newBookFormats = [...bookFormats];
  //     newBookFormats[index] = value;
  //     setBookFormats(newBookFormats);
  //   };
  const addBookFormat = () => {
    setBookFormats([...bookFormats, '']);
  };
  const removeBookFormat = (index) => {
    const newBookFormats = [...bookFormats];
    newBookFormats.splice(index, 1); // Odstranění řádku s daným indexem
    setBookFormats(newBookFormats);
  };

  return (
    <>
      <div className='book-format-box'>
        <label htmlFor='basicInfo' className='entity-label'>
          book formats
        </label>

        {!bookFormats?.length ? (
          <p className='input-label-info center-self'>
            {' '}
            No book formats added to this entity yet.
          </p>
        ) : (
          <>
            {bookFormats.map((bookFormat, index) => (
              <div className='book-format container-1' key={index}>
                <div>
                  <input
                    className='img-uploaded'
                    id='upload-img'
                    type='file'
                    accept='image/*,.png,.jpeg'
                  />
                  <label className='upload-label' htmlFor='upload-img'>
                    <div className='cover-upload__box'>
                      <FaCloudUploadAlt className='cover-upload__icon' />
                    </div>
                  </label>
                </div>

                <select
                  className='input-format'
                  id='format'
                  name='format'
                  autoComplete='off'
                >
                  <option value='Paperback'>Paperback</option>
                  <option value='Hardback'>Hardback</option>
                  <option value='Omnibus'>Omnibus</option>
                </select>

                <select
                  className='input-format'
                  id='type'
                  name='type'
                  autoComplete='off'
                >
                  <option value='Original'>Original</option>
                  <option value='Variant'>Variant</option>
                  <option value='Reissue'>Reissue</option>
                  <option value='Variant Reissue'>Reissue</option>
                </select>

                <input
                  type='text'
                  className='input-format'
                  id='isbn10'
                  placeholder='ISBN 10'
                  autoComplete='off'
                />

                <input
                  type='text'
                  className='input-format'
                  id='isbn13'
                  placeholder='ISBN 13'
                  autoComplete='off'
                />

                <input
                  type='number'
                  className='input-format'
                  id='pages'
                  autoComplete='off'
                  placeholder='Pages'
                />

                <input
                  type='date'
                  className='input-format'
                  id='date'
                  placeholder='Date'
                  autoComplete='off'
                />

                <input
                  type='number'
                  className='input-format'
                  id='copies'
                  autoComplete='off'
                  placeholder='Copies'
                />

                <div className='delete-btn-box mar-left-xs'>
                  <HiXCircle
                    className='btn-icon-2'
                    onClick={() => removeBookFormat(index)}
                  />
                </div>
              </div>
            ))}
          </>
        )}

        <button
          type='button'
          className='add-issue-btn add-issue-btn-blue center-self'
          onClick={addBookFormat}
        >
          <BiSolidPlusCircle className='add-issue-btn-icon' />
          add book format
        </button>

        {/* <button
          type='button'
          className='btn btn-l center-self'
          onClick={addBookFormat}
        >
          <HiPlus className='button-icon' />
          add format
        </button> */}
      </div>
    </>
  );
};

export default BookFormats;
