import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import bookService from './bookService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  books: [],
  book: {},
  bookError: false,
  bookSuccess: false,
  bookLoading: false,
  message: '',
}

// Create book
export const createBook = createAsyncThunk(
  'books/create',
  async (book, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await bookService.createBook(book, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get all books
export const getBooks = createAsyncThunk(
  'books/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await bookService.getBooks(token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get book
export const getBook = createAsyncThunk(
  'books/get',
  async (bookId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await bookService.getBook(bookId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Update book
export const updateBook = createAsyncThunk(
  'books/update',
  async (book, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await bookService.updateBook(book.bookId, book.bookData, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Delete book
export const deleteBook = createAsyncThunk(
  'books/delete',
  async (bookId, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await bookService.deleteBook(bookId, token)
  }
)

export const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    reset: state => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(createBook.pending, state => {
        state.bookLoading = true
      })
      .addCase(createBook.fulfilled, (state, action) => {
        state.bookLoading = false
        state.bookSuccess = true
        state.book = action.payload
      })
      .addCase(createBook.rejected, (state, action) => {
        state.bookLoading = false
        state.bookError = true
        state.message = action.payload
        state.book = null
      })
      .addCase(getBooks.pending, state => {
        state.bookLoading = true
      })
      .addCase(getBooks.fulfilled, (state, action) => {
        state.bookLoading = false
        state.bookSuccess = true
        state.books = action.payload
      })
      .addCase(getBooks.rejected, (state, action) => {
        state.bookLoading = false
        state.bookError = true
        state.message = action.payload
      })
      .addCase(getBook.pending, state => {
        state.bookLoading = true
      })
      .addCase(getBook.fulfilled, (state, action) => {
        state.bookLoading = false
        state.bookSuccess = true
        state.book = action.payload
      })
      .addCase(getBook.rejected, (state, action) => {
        state.bookLoading = false
        state.bookError = true
        state.message = action.payload
      })
      .addCase(updateBook.pending, state => {
        state.bookLoading = true
      })
      .addCase(updateBook.fulfilled, (state, action) => {
        state.bookLoading = false
        state.bookSuccess = true
        state.book = action.payload
      })
      .addCase(updateBook.rejected, (state, action) => {
        state.bookLoading = false
        state.bookError = true
        state.message = action.payload
      })
      .addCase(deleteBook.fulfilled, state => {
        state.bookSuccess = true
      })
  },
})

export const { reset } = bookSlice.actions
export default bookSlice.reducer
