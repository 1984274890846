import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTrashAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCharacters,
  deleteCharacter,
  updateCharacter,
} from '../../features/characters/characterSlice'

const CharactersTableRow = ({ character, isChecked, onCheckboxChange }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const charactersState = useSelector(state => state.characters)
  const currentCharacter = charactersState.characters.find(
    s => s.id === character.id
  )

  // MANUAL CONTROL FUNCTION
  const updateManualControl = isChecked => {
    if (isChecked !== currentCharacter?.checked) {
      dispatch(
        updateCharacter({
          characterId: character.id,
          characterData: { checked: isChecked },
        })
      ).then(() => {
        dispatch(getCharacters())
        toast.success('Characters successfully updated')
      })
    }
  }

  // Delete character
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteCharacter(character.id))
      navigate('/characters')
      dispatch(getCharacters())
    }
  }

  return (
    <>
      <div className='entity-table__row characters-table-row'>
        <input
          type='checkbox'
          className='entity-check'
          id={character.id}
          checked={isChecked}
          onChange={() => onCheckboxChange(character.id)}
        />
        <label htmlFor={character.id}></label>
        <Link
          to={`/characters/${character.id}`}
          className='entity-table__row__link'
        >
          {`${character.name}`}
        </Link>
        <div>
          {character.publishings.length > 0
            ? character.publishings[0].name
            : 'No publishing'}
        </div>
        <div>
          {character.earths.length > 0 ? character.earths[0].name : 'No earths'}
        </div>
        <div className='asc'>3845</div>
        <div className='asc'>
          {currentCharacter?.checked ? 'Finished' : 'Unfinished'}
        </div>
        <label className='switch asc'>
          <input
            type='checkbox'
            checked={currentCharacter?.checked || false}
            onChange={e => {
              const newState = e.target.checked
              updateManualControl(newState)
              onCheckboxChange(character.id, newState)
            }}
          />
          <span className='slider'></span>
        </label>
        <div>{new Date(character.updatedAt).toLocaleString('en-GB')}</div>
        <Popup
          trigger={
            <button className='user-nav__user-photo'>
              <BsThreeDotsVertical className='entity-table__settings-icon' />
            </button>
          }
          position='bottom right'
        >
          <button
            className='btn-inner btn-inner__table'
            onClick={deleteHandler}
          >
            <FaTrashAlt /> &nbsp; Delete
          </button>
        </Popup>
      </div>
    </>
  )
}

export default CharactersTableRow
