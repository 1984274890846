import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTrashAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  getBookSeries,
  deleteBookSerie,
  updateBookSerie,
} from '../../features/bookSeries/bookSerieSlice'

const BookSeriesTableRow = ({ bookSerie, isChecked, onCheckboxChange }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const bookSeriesState = useSelector(state => state.bookSeries)
  const currentBookSerie = bookSeriesState.bookSeries.find(
    s => s.id === bookSerie.id
  )

  // MANUAL CONTROL FUNCTION
  const updateManualControl = isChecked => {
    if (isChecked !== currentBookSerie?.checked) {
      dispatch(
        updateBookSerie({
          bookSerieId: bookSerie.id,
          bookSerieData: { checked: isChecked },
        })
      ).then(() => {
        dispatch(getBookSeries())
        toast.success('Book series successfully updated')
      })
    }
  }

  // Delete Book Serie
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteBookSerie(bookSerie.id))
      navigate('/book-series')
      dispatch(getBookSeries())
    }
  }
  return (
    <>
      <div className='entity-table__row bookSeries-table-row'>
        <input
          type='checkbox'
          className='entity-check'
          id={bookSerie.id}
          checked={isChecked}
          onChange={() => onCheckboxChange(bookSerie.id)}
        />
        <label htmlFor={bookSerie.id}></label>
        <Link
          to={`/book-series/${bookSerie.id}`}
          className='entity-table__row__link'
        >
          {`${bookSerie.name}`}
        </Link>
        <div>150</div>
        <div className='asc'>
          {currentBookSerie?.checked ? 'Finished' : 'Unfinished'}
        </div>
        <label className='switch asc'>
          <input
            type='checkbox'
            checked={currentBookSerie?.checked || false}
            onChange={e => {
              const newState = e.target.checked
              updateManualControl(newState)
              onCheckboxChange(bookSerie.id, newState)
            }}
          />
          <span className='slider'></span>
        </label>
        <div>{new Date(bookSerie.updatedAt).toLocaleString('en-GB')}</div>
        <Popup
          trigger={
            <button className='user-nav__user-photo'>
              <BsThreeDotsVertical className='entity-table__settings-icon' />
            </button>
          }
          position='bottom right'
        >
          <button
            className='btn-inner btn-inner__table'
            onClick={deleteHandler}
          >
            <FaTrashAlt /> &nbsp; Delete
          </button>
        </Popup>
      </div>
    </>
  )
}
export default BookSeriesTableRow
