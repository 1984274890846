import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTrashAlt } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { getAuthors, deleteAuthor } from '../../features/authors/authorSlice'

const AuthorsTableRow = ({ author, isChecked, onCheckboxChange }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Delete author
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteAuthor(author.id))
      navigate('/authors')
      dispatch(getAuthors())
    }
  }

  return (
    <>
      <div className='entity-table__row authors-table-row'>
        <input
          type='checkbox'
          className='entity-check'
          id={author.id}
          checked={isChecked}
          onChange={() => onCheckboxChange(author.id)}
        />
        <label htmlFor={author.id}></label>
        <Link to={`/authors/${author.id}`} className='entity-table__row__link'>
          {`${author.firstName} ${author.lastName}`}
        </Link>
        <div>editor-in-chief, editor, writer, colorist, letterer</div>
        <div className='asc'>0</div>
        <div className='asc'>Unfinished</div>
        <label className='switch asc'>
          <input type='checkbox' />
          <span className='slider'></span>
        </label>
        <div>{new Date(author.updatedAt).toLocaleString('en-GB')}</div>
        <Popup
          trigger={
            <button className='user-nav__user-photo'>
              <BsThreeDotsVertical className='entity-table__settings-icon' />
            </button>
          }
          position='bottom right'
        >
          <div className='btn-inner__box'></div>
          <button
            className='btn-inner btn-inner__table'
            onClick={deleteHandler}
          >
            <FaTrashAlt /> &nbsp; Delete
          </button>
        </Popup>
      </div>
    </>
  )
}

export default AuthorsTableRow
