import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import bookSerieService from './bookSerieService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  bookSeries: [],
  bookSerie: {},
  bookSerieError: false,
  bookSerieSuccess: false,
  bookSerieLoading: false,
  message: '',
}

// Create book series
export const createBookSerie = createAsyncThunk(
  'bookSeries/create',
  async (bookSerie, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await bookSerieService.createBookSerie(bookSerie, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get all book series
export const getBookSeries = createAsyncThunk(
  'bookSeries/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await bookSerieService.getBookSeries(token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get one book serie
export const getBookSerie = createAsyncThunk(
  'bookSeries/get',
  async (bookSerieId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await bookSerieService.getBookSerie(bookSerieId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Update book serie
export const updateBookSerie = createAsyncThunk(
  'bookSeries/update',
  async (bookSerie, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await bookSerieService.updateBookSerie(
        bookSerie.bookSerieId,
        bookSerie.bookSerieData,
        token
      )
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Delete book serie
export const deleteBookSerie = createAsyncThunk(
  'bookSeries/delete',
  async (bookSerieId, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await bookSerieService.deleteBookSerie(bookSerieId, token)
  }
)

export const bookSerieSlice = createSlice({
  name: 'bookSerie',
  initialState,
  reducers: {
    reset: state => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(createBookSerie.pending, state => {
        state.bookSerieLoading = true
      })
      .addCase(createBookSerie.fulfilled, (state, action) => {
        state.bookSerieLoading = false
        state.bookSerieSuccess = true
        state.bookSerie = action.payload
      })
      .addCase(createBookSerie.rejected, (state, action) => {
        state.bookSerieLoading = false
        state.bookSerieError = true
        state.message = action.payload
        state.bookSerie = null
      })
      .addCase(getBookSeries.pending, state => {
        state.bookSerieLoading = true
      })
      .addCase(getBookSeries.fulfilled, (state, action) => {
        state.bookSerieLoading = false
        state.bookSerieSuccess = true
        state.bookSeries = action.payload
      })
      .addCase(getBookSeries.rejected, (state, action) => {
        state.bookSerieLoading = false
        state.bookSerieError = true
        state.message = action.payload
      })
      .addCase(getBookSerie.pending, state => {
        state.bookSerieLoading = true
      })
      .addCase(getBookSerie.fulfilled, (state, action) => {
        state.bookSerieLoading = false
        state.bookSerieSuccess = true
        state.bookSerie = action.payload
      })
      .addCase(getBookSerie.rejected, (state, action) => {
        state.bookSerieLoading = false
        state.bookSerieError = true
        state.message = action.payload
      })
      .addCase(updateBookSerie.pending, state => {
        state.bookSerieLoading = true
      })
      .addCase(updateBookSerie.fulfilled, (state, action) => {
        state.bookSerieLoading = false
        state.bookSerieSuccess = true
        state.bookSerie = action.payload
      })
      .addCase(updateBookSerie.rejected, (state, action) => {
        state.bookSerieLoading = false
        state.bookSerieError = true
        state.message = action.payload
      })
      .addCase(deleteBookSerie.fulfilled, state => {
        state.bookSerieSuccess = true
      })
  },
})

export const { reset } = bookSerieSlice.actions
export default bookSerieSlice.reducer
