import { useState } from 'react'
import { createSerie, getSeries } from '../../features/series/serieSlice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { MdLibraryAddCheck } from 'react-icons/md'
import { SiBookstack } from 'react-icons/si'

const CreateSerie = () => {
  const [name, setName] = useState('')

  const dispatch = useDispatch()

  const { serieError, message } = useSelector(state => state.series)

  const onSubmit = e => {
    e.preventDefault()

    const serieData = { name }

    dispatch(createSerie(JSON.stringify(serieData)))
    dispatch(getSeries())

    if (serieError) {
      toast.error(message)
    } else {
      toast.success(`Serie created!`)
    }
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className='detail-form grid-2'>
          <div className='detail-form__heading'>
            <div className='heading-form'>Create Serie</div>
          </div>

          <div className='detail-form__user-info'>
            <p>
              <span className='required-mark'>&#10044;</span>Data marked with an
              asterisk are required, please fill them in before saving ...{' '}
            </p>
          </div>

          <div className='detail-form__button-box'>
            <button type='submit' className='btn btn-l' onSubmit={onSubmit}>
              <MdLibraryAddCheck className='button-icon' />
              Create
            </button>
          </div>

          <div className='detail-form__main-2 container'>
            <div className='input-box'>
              <SiBookstack className='input__icon' />
              <div className='input-area'>
                <label htmlFor='name' className='form__label'>
                  Name
                </label>
                <input
                  type='text'
                  className='input'
                  id='name'
                  placeholder='Name of Serie'
                  value={name}
                  onChange={e => setName(e.target.value)}
                  autoComplete='off'
                  required
                />
              </div>
              <div className='required-mark-box'>
                <span className='required-mark'>&#10044;</span>
              </div>
            </div>
          </div>

          <div className='detail-form__description container'>
            <textarea
              rows='num'
              cols='num'
              type='text'
              className='input__textarea'
              name='description'
              placeholder='Input description..'
              autoComplete='off'
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default CreateSerie
