import Slapota from '../utils/img/Slapota1.png'
import Slapota2 from '../utils/img/Slapota2.png'
import Filda from '../utils/img/Filda.png'
import Venca from '../utils/img/Venda.png'
import Macek from '../utils/img/Macan.png'
import Hulk1 from '../utils/img/Incredible_Hulk_Vol_1_715.webp'
import Hulk2 from '../utils/img/Incredible_Hulk_Vol_1_716.webp'
import Hulk3 from '../utils/img/Incredible_Hulk_Vol_1_717.webp'

const DashLastActivity = () => {
  return (
    <>
      <div className='item last-activity'>
        <div className='last-activity__heading'>
          <h3 className='heading-tertiary-dark'>Latest Activity</h3>
          <div className='last-activity__filter-box'>
            <div className='last-activity__filter last-activity__filter-active'>
              All
            </div>
            <div className='last-activity__filter'>Content Report</div>
            <div className='last-activity__filter'>Social Report</div>
          </div>
        </div>
        <div className='last-activity__container'>
          <div className='last-activity__log'>
            <img src={Slapota} alt='Slapota' className='last-activity__photo' />
            <div className='last-activity__message'>
              <div className='last-activity__name'>Alicia Hawkins</div>
              <div className='last-activity__logtext'>
                Add variat covers to{' '}
                <span className='last-activity__link'>
                  Incredible Hulk Vol 1 #719
                </span>{' '}
                2 minutes ago
              </div>
              <div className='last-activity__gallery'>
                <img
                  src={Hulk1}
                  alt='Hulk1'
                  className='last-activity__gallery-item'
                />
                <img
                  src={Hulk2}
                  alt='Hulk2'
                  className='last-activity__gallery-item'
                />
                <img
                  src={Hulk3}
                  alt='Hulk3'
                  className='last-activity__gallery-item'
                />
              </div>
            </div>
          </div>
          <div className='last-activity__log'>
            <img src={Venca} alt='Venda' className='last-activity__photo' />
            <div className='last-activity__message'>
              <div className='last-activity__name'>Václav Špaček</div>
              <div className='last-activity__logtext'>
                Updated series description of{' '}
                <span className='last-activity__link'>Iron Fist</span> series 5
                minutes ago
              </div>
            </div>
          </div>

          <div className='last-activity__log'>
            <img src={Filda} alt='Filda' className='last-activity__photo' />
            <div className='last-activity__message'>
              <div className='last-activity__name'>Filip Khol</div>
              <div className='last-activity__logtext'>
                Liked{' '}
                <span className='last-activity__link'>Alicia Hawkins</span>{' '}
                profile image 27 minutes ago
              </div>
            </div>
          </div>

          <div className='last-activity__log'>
            <img
              src={Slapota2}
              alt='Slapota'
              className='last-activity__photo'
            />
            <div className='last-activity__message'>
              <div className='last-activity__name'>Alicia Hawkins</div>
              <div className='last-activity__logtext'>
                Updated profile image 28 minutes ago
              </div>
            </div>
          </div>

          <div className='last-activity__log'>
            <img src={Macek} alt='Macan' className='last-activity__photo' />
            <div className='last-activity__message'>
              <div className='last-activity__name'>Marcel z Borohrádku</div>
              <div className='last-activity__logtext'>
                Stocked some new coal 52 minutes ago
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashLastActivity
