import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/users/userSlice';
import authorReducer from '../features/authors/authorSlice';
import characterReducer from '../features/characters/characterSlice';
import tagReducer from '../features/tags/tagSlice';
import countryReducer from '../features/countries/countrySlice';
import publishingReducer from '../features/publishings/publishingSlice';
import authorRoleReducer from '../features/authorRoles/authorRoleSlice';
import earthReducer from '../features/earths/earthSlice';
import organizationReducer from '../features/organizations/organizationSlice';
import volumeReducer from '../features/volumes/volumeSlice';
import serieReducer from '../features/series/serieSlice';
import bookReducer from '../features/books/bookSlice';
import bookSerieReducer from '../features/bookSeries/bookSerieSlice';
import eventReducer from '../features/events/eventSlice';
import issueReducer from '../features/issues/issueSlice';
import storyArcReducer from '../features/storyArcs/storyArcSlice';
import crossoverReducer from '../features/crossovers/crossoverSlice';
import imageReducer from '../features/images/imageSlice';
import storyReducer from '../features/stories/storySlice';
import aliasReducer from '../features/aliases/aliasSlice';

export const store = configureStore({
  reducer: {
    users: userReducer,
    characters: characterReducer,
    authors: authorReducer,
    tags: tagReducer,
    countries: countryReducer,
    publishings: publishingReducer,
    authorRoles: authorRoleReducer,
    earths: earthReducer,
    organizations: organizationReducer,
    volumes: volumeReducer,
    series: serieReducer,
    books: bookReducer,
    bookSeries: bookSerieReducer,
    events: eventReducer,
    issues: issueReducer,
    storyArcs: storyArcReducer,
    crossovers: crossoverReducer,
    images: imageReducer,
    stories: storyReducer,
    aliases: aliasReducer,
  },
});
