import axios from 'axios'

const backendURL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${backendURL}/api/v1/author-roles/`

// Create Author Role
const createAuthorRole = async (authorRole, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.post(API_URL, authorRole, config)
  return response.data
}

// Get all Author Roles
const getAuthorRoles = async token => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.get(API_URL, config)
  return response.data
}

// Get Author Role
const getAuthorRole = async (authorRoleId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + authorRoleId, config)
  return response.data
}

// Update Author Role
const updateAuthorRole = async (authorRoleId, authorRoleData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.put(
    API_URL + authorRoleId,
    authorRoleData,
    config
  )

  return response.data
}

// Delete Author Role
const deleteAuthorRole = async (authorRoleId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + authorRoleId, config)
  return response.data
}

const authorRoleService = {
  createAuthorRole,
  getAuthorRoles,
  getAuthorRole,
  updateAuthorRole,
  deleteAuthorRole,
}

export default authorRoleService
