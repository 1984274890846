import React, { useState } from 'react';
// import Select from 'react-select';
import { HiXCircle } from 'react-icons/hi';
// import { FaUser, FaCloudUploadAlt } from 'react-icons/fa';
import { BiSolidPlusCircle } from 'react-icons/bi';
import CreatableSelect from 'react-select/creatable';

const AuthorTeam = () => {
  const [authors, setAuthors] = useState(['']);

  const updateAuthors = (index, value) => {
    const newAuthors = [...authors];
    newAuthors[index] = value;
    setAuthors(newAuthors);
  };
  const addAuthor = () => {
    setAuthors([...authors, '']);
  };
  const removeAuthor = (index) => {
    const newAuthors = [...authors];
    newAuthors.splice(index, 1); // Odstranění řádku s daným indexem
    setAuthors(newAuthors);
  };

  return (
    <>
      {/* AUTHOR TEAM IF IS OGN */}

      <div className='detail-form__connect-box container' id='author-team'>
        <label htmlFor='assigned-issues' className='entity-label'>
          author team
        </label>
        {!authors?.length ? (
          <p className='input-label-info'>
            {' '}
            No authors assigned to this author team yet.
          </p>
        ) : (
          <div className='author-team-box'>
            {authors.map((author, index) => (
              <div className='author-team-row' key={index}>
                <select
                  className='single-select'
                  id='author-role'
                  name='author-role'
                  autoComplete='off'
                  placeholder='Select Author Role'
                  style={{ paddingLeft: '1rem' }}
                  //   value='-'
                >
                  <option value='Writer'>Writer</option>
                  <option value='Colorist'>Colorist</option>
                  <option value='Inker'>Inker</option>
                  <option value='Penciler'>Penciler</option>
                  <option value='Letterer'>Letterer</option>
                  <option value='Cover Artis'>Cover Artis</option>
                  <option value='Editor'>Editor</option>
                  <option value='Editor-In-Chief'>Editor-In-Chief</option>
                </select>

                <CreatableSelect
                  className='single-select-disabled'
                  classNamePrefix='single-select-disabled'
                  placeholder='Author Name'
                  autoComplete='off'
                  id={`author${index}`}
                  value={author}
                  onChange={(e) => updateAuthors(index, e.target.value)}
                />

                <div className='delete-btn-box mar-left-xs'>
                  <HiXCircle
                    className='btn-icon-2'
                    onClick={() => removeAuthor(index)}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        <button
          type='button'
          className='add-issue-btn add-issue-btn-blue'
          onClick={addAuthor}
        >
          <BiSolidPlusCircle className='add-issue-btn-icon' />
          add author
        </button>
      </div>
    </>
  );
};

export default AuthorTeam;
