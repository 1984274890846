import Navigation from '../layout/Navigation'
import Topbar from '../layout/Topbar'
import Spinner from '../components/Spinner'
import Select from 'react-select'
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg'
import { FaTags } from 'react-icons/fa'
import { HiSave, HiOfficeBuilding, HiXCircle } from 'react-icons/hi'
import { MdDelete } from 'react-icons/md'
import { RiOrganizationChart } from 'react-icons/ri'
import { ImEarth } from 'react-icons/im'
import { BiSolidPlusCircle } from 'react-icons/bi'
import { toast } from 'react-toastify'
import CreatableSelect from 'react-select/creatable'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import {
  getOrganization,
  deleteOrganization,
  getOrganizations,
  updateOrganization,
} from '../features/organizations/organizationSlice'
import { getPublishings } from '../features/publishings/publishingSlice'
import { getTags, createTag, addNewTagToState } from '../features/tags/tagSlice'
import { addedBy, updatedBy } from '../features/users/userSlice'
import { uploadImage } from '../features/images/imageSlice'
import ImageUploader from '../components/ImageUploader'
import Breadcrumbs from '../layout/Breadcrumbs'

const OrganizationDetail = () => {
  const [name, setName] = useState('')
  const [tagIds, setTagIds] = useState([])
  const [description, setDescription] = useState('')
  const [orgPublishing, setOrgPublishing] = useState([])
  const [orgEarth, setOrgEarth] = useState([])
  const [publishingEarths, setPublishingEarths] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [imageId, setImageId] = useState()
  const [imageURL, setImageURL] = useState('')

  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [relatedCharacters, setRelatedCharacters] = useState([''])

  const updateRelatedCharacters = (index, value) => {
    const newRelatedCharacters = [...relatedCharacters]
    newRelatedCharacters[index] = value
    setRelatedCharacters(newRelatedCharacters)
  }
  const addRelatedCharacter = () => {
    setRelatedCharacters([...relatedCharacters, ''])
  }
  const removeRelatedCharacter = index => {
    const newRelatedCharacters = [...relatedCharacters]
    newRelatedCharacters.splice(index, 1) // Odstranění řádku s daným indexem
    setRelatedCharacters(newRelatedCharacters)
  }

  const [members, setMembers] = useState([''])

  const updateMembers = (index, value) => {
    const newMembers = [...members]
    newMembers[index] = value
    setMembers(newMembers)
  }
  const addMember = () => {
    setMembers([...members, ''])
  }
  const removeMember = index => {
    const newMembers = [...members]
    newMembers.splice(index, 1) // Odstranění řádku s daným indexem
    setMembers(newMembers)
  }

  const onChange = e => {
    setSearchTerm(e.target.value)
  }

  // Fetch Organization to Redux by URL params
  const { organizationId } = params
  const { organization, organizationLoading, organizationError, message } =
    useSelector(state => state.organizations)
  const { tags } = useSelector(state => state.tags)
  const { publishings, publishing } = useSelector(state => state.publishings)

  useEffect(() => {
    if (organizationError) {
      toast.error(message)
    }

    dispatch(getOrganization(organizationId))
    // eslint-disable-next-line
  }, [organizationError, message, organizationId])

  const selectRef = useRef(null) // Create a ref for CreatableSelect
  useEffect(() => {
    // Focus the input of CreatableSelect when the component mounts
    if (selectRef.current) {
      selectRef.current.focus()
    }
  }, [])

  useEffect(() => {
    dispatch(getTags())
    dispatch(getPublishings())
    // eslint-disable-next-line
  }, [])

  // Set default form data from organizations reducer
  useEffect(() => {
    setName(organization.name && organization.name)
    setDescription(!organization.description ? '' : organization.description)
    setTagIds(organization.tags?.map(tag => ({ id: tag.id, name: tag.name })))
    setOrgPublishing(!organization.publishing ? [] : organization.publishing)
    setOrgEarth(!organization.earths ? [] : organization.earths[0])
    setImageURL(!organization.imageUrl ? '' : organization.imageUrl)
    dispatch(addedBy(organization.addedBy))
    dispatch(updatedBy(organization.lastUpdatedBy))
  }, [dispatch, organization])

  // CREATE NEW TAG AND ASSIGNE IT TO PUBLISHING
  const handleCreateTag = async inputValue => {
    try {
      const { payload: createdTag } = await dispatch(
        createTag({ name: inputValue })
      )
      if (createdTag && createdTag.id) {
        dispatch(addNewTagToState(createdTag))
        setTagIds(prevTagIds => [
          ...prevTagIds,
          { id: createdTag.id, name: createdTag.name },
        ])
      } else {
        console.error('Failed to create Tag or no Tag ID returned.')
      }
    } catch (error) {
      console.error('Error creating Tag:', error)
    }
  }

  const publishingOnChange = async e => {
    const selectedPublishing = e
    setOrgPublishing(selectedPublishing)

    // Update 'Earths' based on the selected publishing
    const selectedPublishingId = selectedPublishing.id
    const selectedPublishingData = publishings.find(
      publishing => publishing.id === selectedPublishingId
    )

    if (selectedPublishingData) {
      const selectedPublishingEarths = selectedPublishingData.earths
      // Update 'publishingEarths' state with the selected Earths
      setPublishingEarths(selectedPublishingEarths)

      // Update 'charEarth' with the first Earth from the selected publishing (you can modify this based on your logic)
      if (selectedPublishingEarths && selectedPublishingEarths.length > 0) {
        setOrgEarth(selectedPublishingEarths[0])
      }
    }
  }

  // UPLOAD IMAGE
  const handleImageUpload = async file => {
    try {
      const response = await dispatch(uploadImage(file))
      const uploadedImage = response.payload[0]

      if (uploadedImage) {
        setImageId(uploadedImage.id)
        toast.success('Image uploaded successfully!')
      } else {
        console.error('Failed to create image or no ID returned.')
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const handleImageChange = () => {
    setImageURL('')
  }

  // Update organization
  const onSubmit = e => {
    e.preventDefault()

    const organizationData = {
      name,
      description,
      tagIds: tagIds.map(({ id }) => id),
      publishingId: !orgPublishing ? '' : orgPublishing.id,
      earthIds: !orgEarth ? [] : [orgEarth.id],
      imageId,
    }

    dispatch(updateOrganization({ organizationId, organizationData }))

    if (organizationError) {
      toast.error(message)
    } else {
      toast.success('Organization successfully updated!')
    }
  }

  // Delete organization
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteOrganization(organizationId))
      navigate('/organization')
      dispatch(getOrganizations())
    }
  }

  const creator = useSelector(state => state.users.addedBy)
  const updator = useSelector(state => state.users.updatedBy)

  const breadcrumbsPath = ['organizations', `${organization.name}`]

  if (organizationLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <Breadcrumbs path={breadcrumbsPath} />
          <form onSubmit={onSubmit}>
            <div className='detail-form grid-2'>
              <div className='detail-form__heading'>
                <div className='heading-form'>
                  <RiOrganizationChart className='detail-form__icon' />{' '}
                  {organization.name}{' '}
                </div>
                <div className='entity-info bold'>
                  <p className='entity-info__id'>ID: {organization.id}</p>
                  <div className='entity-info__slug mar-left-xs'>
                    {organization.slug}
                  </div>
                </div>
              </div>

              <div className='detail-form__user-info'>
                <p className='pad-bottom-xs mar-left-s'>
                  Created:{' '}
                  <span className='bold'>
                    {new Date(organization.createdAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${creator.name} ${creator.surname}`}</span>
                </p>
                <p className='mar-left-s'>
                  Updated:{' '}
                  <span className='bold'>
                    {new Date(organization.updatedAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${updator.name} ${updator.surname}`}</span>
                </p>
              </div>

              <div className='detail-form__button-box'>
                <button type='submit' className='btn-new btn-save mar-right-xs'>
                  <HiSave className='button-icon-new' />
                </button>
                <button
                  type='button'
                  className='btn-new btn-delete'
                  onClick={deleteHandler}
                >
                  <MdDelete className='button-icon-new' />
                </button>
              </div>

              {!imageURL ? (
                <ImageUploader onImageUpload={handleImageUpload} />
              ) : (
                <>
                  <div className='image-upload container'>
                    <label htmlFor='basicInfo' className='entity-label'>
                      Image
                    </label>
                    <img
                      src={imageURL}
                      alt='Main preview'
                      className='img-entity'
                    />
                    <HiXCircle
                      className='btn-img-del'
                      onClick={handleImageChange}
                    />
                  </div>
                </>
              )}

              <div className='detail-form__main-1 container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  Basic Info
                </label>
                <div className='input-box'>
                  <RiOrganizationChart className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='name' className='input__label'>
                      Organization Name
                    </label>
                    <input
                      type='text'
                      className='input'
                      id='name'
                      placeholder='Organization Name'
                      value={name}
                      onChange={e => setName(e.target.value)}
                      autoComplete='off'
                      required
                    />
                  </div>
                  <div className='required-mark-box'>
                    <span className='required-mark'>&#10044;</span>
                  </div>
                </div>

                <div className='input-box-2'>
                  <FaTags className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='tags' className='input__label'>
                      Tags
                    </label>
                    <CreatableSelect
                      className='react-select-xl'
                      classNamePrefix='react-select-xl'
                      placeholder='Add tags'
                      isMulti
                      options={tags}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      value={tagIds}
                      onCreateOption={handleCreateTag} // Handle tag creation
                      onChange={e => setTagIds(e)}
                      isValidNewOption={inputValue => inputValue}
                      getNewOptionData={inputValue => ({
                        value: inputValue,
                        name: `Create ${inputValue}`,
                      })}
                      menuPortalTarget={document.body} // Set menuPortalTarget to document.body
                      ref={selectRef} // Attach the ref to CreatableSelect
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <HiOfficeBuilding className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='publishing' className='input__label'>
                      Publishing
                    </label>
                    <Select
                      className='react-single-select'
                      classNamePrefix='react-single-select'
                      placeholder='Choose Publishing'
                      id='publishing'
                      options={publishings}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      value={orgPublishing}
                      onChange={publishingOnChange}
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <ImEarth className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='earth' className='input__label'>
                      Earth
                    </label>
                    <Select
                      className='react-single-select'
                      classNamePrefix='react-single-select'
                      placeholder='Choose Earth'
                      id='earth'
                      value={orgEarth}
                      options={publishingEarths}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      onChange={e => setOrgEarth(e)}
                    />
                  </div>
                </div>
              </div>

              <div className='detail-form__description container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  description
                </label>
                <EditorProvider>
                  <Editor
                    className='rsw-editor'
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
              </div>

              <div className='detail-form__connect-box container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  members
                </label>
                {!members?.length ? (
                  <p className='input-label-info'>
                    {' '}
                    No members added to this entity yet.
                  </p>
                ) : (
                  <div className='flex-row-box'>
                    {members.map((member, index) => (
                      <div className='flex-row' key={index}>
                        <div className='select-cont-flex'>
                          <div className='connect-num'>{index + 1}</div>

                          <CreatableSelect
                            className='single-select-number-disabled'
                            classNamePrefix='single-select-number-disabled'
                            placeholder='Add Member'
                            autoComplete='off'
                            id={`member${index}`}
                            value={members}
                            onChange={e => updateMembers(index, e.target.value)}
                          />
                        </div>

                        <div className='delete-btn-box mar-left-xs'>
                          <HiXCircle
                            className='btn-icon-2'
                            onClick={() => removeMember(index)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  type='button'
                  className='add-issue-btn add-issue-btn-blue'
                  onClick={addMember}
                >
                  <BiSolidPlusCircle className='add-issue-btn-icon' />
                  add members
                </button>
              </div>

              <div className='detail-form__connect-box container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  related characters
                </label>
                {!relatedCharacters?.length ? (
                  <p className='input-label-info'>
                    {' '}
                    No related characters added to this entity yet.
                  </p>
                ) : (
                  <div className='flex-row-box'>
                    {relatedCharacters.map((relatedCharacter, index) => (
                      <div className='flex-row' key={index}>
                        <div className='select-cont-flex'>
                          <div className='connect-num'>{index + 1}</div>

                          <CreatableSelect
                            className='single-select-number-disabled'
                            classNamePrefix='single-select-number-disabled'
                            placeholder='Related Character'
                            autoComplete='off'
                            id={`relatedCharacter${index}`}
                            value={relatedCharacter}
                            onChange={e =>
                              updateRelatedCharacters(index, e.target.value)
                            }
                          />
                        </div>

                        <div className='delete-btn-box mar-left-xs'>
                          <HiXCircle
                            className='btn-icon-2'
                            onClick={() => removeRelatedCharacter(index)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  type='button'
                  className='add-issue-btn add-issue-btn-blue'
                  onClick={addRelatedCharacter}
                >
                  <BiSolidPlusCircle className='add-issue-btn-icon' />
                  add related character
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default OrganizationDetail
