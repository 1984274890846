import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import serieService from './serieService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  series: [],
  serie: {},
  serieError: false,
  serieSuccess: false,
  serieLoading: false,
  message: '',
}

// Create
export const createSerie = createAsyncThunk(
  'series/create',
  async (serie, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await serieService.createSerie(serie, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get all
export const getSeries = createAsyncThunk(
  'series/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await serieService.getSeries(token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get one
export const getSerie = createAsyncThunk(
  'series/get',
  async (serieId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await serieService.getSerie(serieId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Update
export const updateSerie = createAsyncThunk(
  '/series/update',
  async (serie, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await serieService.updateSerie(
        serie.serieId,
        serie.serieData,
        token
      )
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Delete
export const deleteSerie = createAsyncThunk(
  'series/delete',
  async (serieId, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await serieService.deleteSerie(serieId, token)
  }
)

export const serieSlice = createSlice({
  name: 'serie',
  initialState,
  reducers: {
    reset: state => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(createSerie.pending, state => {
        state.serieLoading = true
      })
      .addCase(createSerie.fulfilled, (state, action) => {
        state.serieLoading = false
        state.serieSuccess = true
        state.serie = action.payload
      })
      .addCase(createSerie.rejected, (state, action) => {
        state.serieLoading = false
        state.serieError = true
        state.message = action.payload
        state.serie = null
      })
      .addCase(getSeries.pending, state => {
        state.serieLoading = true
      })
      .addCase(getSeries.fulfilled, (state, action) => {
        state.serieLoading = false
        state.serieSuccess = true
        state.series = action.payload
      })
      .addCase(getSeries.rejected, (state, action) => {
        state.serieLoading = false
        state.serieError = true
        state.message = action.payload
      })
      .addCase(getSerie.pending, state => {
        state.serieLoading = true
      })
      .addCase(getSerie.fulfilled, (state, action) => {
        state.serieLoading = false
        state.serieSuccess = true
        state.serie = action.payload
      })
      .addCase(getSerie.rejected, (state, action) => {
        state.serieLoading = false
        state.serieError = true
        state.message = action.payload
      })
      .addCase(updateSerie.pending, state => {
        state.serieLoading = true
      })
      .addCase(updateSerie.fulfilled, (state, action) => {
        state.serieLoading = false
        state.serieSuccess = true
        state.serie = action.payload
      })
      .addCase(updateSerie.rejected, (state, action) => {
        state.serieLoading = false
        state.serieError = true
        state.message = action.payload
      })
      .addCase(deleteSerie.fulfilled, state => {
        state.serieSuccess = true
      })
  },
})

export const { reset } = serieSlice.actions
export default serieSlice.reducer
