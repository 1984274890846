import axios from 'axios'

const backendURL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${backendURL}/api/v1/earths/`

// Create earth
const createEarth = async (earth, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.post(API_URL, earth, config)
  return response.data
}

// Get all earths
const getEarths = async token => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.get(API_URL, config)
  return response.data
}

// Get earth
const getEarth = async (earthId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + earthId, config)
  return response.data
}

// Update earth
const updateEarth = async (earthId, earthData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.put(API_URL + earthId, earthData, config)

  return response.data
}

// Delete earth
const deleteEarth = async (earthId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + earthId, config)
  return response.data
}

const earthService = {
  createEarth,
  getEarths,
  getEarth,
  updateEarth,
  deleteEarth,
}

export default earthService
