import axios from 'axios'

const backendURL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${backendURL}/api/v1/story-arcs/`

// Create storyArc
const createStoryArc = async (storyArc, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.post(API_URL, storyArc, config)
  return response.data
}

// Get all storyArcs
const getStoryArcs = async token => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.get(API_URL, config)
  return response.data
}

// Get storyArc
const getStoryArc = async (storyArcId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + storyArcId, config)
  return response.data
}

// Update storyArc
const updateStoryArc = async (storyArcId, storyArcData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.put(API_URL + storyArcId, storyArcData, config)

  return response.data
}

// Delete storyArc
const deleteStoryArc = async (storyArcId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + storyArcId, config)
  return response.data
}

const storyArcService = {
  createStoryArc,
  getStoryArcs,
  getStoryArc,
  updateStoryArc,
  deleteStoryArc,
}

export default storyArcService
