import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import volumeService from './volumeService'
import userService from '../users/userService'

const initialState = {
  volumes: [],
  volume: {},
  volumeError: false,
  volumeSuccess: false,
  volumeLoading: false,
  message: '',
}

// Create
export const createVolume = createAsyncThunk(
  'volumes/create',
  async (volume, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await volumeService.createVolume(volume, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      if (message === 'Requires authentication') {
        userService.logout()
      }

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get all
export const getVolumes = createAsyncThunk(
  'volumes/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await volumeService.getVolumes(token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      if (message === 'Requires authentication') {
        userService.logout()
      }

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get one
export const getVolume = createAsyncThunk(
  'volumes/get',
  async (volumeId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await volumeService.getVolume(volumeId, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      if (message === 'Requires authentication') {
        userService.logout()
      }

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Update
export const updateVolume = createAsyncThunk(
  '/volumes/update',
  async (volume, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await volumeService.updateVolume(
        volume.volumeId,
        volume.volumeData,
        token
      )
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      if (message === 'Requires authentication') {
        userService.logout()
      }

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Delete
export const deleteVolume = createAsyncThunk(
  'volumes/delete',
  async (volumeId, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await volumeService.deleteVolume(volumeId, token)
  }
)

export const volumeSlice = createSlice({
  name: 'volume',
  initialState,
  reducers: {
    reset: state => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(createVolume.pending, state => {
        state.volumeLoading = true
      })
      .addCase(createVolume.fulfilled, (state, action) => {
        state.volumeLoading = false
        state.volumeSuccess = true
        state.volume = action.payload
      })
      .addCase(createVolume.rejected, (state, action) => {
        state.volumeLoading = false
        state.volumeError = true
        state.message = action.payload
        state.volume = null
      })
      .addCase(getVolumes.pending, state => {
        state.volumeLoading = true
      })
      .addCase(getVolumes.fulfilled, (state, action) => {
        state.volumeLoading = false
        state.volumeSuccess = true
        state.volumes = action.payload
      })
      .addCase(getVolumes.rejected, (state, action) => {
        state.volumeLoading = false
        state.volumeError = true
        state.message = action.payload
      })
      .addCase(getVolume.pending, state => {
        state.volumeLoading = true
      })
      .addCase(getVolume.fulfilled, (state, action) => {
        state.volumeLoading = false
        state.volumeSuccess = true
        state.volume = action.payload
      })
      .addCase(getVolume.rejected, (state, action) => {
        state.volumeLoading = false
        state.volumeError = true
        state.message = action.payload
      })
      .addCase(updateVolume.pending, state => {
        state.volumeLoading = true
      })
      .addCase(updateVolume.fulfilled, (state, action) => {
        state.volumeLoading = false
        state.volumeSuccess = true
        state.volume = action.payload
      })
      .addCase(updateVolume.rejected, (state, action) => {
        state.volumeLoading = false
        state.volumeError = true
        state.message = action.payload
      })
      .addCase(deleteVolume.fulfilled, state => {
        state.volumeSuccess = true
      })
  },
})

export const { reset } = volumeSlice.actions
export default volumeSlice.reducer
