import axios from 'axios'

const backendURL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${backendURL}/api/v1/publishings/`

// Create publishing
const createPublishing = async (publishing, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.post(API_URL, publishing, config)
  return response.data
}

// Get all publishings
const getPublishings = async token => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.get(API_URL, config)
  return response.data
}

// Get publishing
const getPublishing = async (publishingId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + publishingId, config)
  return response.data
}

// Update publishing
const updatePublishing = async (publishingId, publishingData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.put(
    API_URL + publishingId,
    publishingData,
    config
  )

  return response.data
}

// Delete publishing
const deletePublishing = async (publishingId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + publishingId, config)
  return response.data
}

const publishingService = {
  createPublishing,
  getPublishings,
  getPublishing,
  updatePublishing,
  deletePublishing,
}

export default publishingService
