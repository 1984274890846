const DashOverview = () => {
  return (
    <>
      <div className='item overview'>
        <div className='overview__heading'>
          <h3 className='heading-3--dark'>Database Overview</h3>
          <div className='overview__filter'>
            <label className='dropdwon'>
              <div className='dropdwon__button'>Series</div>
              <input type='checkbox' className='dropdown__input' id='test' />
              <ul className='dropdown__menu'>
                <li>Issues</li>
                <li className='divider'></li>
                <li>Tags</li>
                <li className='divider'></li>
                <li>Books</li>
              </ul>
            </label>
          </div>
        </div>
        <div className='overview__statistics'>
          <div className='overview__numbers'>
            <div className='dashchart-item'>
              Total Series <span className='dashchart-item__value'>4.840</span>
            </div>
            <div className='dashchart-item'>
              Last Month <span className='dashchart-item__value'>+202</span>
            </div>
            <div className='dashchart-item'>
              Month Before <span className='dashchart-item__value'>179</span>
            </div>
            <div className='dashchart-item'>
              Average <span className='dashchart-item__value'>110</span>
            </div>
          </div>
          <div className='overview__chart'>
            <div className='dashchart-col-container'>
              <div className='dashchart-col dashchart-col-1'></div>
              <div className='dashchart-col dashchart-col-2'></div>
              <div className='dashchart-col dashchart-col-3'></div>
              <div className='dashchart-col dashchart-col-4'></div>
              <div className='dashchart-col dashchart-col-5'></div>
              <div className='dashchart-col dashchart-col-6'></div>
              <div className='dashchart-col dashchart-col-7'></div>
              <div className='dashchart-col dashchart-col-8'></div>
              <div className='dashchart-col dashchart-col-9'></div>
              <div className='dashchart-col dashchart-col-10'></div>
              <div className='dashchart-col dashchart-col-11'></div>
              <div className='dashchart-col dashchart-col-12'></div>
            </div>
            <div className='dashchart-title-container'>
              <div className='dashchart-title'>jan</div>
              <div className='dashchart-title'>feb</div>
              <div className='dashchart-title'>mar</div>
              <div className='dashchart-title'>apr</div>
              <div className='dashchart-title'>may</div>
              <div className='dashchart-title'>jun</div>
              <div className='dashchart-title'>jul</div>
              <div className='dashchart-title'>aug</div>
              <div className='dashchart-title'>sep</div>
              <div className='dashchart-title'>oct</div>
              <div className='dashchart-title'>nov</div>
              <div className='dashchart-title'>dec</div>
            </div>
          </div>
          <div className='overview__legend'>
            <div className='overview__legend__item overview__legend__item--1'>
              Above limit
            </div>
            <div className='overview__legend__item overview__legend__item--2'>
              Below limit
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashOverview
