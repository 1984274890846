import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getEvents, deleteEvent, reset } from '../features/events/eventSlice'
import {
  HiCheckCircle,
  HiXCircle,
  HiDatabase,
  HiAdjustments,
  HiFilter,
} from 'react-icons/hi'
import { FaEdit } from 'react-icons/fa'
import { BiSlider, BiSolidTrash } from 'react-icons/bi'
import Navigation from '../layout/Navigation'
import Topbar from '../layout/Topbar'
import EventsTableRow from '../components/entityTables/EventsTableRow'
import Spinner from '../components/Spinner'
import { toast } from 'react-toastify'

const EventsTable = () => {
  const { events, eventLoading, eventSuccess } = useSelector(
    state => state.events
  )

  const [searchTerm, setSearchTerm] = useState('')
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [checkedEvents, setCheckedEvents] = useState([])
  const [filterFullfilled, setFilterFullfilled] = useState(false)
  const [filterUnfinished, setFilterUnfinished] = useState(false)
  const [activeFilter, setActiveFilter] = useState('all')

  const dispatch = useDispatch()

  const onChange = e => {
    setSearchTerm(e.target.value)
  }

  const handleCheckboxChange = id => {
    if (checkedEvents.includes(id)) {
      const updatedCheckedEvents = checkedEvents.filter(
        eventId => eventId !== id
      )
      setCheckedEvents(updatedCheckedEvents)
    } else {
      const updatedCheckedEvents = [...checkedEvents, id]
      setCheckedEvents(updatedCheckedEvents)
    }
  }

  const deleteSelectedEvents = async () => {
    if (window.confirm('Are you sure?'))
      try {
        const deleteRequests = checkedEvents.map(eventId =>
          dispatch(deleteEvent(eventId))
        )
        await Promise.all(deleteRequests)
        dispatch(getEvents())
      } catch (error) {
        console.error('Chyba při mazání eventu', error)
      }
    toast.success('Selected events were successfully deleted!')
  }

  useEffect(() => {
    return () => {
      if (eventSuccess) {
        dispatch(reset())
      }
    }
  }, [dispatch, eventSuccess])

  useEffect(() => {
    dispatch(getEvents())
  }, [dispatch])

  useEffect(() => {
    if (isCheckedAll) {
      setCheckedEvents(events.map(event => event.id))
    } else {
      setCheckedEvents([])
    }
  }, [isCheckedAll, events])

  if (eventLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <div className='table-bookmark'>
            <button
              className={`table-bookmark__button ${
                activeFilter === 'all' ? 'table-bookmark__button--active' : ''
              }`}
              onClick={() => {
                setActiveFilter('all')
                setFilterFullfilled(false)
                setFilterUnfinished(false)
              }}
            >
              <HiDatabase
                className={`table-bookmark__icon ${
                  activeFilter === 'all' ? 'table-bookmark__icon--active' : ''
                }`}
              />{' '}
              All Events
            </button>
            <button
              className={`table-bookmark__button ${
                activeFilter === 'fullfilled'
                  ? 'table-bookmark__button--active'
                  : ''
              }`}
              onClick={() => {
                setActiveFilter('fullfilled')
                setFilterFullfilled(true)
                setFilterUnfinished(false)
              }}
            >
              <HiCheckCircle
                className={`table-bookmark__icon ${
                  activeFilter === 'fullfilled'
                    ? 'table-bookmark__icon--active'
                    : ''
                }`}
              />{' '}
              Fullfilled
            </button>
            <button
              className={`table-bookmark__button ${
                activeFilter === 'unfinished'
                  ? 'table-bookmark__button--active'
                  : ''
              }`}
              onClick={() => {
                setActiveFilter('unfinished')
                setFilterFullfilled(false)
                setFilterUnfinished(true)
              }}
            >
              <HiXCircle
                className={`table-bookmark__icon ${
                  activeFilter === 'unfinished'
                    ? 'table-bookmark__icon--active'
                    : ''
                }`}
              />{' '}
              Unfinished
            </button>
          </div>
          <div className='authors-bulk'>
            <div className='entity-search'>
              <HiFilter className='entity-search__icon' />
              <input
                type='text'
                className='entity-search__input'
                placeholder='Filter books'
                onChange={onChange}
              />
              <div className='entity-search__filter-box'>
                <HiAdjustments className='entity-search__filter-box__icon' />
              </div>
            </div>
            <button className='btn btn-l'>
              <FaEdit className='button-box__icon' />
              Bulk edit
            </button>
          </div>
          <div className='authors'>
            <div className='entity-table'>
              <div className='entity-table__header events-table-row'>
                <input
                  type='checkbox'
                  className='entity-check'
                  id='selectAll'
                  checked={isCheckedAll}
                  onChange={() => setIsCheckedAll(!isCheckedAll)}
                />
                <label htmlFor='selectAll'></label>
                <div>Name</div>
                <div>Number of assigned titles</div>
                <div className='asc'>Status</div>
                <div className='asc'>Manual control</div>
                <div>Last Updated</div>
                <div>Actions</div>
              </div>
            </div>
            {events
              .filter(event => {
                if (activeFilter === 'fullfilled' && event.checked) {
                  return true
                } else if (activeFilter === 'unfinished' && !event.checked) {
                  return true
                } else if (activeFilter === 'all') {
                  return true
                } else {
                  return false
                }
              })
              .filter(event => {
                if (searchTerm === '') {
                  return true
                } else if (
                  `${event.name}`
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ) {
                  return true
                } else {
                  return false
                }
              })
              .map(event => (
                <EventsTableRow
                  key={event.id}
                  event={event}
                  isChecked={checkedEvents.includes(event.id)}
                  onCheckboxChange={handleCheckboxChange}
                />
              ))
              .reverse()}
            <div className='table-footer'>
              <button
                type='button'
                className='add-issue-btn add-issue-btn-purple'
                disabled={checkedEvents.length === 0}
              >
                <BiSlider className='add-issue-btn-icon' /> mass editing
              </button>
              <button
                type='button'
                className='add-issue-btn add-issue-btn-red'
                onClick={deleteSelectedEvents}
                disabled={checkedEvents.length === 0}
              >
                <BiSolidTrash className='add-issue-btn-icon' /> delete selected
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EventsTable
