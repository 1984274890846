import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { login, reset } from '../features/users/userSlice'
import logo from '../utils/img/ch-logo.png'
import Spinner from '../components/Spinner'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { auth, userLoading, userError, userSuccess, message } = useSelector(
    state => state.users
  )

  useEffect(() => {
    if (userError) {
      toast.error(message)
    }

    // Redirect when logged in
    if (userSuccess || auth) {
      navigate('/dashboard')
    }

    dispatch(reset())
  }, [userError, userSuccess, auth, message, navigate, dispatch])

  const onSubmit = e => {
    e.preventDefault()

    const userData = {
      email,
      password,
    }

    dispatch(login(userData))
  }

  if (userLoading) {
    return <Spinner />
  }

  return (
    <>
      <section className='section-login'>
        <div className='login-box'>
          <div className='login-box__info'>
            <img src={logo} alt='logo' className='login-box__info__logo' />
            <div className='login-box__info__heading-1'>
              <h2 className='heading-2 heading-2--light'>
                Welcome to comicshub
              </h2>
            </div>
            <div className='login-box__info__text'>
              <p className='p'>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam.
              </p>
              <div className='login-box__info__btn'>
                <Link to='/dashboard' className='btn-text'>
                  Know More
                </Link>
              </div>
            </div>
          </div>
          <div className='login-box__form'>
            <div className='login-box__form__container'>
              <form onSubmit={onSubmit}>
                <div className='login-box__form__heading-2'>
                  <h2 className='heading-2 heading-2--dark'>Sign In</h2>
                </div>
                <div className='login-box__form__group'>
                  <input
                    type='email'
                    className='login-box__form__input'
                    placeholder='Email'
                    id='email'
                    name='email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                    autoComplete='off'
                  />
                  <label htmlFor='username' className='login-box__form__label'>
                    Email
                  </label>
                </div>
                <div className='login-box__form__group'>
                  <input
                    type='password'
                    className='login-box__form__input'
                    placeholder='Password'
                    id='password'
                    name='password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    minLength='5'
                    required
                    autoComplete='off'
                  />
                  <label htmlFor='password' className='login-box__form__label'>
                    Password
                  </label>
                </div>
                <div className='login-box__form__btn'>
                  <button className='btn btn-full'>Login</button>
                </div>
              </form>
            </div>
          </div>
          <div className='login__circle-1'></div>
          <div className='login__circle-2'></div>
        </div>
      </section>
    </>
  )
}

export default Login
