import { Link } from 'react-router-dom'

const Dropdown = ({ submenus, dropdown }) => {
  return (
    <ul className={`dropdown ${dropdown ? 'show' : ''}`}>
      {submenus.map((submenu, index) => (
        <li key={index} className='menu-items' title={submenu.description}>
          <Link to={submenu.url} className='menu-items__link'>
            {submenu.title}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default Dropdown
