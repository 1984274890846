import {
  HiChartBar,
  HiDatabase,
  HiUserGroup,
  HiOfficeBuilding,
  HiCog,
} from 'react-icons/hi';
import { SiBookstack } from 'react-icons/si';
import { FaUser, FaBook, FaPenAlt } from 'react-icons/fa';
import { ImEarth, ImBooks, ImBubble } from 'react-icons/im';
import { RiShieldStarLine, RiOrganizationChart } from 'react-icons/ri';
import { SiUnity } from 'react-icons/si';

export const menuItems = [
  {
    title: <HiChartBar />,
    url: '/dashboard',
    description: 'Dashboard',
    id: '1',
    key: '1',
  },
  {
    title: <HiDatabase />,
    description: 'Database',
    id: '2',
    key: '2',
    submenu: [
      {
        title: (
          <div className='menu-item'>
            <SiBookstack />
            <p>Series</p>
          </div>
        ),
        url: '/series',
        description: 'Series',
        id: '3',
        key: '3',
      },
      {
        title: (
          <div className='menu-item'>
            <FaBook />
            <p>Books</p>
          </div>
        ),
        url: '/books',
        description: 'Books',
        id: '4',
        key: '4',
      },
      {
        title: (
          <div className='menu-item'>
            <ImBooks />
            <p>Book Series</p>
          </div>
        ),
        url: '/book-series',
        description: 'Book Series',
        id: '5',
        key: '5',
      },
      {
        title: (
          <div className='menu-item'>
            <RiShieldStarLine />
            <p>Characters</p>
          </div>
        ),
        url: '/characters',
        description: 'Characters',
        id: '6',
        key: '6',
      },
      {
        title: (
          <div className='menu-item'>
            <RiOrganizationChart />
            <p>Organizations</p>
          </div>
        ),
        url: '/organizations',
        description: 'Organizations',
        id: '7',
        key: '7',
      },

      {
        title: (
          <div className='menu-item'>
            <FaPenAlt />
            <p>Story Arcs</p>
          </div>
        ),
        url: '/story-arcs',
        description: 'Story Arcs',
        id: '8',
        key: '8',
      },
      {
        title: (
          <div className='menu-item'>
            <SiUnity />
            <p>Events</p>
          </div>
        ),
        url: '/events',
        description: 'Events',
        id: '9',
        key: '9',
      },
      {
        title: (
          <div className='menu-item'>
            <FaUser />
            <p>Authors</p>
          </div>
        ),
        url: '/authors',
        description: 'Authors',
        id: '10',
        key: '10',
      },
      {
        title: (
          <div className='menu-item'>
            <HiOfficeBuilding />
            <p>Publishings</p>
          </div>
        ),
        url: '/publishings',
        description: 'Publishings',
        id: '11',
        key: '11',
      },
      {
        title: (
          <div className='menu-item'>
            <ImEarth />
            <p>Earths</p>
          </div>
        ),
        url: '/earths',
        description: 'Earths',
        id: '12',
        key: '12',
      },
    ],
  },
  {
    title: <HiUserGroup />,
    url: '/users',
    description: 'Users',
    id: '13',
    key: '13',
  },
  {
    title: <HiCog />,
    description: 'Settings',
    id: '14',
    key: '14',
    submenu: [
      {
        title: (
          <div className='menu-item'>
            <ImBubble />
            <p>Author - roles</p>
          </div>
        ),
        url: '/author-roles',
        description: 'Author roles',
        id: '15',
        key: '15',
      },
    ],
  },
];
