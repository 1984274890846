import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteStory,
  updateStory,
  createStory,
} from '../features/stories/storySlice'
import { toast } from 'react-toastify'
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg'
import { getStory } from '../features/stories/storySlice'
import { BsFillXCircleFill, BsCheckCircleFill } from 'react-icons/bs'
import CreatableSelect from 'react-select/creatable'
import { FaUser, FaTags, FaPlus, FaSave } from 'react-icons/fa'
import { HiSave, HiXCircle } from 'react-icons/hi'
import Select from 'react-select'

const StoryBlock = ({ story, onDeleteStory, initialAuthorRoles, authors }) => {
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [authorRoles, setAuthorRoles] = useState(initialAuthorRoles)

  const storyId = story.id

  const { storyError, message } = useSelector(state => state.stories)
  const storyAuthors = story.authors

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getStory(storyId))
    }

    fetchData()
  }, [dispatch, storyId])

  useEffect(() => {
    setName(!story.name ? '' : story.name)
    setDescription(!story.description ? '' : story.description)

    // Check if story has authors loaded
    if (storyAuthors && storyAuthors?.length > 0) {
      const updatedAuthorRoles = initialAuthorRoles?.map(role => {
        const authorsForRole = storyAuthors?.filter(
          author => author.authorRoleId === role.id
        )

        return {
          ...role,
          selectedAuthor: authorsForRole?.length > 0 ? authorsForRole[0] : null,
        }
      })

      setAuthorRoles(updatedAuthorRoles)
    } else {
      // If no authors loaded, reset authorRoles to initialAuthorRoles
      setAuthorRoles(initialAuthorRoles)
    }
  }, [story, storyAuthors, initialAuthorRoles])

  const onStorySave = async e => {
    e.preventDefault()

    // Filter out roles with null selectedAuthor
    const filteredAuthorRoles = authorRoles?.filter(role => role.selectedAuthor)

    const storyData = {
      name,
      description,
      authors: filteredAuthorRoles.map(role => ({
        authorId: role.selectedAuthor
          ? role.selectedAuthor.id || role.selectedAuthor.authorId
          : null,
        authorRoleId: role.id,
      })),
    }

    await dispatch(updateStory({ storyId, storyData }))

    if (storyError) {
      toast.error(message)
    } else {
      toast.success('Story successfully updated!')
    }

    // Fetch the updated story data after the save
    await dispatch(getStory(storyId))
  }

  const deleteHandler = () => {
    onDeleteStory(storyId)
  }

  const handleAuthorChange = (roleId, selectedOption) => {
    setAuthorRoles(prevRoles =>
      prevRoles.map(role =>
        role.id === roleId ? { ...role, selectedAuthor: selectedOption } : role
      )
    )
  }

  return (
    <div className='detail-form grid-1 mar-top-s'>
      <div className='story-header'>
        Title:
        <div className='volume__container'>
          <input
            placeholder='Volume name'
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
      </div>
      <div className='story-reprint'>- is reprint?</div>
      <div className='story-del-button'>
        <button className='volume__save-btn mar-right-xs' onClick={onStorySave}>
          <BsCheckCircleFill className='volume__save' />
        </button>
        <button className='volume__delete-btn' onClick={deleteHandler}>
          <BsFillXCircleFill className='volume__delete' />
        </button>
      </div>

      <div className='story-authors'>
        {authorRoles?.map(role => (
          <div key={role.id} className='story-authors-line'>
            <div className='story-authors-line-title'>{role.name}:</div>
            <div className='input-area'>
              <label htmlFor={`author-${role.id}`} className='input__label'>
                Choose an author
              </label>
              <Select
                id={`author-${role.id}`}
                className='react-single-select'
                classNamePrefix='react-single-select'
                placeholder='Select author'
                value={role.selectedAuthor || null} // Allow null or empty object
                options={authors}
                getOptionLabel={({ firstName, lastName }) =>
                  `${firstName} ${lastName}`
                }
                getOptionValue={({ id }) => id}
                onChange={selectedOption =>
                  handleAuthorChange(role.id, selectedOption)
                }
              />
            </div>
          </div>
        ))}
      </div>

      <div className='story-organization'>
        <div className='input-box-1'>
          <FaTags className='input__icon' />
          <div className='input-area-multi'>
            <label htmlFor='tags' className='input__label'>
              Organizations
            </label>
            <CreatableSelect
              className='react-select-container'
              classNamePrefix='react-select'
              placeholder='Add Organizations'
              isMulti
            />
          </div>
        </div>
      </div>
      <div className='story-characters'>
        <div className='detail-form__rel-char container'>
          <label htmlFor='basicInfo' className='entity-label'>
            Related Characters
          </label>
          <div className='container-heading-box'>
            <div className='container-heading'>Related Characters</div>
            <button type='button' className='btn btn-s'>
              <FaPlus className='button-box__icon' /> Add Character
            </button>
          </div>

          <div className='flex-row'>
            <div className='input-box'>
              <FaUser className='input__icon' />
              <div className='input-area'>
                <label htmlFor='alias' className='input__label'>
                  Name
                </label>
                <input
                  type='text'
                  className='input'
                  id='alias'
                  placeholder='Add Name'
                  autoComplete='off'
                />
              </div>
            </div>

            <div className='input-box mar-left-s'>
              <FaUser className='input__icon' />
              <div className='input-area'>
                <label htmlFor='alias' className='input__label'>
                  Alias
                </label>
                <input
                  type='text'
                  className='input'
                  id='alias'
                  placeholder='Choose Alias'
                  autoComplete='off'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='story-description'>
        <EditorProvider>
          <Editor
            className='rsw-editor'
            value={description}
            onChange={e => setDescription(e.target.value)}
          >
            <Toolbar>
              <BtnBold />
              <BtnItalic />
            </Toolbar>
          </Editor>
        </EditorProvider>
      </div>
    </div>
  )
}

export default StoryBlock
