import Navigation from '../layout/Navigation';
import Topbar from '../layout/Topbar';
import Spinner from '../components/Spinner';
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg';
import { FaUser, FaTags, FaPenNib, FaCloudUploadAlt } from 'react-icons/fa';
import { HiSave, HiXCircle, HiOfficeBuilding } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import { RiShieldStarLine } from 'react-icons/ri';
import { BiSolidPlusCircle } from 'react-icons/bi';
import { ImEarth } from 'react-icons/im';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  getCharacter,
  deleteCharacter,
  getCharacters,
  updateCharacter,
} from '../features/characters/characterSlice';
import {
  getTags,
  createTag,
  addNewTagToState,
} from '../features/tags/tagSlice';

import { addedBy, updatedBy } from '../features/users/userSlice';

// import { format } from 'date-fns';
// import { id } from 'date-fns/esm/locale'

import ImageUploader from '../components/ImageUploader';
import { getPublishings } from '../features/publishings/publishingSlice';
import {
  createAlias,
  deleteAlias,
  getAlias,
  getAliases,
} from '../features/aliases/aliasSlice';
import CharacterAlias from '../components/entityComponents/CharacterAlias';
import Breadcrumbs from '../layout/Breadcrumbs';

const CharacterDetail = () => {
  const [name, setName] = useState('');
  const [civilName, setCivilName] = useState('');
  const [altNames, setAltNames] = useState([]);
  const [description, setDescription] = useState('');
  const [charPublishing, setCharPublishing] = useState([]);
  const [charEarth, setCharEarth] = useState([]);
  const [tagIds, setTagIds] = useState([]);
  const [publishingEarths, setPublishingEarths] = useState([]);

  const [newAlias, setNewAlias] = useState('');

  // CREATE ALIAS
  const createAliasHandler = async () => {
    const aliasData = {
      characterId: character.id,
      name: newAlias,
    };
    if (aliasData.name === '') {
      toast.warning(`Alias name can't be empty`);
    } else {
      await dispatch(createAlias(aliasData));
      await dispatch(getCharacter(characterId));
      await toast.success('Alias successfully created!');
      await setNewAlias('');
    }
  };

  // RELATED CHARACTERS
  const [relatedCharacters, setRelatedCharacters] = useState(['']);

  const updateRelatedCharacters = (index, value) => {
    const newRelatedCharacters = [...relatedCharacters];
    newRelatedCharacters[index] = value;
    setRelatedCharacters(newRelatedCharacters);
  };
  const addRelatedCharacter = () => {
    setRelatedCharacters([...relatedCharacters, '']);
  };
  const removeRelatedCharacter = (index) => {
    const newRelatedCharacters = [...relatedCharacters];
    newRelatedCharacters.splice(index, 1); // Odstranění řádku s daným indexem
    setRelatedCharacters(newRelatedCharacters);
  };

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch user to Redux by URL params
  const { characterId } = params;
  const { character, characterLoading, characterError, message } = useSelector(
    (state) => state.characters
  );
  const { aliases } = character;
  const { tags } = useSelector((state) => state.tags);
  const { publishings, publishing } = useSelector((state) => state.publishings);

  useEffect(() => {
    if (characterError) {
      toast.error(message);
    }

    dispatch(getCharacter(characterId));
    // eslint-disable-next-line
  }, [characterError, message, characterId]);

  useEffect(() => {
    dispatch(getTags());
    dispatch(getPublishings());
    // eslint-disable-next-line
  }, []);

  // Set default form data from characters reducer
  useEffect(() => {
    setName(character.name && character.name);

    setCivilName(!character.civilName ? '' : character.civilName);
    setAltNames(
      character.altNames?.map((altName) => ({ value: altName, label: altName }))
    );
    setTagIds(character.tags?.map((tag) => ({ id: tag.id, name: tag.name })));
    setDescription(!character.description ? '' : character.description);
    setCharPublishing(!character.publishings ? [] : character.publishings[0]);
    setCharEarth(!character.earths ? [] : character.earths[0]);
    dispatch(addedBy(character.addedBy));
    dispatch(updatedBy(character.lastUpdatedBy));
  }, [dispatch, character]);

  // CREATE NEW TAG AND ASSIGNE IT TO PUBLISHING
  const handleCreateTag = async (inputValue) => {
    try {
      const { payload: createdTag } = await dispatch(
        createTag({ name: inputValue })
      );
      if (createdTag && createdTag.id) {
        dispatch(addNewTagToState(createdTag));
        setTagIds((prevTagIds) => [
          ...prevTagIds,
          { id: createdTag.id, name: createdTag.name },
        ]);
      } else {
        console.error('Failed to create Tag or no Tag ID returned.');
      }
    } catch (error) {
      console.error('Error creating Tag:', error);
    }
  };

  const publishingOnChange = async (e) => {
    const selectedPublishing = e;
    setCharPublishing(selectedPublishing);

    // Update 'Earths' based on the selected publishing
    const selectedPublishingId = selectedPublishing.id;
    const selectedPublishingData = publishings.find(
      (publishing) => publishing.id === selectedPublishingId
    );

    if (selectedPublishingData) {
      const selectedPublishingEarths = selectedPublishingData.earths;
      // Update 'publishingEarths' state with the selected Earths
      setPublishingEarths(selectedPublishingEarths);

      // Update 'charEarth' with the first Earth from the selected publishing (you can modify this based on your logic)
      if (selectedPublishingEarths && selectedPublishingEarths.length > 0) {
        setCharEarth(selectedPublishingEarths[0]);
      }
    }
  };

  // Update character
  const onSubmit = (e) => {
    e.preventDefault();

    const characterData = {
      name,
      civilName,
      altNames: altNames.map(({ value }) => value),
      tagIds: tagIds.map(({ id }) => id),
      description,
      publishingIds: !charPublishing ? [] : [charPublishing.id],
      earthIds: !charEarth ? [] : [charEarth.id],
    };

    dispatch(updateCharacter({ characterId, characterData }));

    if (characterError) {
      toast.error(message);
    } else {
      toast.success('Character successfully updated!');
    }
  };

  // Delete character
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteCharacter(characterId));
      navigate('/characters');
      dispatch(getCharacters());
    }
  };

  const creator = useSelector((state) => state.users.addedBy);
  const updator = useSelector((state) => state.users.updatedBy);

  const breadcrumbsPath = ['charcaters', `${character.name}`];

  if (characterLoading) {
    return <Spinner />;
  }
  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <Breadcrumbs path={breadcrumbsPath} />
          <form onSubmit={onSubmit}>
            <div className='detail-form grid-1'>
              <div className='detail-form__heading'>
                <div className='heading-form'>
                  <RiShieldStarLine className='detail-form__icon' />{' '}
                  {character.name}{' '}
                </div>
                <div className='entity-info'>
                  <p className='entity-info__id bold'>ID: {character.id}</p>
                  <div className='entity-info__slug bold mar-left-xs'>
                    {character.slug}
                  </div>
                </div>
              </div>

              <div className='detail-form__user-info'>
                <p className='pad-bottom-xs mar-left-s'>
                  Created:{' '}
                  <span className='bold'>
                    {new Date(character.createdAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${creator.name} ${creator.surname}`}</span>
                </p>
                <p className='mar-left-s'>
                  Updated:{' '}
                  <span className='bold'>
                    {new Date(character.updatedAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${updator.name} ${updator.surname}`}</span>
                </p>
              </div>

              <div className='detail-form__button-box'>
                <button type='submit' className='btn-new btn-save mar-right-xs'>
                  <HiSave className='button-icon-new' />
                </button>
                <button
                  type='button'
                  className='btn-new btn-delete'
                  onClick={deleteHandler}
                >
                  <MdDelete className='button-icon-new' />
                </button>
              </div>

              <ImageUploader />

              <div className='detail-form__main container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  Basic Info
                </label>
                <div className='input-box'>
                  <FaUser className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='name' className='input__label'>
                      Name
                    </label>
                    <input
                      type='text'
                      className='input'
                      id='name'
                      placeholder='Add Name'
                      autoComplete='off'
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className='required-mark-box'>
                    <span className='required-mark'>&#10044;</span>
                  </div>
                </div>
                <div className='input-box'>
                  <FaUser className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='civilName' className='input__label'>
                      Civil Name
                    </label>
                    <input
                      type='text'
                      className='input'
                      id='civilName'
                      placeholder='Add Civil Name'
                      autoComplete='off'
                      value={civilName}
                      onChange={(e) => setCivilName(e.target.value)}
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <FaPenNib className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='authors' className='input__label'>
                      Authors
                    </label>
                    <CreatableSelect
                      className='react-select-container'
                      classNamePrefix='react-select'
                      placeholder='Add Authors'
                      id='authors'
                      isMulti
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <HiOfficeBuilding className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='earth' className='input__label'>
                      Publishing
                    </label>
                    <Select
                      className='react-single-select'
                      classNamePrefix='react-single-select'
                      placeholder='Choose Publishing'
                      id='publishing'
                      options={publishings}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      value={charPublishing}
                      onChange={publishingOnChange}
                    />
                  </div>
                </div>

                <div className='input-box-1'>
                  <BsFillPersonLinesFill className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='pseudo' className='input__label'>
                      Alt Names
                    </label>
                    <CreatableSelect
                      className='react-select-container'
                      classNamePrefix='react-select'
                      placeholder='Add Alt Names'
                      isMulti
                      value={altNames}
                      onChange={(e) => setAltNames(e)}
                    />
                  </div>
                </div>

                <div className='input-box-1'>
                  <FaTags className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='tags' className='input__label'>
                      Tags
                    </label>
                    <CreatableSelect
                      className='react-select-container'
                      classNamePrefix='react-select'
                      placeholder='Add Tags'
                      isMulti
                      options={tags}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      value={tagIds}
                      onChange={(e) => setTagIds(e)}
                      onCreateOption={handleCreateTag}
                      isValidNewOption={(inputValue) => inputValue}
                      getNewOptionData={(inputValue) => ({
                        value: inputValue,
                        name: `Create ${inputValue}`,
                      })}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <ImEarth className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='earth' className='input__label'>
                      Earth
                    </label>
                    <Select
                      className='react-single-select'
                      classNamePrefix='react-single-select'
                      placeholder='Choose Earth'
                      id='earth'
                      value={charEarth}
                      options={publishingEarths}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      onChange={(e) => setCharEarth(e)}
                    />
                  </div>
                </div>
              </div>

              {/* CHARACTER ALIASES BOX */}

              <div className='detail-form__aliases container-2'>
                <label htmlFor='basicInfo' className='entity-label'>
                  aliases
                </label>

                {aliases?.map((alias) => (
                  <CharacterAlias
                    key={alias.id}
                    alias={alias}
                    character={character}
                  />
                ))}

                <div className='alias-ticket container'>
                  <>
                    <input
                      className='img-uploaded'
                      id='alias-img'
                      type='file'
                      accept='image/*,.png,.jpeg'
                    />

                    <label className='upload-label' htmlFor='alias-img'>
                      <div className='circle-img-upload'>
                        <FaCloudUploadAlt className='img-upload__icon' />
                      </div>
                    </label>
                  </>

                  <div className='input-box-alias'>
                    <div className='input-area-alias'>
                      <label htmlFor='alias' className='input__label'>
                        New Alias
                      </label>
                      <input
                        type='text'
                        className='input-alias'
                        placeholder='Add Alias'
                        autoComplete='off'
                        id='alias'
                        value={newAlias}
                        onChange={(e) => setNewAlias(e.target.value)}
                      />
                    </div>
                    <button
                      className='btn-add-alias'
                      onClick={createAliasHandler}
                      type='button'
                    >
                      <BiSolidPlusCircle />
                    </button>
                  </div>
                </div>
              </div>

              <div className='detail-form__description-3 container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  description
                </label>
                <EditorProvider>
                  <Editor
                    className='rsw-editor'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
              </div>

              <div className='detail-form__connect-box container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  related characters
                </label>
                {!relatedCharacters?.length ? (
                  <p className='input-label-info'>
                    {' '}
                    No related characters added to this entity yet.
                  </p>
                ) : (
                  <div className='flex-row-box'>
                    {relatedCharacters.map((relatedCharacter, index) => (
                      <div className='flex-row' key={index}>
                        <div className='select-cont-flex'>
                          <div className='connect-num'>{index + 1}</div>

                          <CreatableSelect
                            className='single-select-number-disabled'
                            classNamePrefix='single-select-number-disabled'
                            placeholder='Related Character'
                            autoComplete='off'
                            id={`relatedCharacter${index}`}
                            value={relatedCharacter}
                            onChange={(e) =>
                              updateRelatedCharacters(index, e.target.value)
                            }
                          />
                        </div>

                        <div className='delete-btn-box mar-left-xs'>
                          <HiXCircle
                            className='btn-icon-2'
                            onClick={() => removeRelatedCharacter(index)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  type='button'
                  className='add-issue-btn add-issue-btn-blue'
                  onClick={addRelatedCharacter}
                >
                  <BiSolidPlusCircle className='add-issue-btn-icon' />
                  add related character
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CharacterDetail;
