import { Link, useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTrashAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import {
  getEarths,
  deleteEarth,
  updateEarthStatus
} from '../../features/earths/earthSlice'

const EarthsTableRow = ({ earth, isChecked, onCheckboxChange }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const publishings = useSelector(state => state.publishings.publishings)

  const getAssignedPublishings = () => {
    const assignedPublishingIds = earth.publishingIds
    return publishings.filter(publishing =>
      assignedPublishingIds.includes(publishing.id)
    )
  }

  const [completed, setCompleted] = useState(earth.completed)
  const [status, setStatus] = useState(
    earth.completed ? 'Finished' : 'Unfinished'
  )

  // Delete earth
  const deleteHandler = async () => {
    if (window.confirm('Are you sure?')) {
      await dispatch(deleteEarth(earth.id))
      await dispatch(updateEarthStatus({ earthId: earth.id, completed }))
      setStatus(completed ? 'Finished' : 'Unfinished')
      navigate('/earths')
      dispatch(getEarths())
    }
  }

  const assignedPublishings = getAssignedPublishings()

  const handleCheckboxChange = () => {
    const updatedCompleted = !completed
    setCompleted(updatedCompleted)
    dispatch(updateEarthStatus({ earthId: earth.id, completed: updatedCompleted }))
    setStatus(updatedCompleted ? 'Finished' : 'Unfinished')
  }

  return (
    <>
      <div className='entity-table__row earths-table-row'>
        <input
          type='checkbox'
          className='entity-check'
          id={earth.id}
          checked={isChecked}
          onChange={() => onCheckboxChange(earth.id)}
        />
        <label htmlFor={earth.id}></label>
        <Link to={`/earths/${earth.id}`} className='entity-table__row__link'>
          {earth.name}
        </Link>
        <div>{assignedPublishings.map(publishing => publishing.name).join(', ')}</div>
        <div className='asc'>{status}</div>
        <label className='switch asc'>
          <input
            type='checkbox'
            checked={completed}
            onChange={handleCheckboxChange}
          />
          <span className='slider'></span>
        </label>
        <div>{new Date(earth.updatedAt).toLocaleString('en-GB')}</div>
        <Popup
          trigger={
            <button className='user-nav__user-photo'>
              <BsThreeDotsVertical className='entity-table__settings-icon' />
            </button>
          }
          position='bottom right'
        >
          <button className='btn-inner btn-inner__table' onClick={deleteHandler}>
            <FaTrashAlt /> &nbsp; Delete
          </button>
        </Popup>
      </div>
    </>
  )
}

export default EarthsTableRow