import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FaUser, FaCloudUploadAlt } from 'react-icons/fa';
import { HiXCircle } from 'react-icons/hi';
import { BsCheckCircle } from 'react-icons/bs';
import { deleteAlias, updateAlias } from '../../features/aliases/aliasSlice';
import { getCharacter } from '../../features/characters/characterSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const CharacterAlias = ({ alias, character }) => {
  const dispatch = useDispatch();
  const aliasId = alias.id;
  const [aliasValue, setAliasValue] = useState(alias.name);
  const { aliasError, message } = useSelector((state) => state.aliases);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [originalAlias, setOriginalAlias] = useState(aliasValue);

  useEffect(() => {
    // Check if the alias is different from the original alias
    setIsButtonVisible(aliasValue !== originalAlias);
  }, [aliasValue, originalAlias]);

  // UPDATE ALIAS
  const updateAliasHandler = (e) => {
    e.preventDefault();

    const aliasData = {
      characterId: character.id,
      name: aliasValue,
    };

    dispatch(updateAlias({ aliasId, aliasData }));

    if (aliasError) {
      toast.error(message);
    } else {
      toast.success('Alias successfully updated!');
      setIsButtonVisible(false);
      setOriginalAlias(aliasValue);
    }
  };

  // DELETE ALIAS
  const deleteAliasHandler = async () => {
    try {
      await dispatch(deleteAlias(alias.id));
      await dispatch(getCharacter(character.id));
      toast.success('Alias succesfully deleted!');
    } catch (error) {
      console.error('Chyba při mazání aliasu:', error);
    }
  };

  return (
    <>
      <div className='alias-ticket container'>
        <>
          <div className='alias-del-button'>
            <HiXCircle className='btn-icon-2' onClick={deleteAliasHandler} />
          </div>
          <input
            className='img-uploaded'
            id='alias-img'
            type='file'
            accept='image/*,.png,.jpeg'
          />

          <label className='upload-label' htmlFor='alias-img'>
            <div className='circle-img-upload'>
              <FaCloudUploadAlt className='img-upload__icon' />
            </div>
          </label>
        </>

        <div className='input-box'>
          <div className='input-area-alias'>
            <label htmlFor='alias' className='input__label'>
              Alias Name
            </label>
            <input
              type='text'
              className='input-alias'
              placeholder='Add Alias'
              autoComplete='off'
              value={aliasValue}
              onChange={(e) => setAliasValue(e.target.value)}
            />
          </div>
          <button
            className={`btn-confirm ${isButtonVisible ? '' : 'btn-invisible'}`}
            type='button'
            onClick={updateAliasHandler}
            disabled={!isButtonVisible}
          >
            <BsCheckCircle />
          </button>
        </div>
      </div>
    </>
  );
};

export default CharacterAlias;
