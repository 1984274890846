import axios from 'axios'

const backendURL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${backendURL}/api/v1/volumes/`

// Create
const createVolume = async (volume, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.post(API_URL, volume, config)
  return response.data
}

// Get all
const getVolumes = async token => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.get(API_URL, config)
  return response.data
}

// Get one
const getVolume = async (volumeId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + volumeId, config)
  return response.data
}

// Update
const updateVolume = async (volumeId, volumeData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.put(API_URL + volumeId, volumeData, config)

  return response.data
}

// Delete
const deleteVolume = async (volumeId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + volumeId, config)
  return response.data
}

const volumeService = {
  createVolume,
  getVolumes,
  getVolume,
  updateVolume,
  deleteVolume,
}

export default volumeService
