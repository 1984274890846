import React, { useState } from 'react';
// import Select from 'react-select';
import { HiXCircle } from 'react-icons/hi';
import { FaTags } from 'react-icons/fa';
import { BiSolidPlusCircle } from 'react-icons/bi';
import CreatableSelect from 'react-select/creatable';

const AssignedCharacters = () => {
  const [characters, setCharacters] = useState(['']);

  const updateCharacters = (index, value) => {
    const newCharacters = [...characters];
    newCharacters[index] = value;
    setCharacters(newCharacters);
  };
  const addCharacter = () => {
    setCharacters([...characters, '']);
  };
  const removeCharacter = (index) => {
    const newCharacters = [...characters];
    newCharacters.splice(index, 1); // Odstranění řádku s daným indexem
    setCharacters(newCharacters);
  };

  return (
    <>
      {/* ASSIGNED CHARACTERS AND ORGANIZATIONS*/}

      <div className='detail-form__connect-box container' id='author-team'>
        <label htmlFor='assigned-issues' className='entity-label'>
          characters and organizations
        </label>

        <div className='input-box-1 mar-bottom-s'>
          <FaTags className='input__icon' />
          <div className='input-area-multi'>
            <label htmlFor='tags' className='input__label'>
              Organizations
            </label>
            <CreatableSelect
              className='react-select-container'
              classNamePrefix='react-select'
              placeholder='Add Organizations'
              isMulti
              //   options={tags}
              //   getOptionLabel={({ name }) => name}
              //   getOptionValue={({ id }) => id}
              //   value={tagIds}
              //   onCreateOption={handleCreateTag} // Handle tag creation
              //   onChange={(e) => setTagIds(e)}
              //   isValidNewOption={(inputValue) => inputValue}
              //   getNewOptionData={(inputValue) => ({
              //     value: inputValue,
              //     name: `Create ${inputValue}`,
              //   })}
              //   menuPortalTarget={document.body} // Set menuPortalTarget to document.body
              //   ref={selectRef} // Attach the ref to CreatableSelect
            />
          </div>
        </div>

        {!characters?.length ? (
          <p className='input-label-info'>
            {' '}
            No characters assigned to this entity yet.
          </p>
        ) : (
          <div className='author-team-box'>
            {characters.map((character, index) => (
              <div className='assigned-characters-row' key={index}>
                <CreatableSelect
                  className='single-select-disabled'
                  classNamePrefix='single-select-disabled'
                  placeholder='Character'
                  autoComplete='off'
                  id={`character${index}`}
                  value={character}
                  onChange={(e) => updateCharacters(index, e.target.value)}
                />

                <CreatableSelect
                  className='single-select-disabled'
                  classNamePrefix='single-select-disabled'
                  placeholder='Alias'
                  autoComplete='off'
                  //   id={`author${index}`}
                  //   value={author}
                  //   onChange={(e) => updateAuthors(index, e.target.value)}
                />

                <div className='delete-btn-box mar-left-xs'>
                  <HiXCircle
                    className='btn-icon-2'
                    onClick={() => removeCharacter(index)}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        <button
          type='button'
          className='add-issue-btn add-issue-btn-blue'
          onClick={addCharacter}
        >
          <BiSolidPlusCircle className='add-issue-btn-icon' />
          add character
        </button>
      </div>
    </>
  );
};

export default AssignedCharacters;
