import { useEffect, useState } from 'react'
import {
  createPublishing,
  getPublishings,
} from '../../features/publishings/publishingSlice'
import { getTags } from '../../features/tags/tagSlice'
import { useDispatch, useSelector } from 'react-redux'
import CreatableSelect from 'react-select/creatable'
import { toast } from 'react-toastify'
import { FaTags } from 'react-icons/fa'
import { MdLibraryAddCheck, MdDriveFileRenameOutline } from 'react-icons/md'
import { HiOfficeBuilding } from 'react-icons/hi'
import { ImEarth } from 'react-icons/im'
import ImageUploader from '../../components/ImageUploader'

const CreatePublishing = () => {
  const [name, setName] = useState('')
  const [headquarters, setHeadquarters] = useState('')
  const [tagIds, setTagIds] = useState([])

  const dispatch = useDispatch()

  const { publishingError, message } = useSelector(state => state.publishings)
  const { tags } = useSelector(state => state.tags)

  useEffect(() => {
    dispatch(getTags())
    // eslint-disable-next-line
  }, [])

  const onSubmit = e => {
    e.preventDefault()

    const publishingData = {
      name,
      headquarters,
      tagIds: tagIds.map(({ id }) => id),
    }

    dispatch(createPublishing(JSON.stringify(publishingData)))
    dispatch(getPublishings())

    if (publishingError) {
      toast.error(message)
    } else {
      toast.success(`Publishing created!`)
    }
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className='detail-form grid-2'>
          <div className='detail-form__heading'>
            <div className='heading-form'>Create Publishing</div>
          </div>

          <div className='detail-form__user-info'>
            <p>
              <span className='required-mark'>&#10044;</span>Data marked with an
              asterisk are required, please fill them in before saving ...{' '}
            </p>
          </div>

          <div className='detail-form__button-box'>
            <button type='submit' className='btn btn-l' onSubmit={onSubmit}>
              <MdLibraryAddCheck className='button-icon' />
              Create
            </button>
          </div>

          <ImageUploader />

          <div className='detail-form__main-1 container'>
            <div className='input-box'>
              <MdDriveFileRenameOutline className='input__icon' />
              <div className='input-area'>
                <label htmlFor='name' className='form__label'>
                  Publishing Name
                </label>
                <input
                  type='text'
                  className='input'
                  id='name'
                  placeholder='Publishing Name'
                  value={name}
                  onChange={e => setName(e.target.value)}
                  autoComplete='off'
                  required
                />
              </div>
              <div className='required-mark-box'>
                <span className='required-mark'>&#10044;</span>
              </div>
            </div>

            <div className='input-box-2'>
              <FaTags className='input__icon' />
              <div className='input-area-multi'>
                <label htmlFor='tags' className='form__label'>
                  Tags
                </label>
                <CreatableSelect
                  className='react-select-container'
                  classNamePrefix='react-select'
                  placeholder='Add tags'
                  isMulti
                  options={tags}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  value={tagIds}
                  onChange={e => setTagIds(e)}
                />
              </div>
            </div>

            <div className='input-box'>
              <HiOfficeBuilding className='input__icon' />
              <div className='input-area'>
                <label htmlFor='headquarters' className='form__label'>
                  Headquarters
                </label>
                <input
                  type='text'
                  className='input'
                  id='headquarters'
                  value={headquarters}
                  onChange={e => setHeadquarters(e.target.value)}
                  placeholder='Headquarters'
                  autoComplete='off'
                />
              </div>
            </div>

            <div className='input-box'>
              <ImEarth className='input__icon' />
              <div className='input-area'>
                <label htmlFor='earth' className='form__label'>
                  Default Earth
                </label>
                <input
                  type='text'
                  className='input'
                  id='earth'
                  placeholder='Default Earth'
                  autoComplete='off'
                />
              </div>
            </div>
          </div>

          <div className='detail-form__description container'>
            <textarea
              rows='num'
              cols='num'
              type='text'
              className='input__textarea'
              name='description'
              placeholder='Input description..'
              autoComplete='off'
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default CreatePublishing
