import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import imageService from './imageService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  images: [],
  image: {},
  imageError: false,
  imageSuccess: false,
  imageLoading: false,
  message: '',
}

// Upload image
export const uploadImage = createAsyncThunk(
  'images/upload',
  async (file, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken

      const formData = new FormData()
      formData.append('image', file) // Assuming the backend expects the image to be sent as 'image' field

      return await imageService.uploadImage(formData, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

export const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    imageReset: state => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(uploadImage.pending, state => {
        state.imageLoading = true
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.imageLoading = false
        state.imageSuccess = true
        state.image = action.payload
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.imageLoading = false
        state.imageError = true
        state.message = action.payload
      })
  },
})

export const { imageReset, addNewImageToState } = imageSlice.actions
export default imageSlice.reducer
