import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg';
import Navigation from '../layout/Navigation';
import Topbar from '../layout/Topbar';
import Spinner from '../components/Spinner';
import { FaTags, FaPenAlt } from 'react-icons/fa';
import { HiSave, HiXCircle } from 'react-icons/hi';
import { BiSolidPlusCircle } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import {
  getStoryArc,
  deleteStoryArc,
  getStoryArcs,
  updateStoryArc,
} from '../features/storyArcs/storyArcSlice';
import {
  getTags,
  createTag,
  addNewTagToState,
} from '../features/tags/tagSlice';
import { addedBy, updatedBy } from '../features/users/userSlice';

// import { id } from 'date-fns/esm/locale'
import ImageUploader from '../components/ImageUploader';
import Breadcrumbs from '../layout/Breadcrumbs';

const StoryArcsDetail = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [tagIds, setTagIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [issues, setIssues] = useState(['']);

  const updateIssues = (index, value) => {
    const newIssues = [...issues];
    newIssues[index] = value;
    setIssues(newIssues);
  };

  const addIssueConnection = () => {
    setIssues([...issues, '']);

    // Adjust the scroll position after adding an issue
    const yOffset = 0; // Adjust this value based on your layout
    const element = document.getElementById('issueConnectionSection'); // Set the ID of the section where you want to scroll

    if (element) {
      // Using scrollIntoView
      // element.scrollIntoView({ behavior: 'smooth', block: 'start' });

      // Or using scrollTo
      window.scrollTo({ top: element.offsetTop - yOffset, behavior: 'smooth' });
    }
  };

  const removeIssue = (index) => {
    const newIssues = [...issues];
    newIssues.splice(index, 1); // Odstranění řádku s daným indexem
    setIssues(newIssues);
  };

  const onChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch user to Redux by URL params
  const { storyArcId } = params;
  const { storyArc, storyArcLoading, storyArcError, message } = useSelector(
    (state) => state.storyArcs
  );
  const { tags } = useSelector((state) => state.tags);

  useEffect(() => {
    if (storyArcError) {
      toast.error(message);
    }

    dispatch(getStoryArc(storyArcId));
    // eslint-disable-next-line
  }, [storyArcError, message, storyArcId]);

  const selectRef = useRef(null); // Create a ref for CreatableSelect

  useEffect(() => {
    // Focus the input of CreatableSelect when the component mounts
    if (selectRef.current) {
      selectRef.current.focus();
    }
  }, []);

  useEffect(() => {
    dispatch(getTags());
    // eslint-disable-next-line
  }, []);

  // Set default form data from Story Arcs reducer
  useEffect(() => {
    setName(storyArc.name && storyArc.name);
    setTagIds(storyArc.tags?.map((tag) => ({ id: tag.id, name: tag.name })));
    setDescription(!storyArc.description ? '' : storyArc.description);
    if (storyArc.issues) {
      setIssues(
        storyArc.issues.map((issue) => ({ value: issue.id, label: issue.name }))
      );
    }
    dispatch(addedBy(storyArc.addedBy));
    dispatch(updatedBy(storyArc.lastUpdatedBy));
  }, [dispatch, storyArc]);

  // CREATE NEW TAG AND ASSIGNE IT TO PUBLISHING
  const handleCreateTag = async (inputValue) => {
    try {
      const { payload: createdTag } = await dispatch(
        createTag({ name: inputValue })
      );
      if (createdTag && createdTag.id) {
        dispatch(addNewTagToState(createdTag));
        setTagIds((prevTagIds) => [
          ...prevTagIds,
          { id: createdTag.id, name: createdTag.name },
        ]);
      } else {
        console.error('Failed to create Tag or no Tag ID returned.');
      }
    } catch (error) {
      console.error('Error creating Tag:', error);
    }
  };

  // Update storyArc
  const onSubmit = (e) => {
    e.preventDefault();

    const storyArcData = {
      name,
      description,
      tagIds: tagIds?.map(({ id }) => id),
    };

    dispatch(updateStoryArc({ storyArcId, storyArcData }));

    if (storyArcError) {
      toast.error(message);
    } else {
      toast.success('Story Arc successfully updated!');
    }
  };

  // Delete Story Arc
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteStoryArc(storyArcId));
      navigate('/storyArcs');
      dispatch(getStoryArcs());
    }
  };

  const creator = useSelector((state) => state.users.addedBy);
  const updator = useSelector((state) => state.users.updatedBy);

  const breadcrumbsPath = ['story-arcs', `${storyArc.name}`];

  if (storyArcLoading || !creator || !updator) {
    return <Spinner />;
  }
  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <Breadcrumbs path={breadcrumbsPath} />
          <form onSubmit={onSubmit}>
            <div className='detail-form grid-2 container-2'>
              <div className='detail-form__heading'>
                <div className='heading-form'>
                  <FaPenAlt className='detail-form__icon' /> {storyArc.name}{' '}
                </div>
                <div className='entity-info bold'>
                  <p className='entity-info__id'>ID: {storyArc.id}</p>
                  <div className='entity-info__slug mar-left-xs'>
                    {' '}
                    {storyArc.slug}
                  </div>
                </div>
              </div>

              <div className='detail-form__user-info'>
                <p className='pad-bottom-xs mar-left-s'>
                  Created:{' '}
                  <span className='bold'>
                    {new Date(storyArc.createdAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${creator.name} ${creator.surname}`}</span>
                </p>
                <p className='mar-left-s'>
                  Updated:{' '}
                  <span className='bold'>
                    {new Date(storyArc.updatedAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${updator.name} ${updator.surname}`}</span>
                </p>
              </div>

              <div className='detail-form__button-box'>
                <button type='submit' className='btn-new btn-save mar-right-xs'>
                  <HiSave className='button-icon-new' />
                </button>
                <button
                  type='button'
                  className='btn-new btn-delete'
                  onClick={deleteHandler}
                >
                  <MdDelete className='button-icon-new' />
                </button>
              </div>

              <ImageUploader />

              <div className='detail-form__main-story-arc container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  Basic Info
                </label>
                <div className='input-box'>
                  <FaPenAlt className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='name' className='input__label'>
                      Name of Story Arc
                    </label>
                    <input
                      type='text'
                      className='input'
                      id='name'
                      placeholder='Name of Story Arc'
                      autoComplete='off'
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className='required-mark-box'>
                    <span className='required-mark'>&#10044;</span>
                  </div>
                </div>

                <div className='input-box-1'>
                  <FaTags className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='tags' className='input__label'>
                      Tags
                    </label>
                    <CreatableSelect
                      className='react-select-container'
                      classNamePrefix='react-select'
                      placeholder='Add tags'
                      isMulti
                      options={tags}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      value={tagIds}
                      onCreateOption={handleCreateTag} // Handle tag creation
                      onChange={(e) => setTagIds(e)}
                      isValidNewOption={(inputValue) => inputValue}
                      getNewOptionData={(inputValue) => ({
                        value: inputValue,
                        name: `Create ${inputValue}`,
                      })}
                      menuPortalTarget={document.body} // Set menuPortalTarget to document.body
                      ref={selectRef} // Attach the ref to CreatableSelect
                    />
                  </div>
                </div>
              </div>

              <div className='detail-form__description-3 container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  description
                </label>
                <EditorProvider>
                  <Editor
                    className='rsw-editor'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
              </div>

              <div
                className='detail-form__connect-box container'
                id='issueConnectionSection'
              >
                <label htmlFor='basicInfo' className='entity-label'>
                  connected issues
                </label>
                {!issues?.length ? (
                  <p className='input-label-info'>
                    {' '}
                    No issues added to this entity yet.
                  </p>
                ) : (
                  <div className='flex-row-box'>
                    {issues.map((issue, index) => (
                      <div className='flex-row' key={index}>
                        <div className='select-cont-flex'>
                          <div className='connect-num'>{index + 1}</div>

                          <Select
                            className='single-select-number-disabled'
                            classNamePrefix='single-select-number-disabled'
                            placeholder='Connected Issue'
                            autoComplete='off'
                            id={`issue${index}`}
                            value={issue}
                            isDisabled
                            onChange={(e) =>
                              updateIssues(index, e.target.value)
                            }
                          />
                        </div>

                        <div className='delete-btn-box mar-left-xs'>
                          <HiXCircle
                            className='btn-icon-2'
                            onClick={() => removeIssue(index)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  type='button'
                  className='add-issue-btn add-issue-btn-blue'
                  onClick={addIssueConnection}
                >
                  <BiSolidPlusCircle className='add-issue-btn-icon' />
                  add issue
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default StoryArcsDetail;
