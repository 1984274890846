import Navigation from '../layout/Navigation';
import Topbar from '../layout/Topbar';
import Spinner from '../components/Spinner';
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaTags } from 'react-icons/fa';
import { HiSave, HiOfficeBuilding } from 'react-icons/hi';
import { MdDelete } from 'react-icons/md';
import { SiBookstack } from 'react-icons/si';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  getSerie,
  deleteSerie,
  getSeries,
  updateSerie,
} from '../features/series/serieSlice';
import {
  createVolume,
  deleteVolume,
  updateVolume,
} from '../features/volumes/volumeSlice'
import { getTags, createTag, addNewTagToState } from '../features/tags/tagSlice'
import { addedBy, updatedBy } from '../features/users/userSlice'

import { MdDriveFileRenameOutline } from 'react-icons/md';
import ImageUploader from '../components/ImageUploader';
import VolumeBlock from '../components/VolumeBlock';
import Breadcrumbs from '../layout/Breadcrumbs';

const SeriesDetail = () => {
  const [name, setName] = useState('');
  const [tagIds, setTagIds] = useState([]);
  const [description, setDescription] = useState('');
  const [volumes, setVolumes] = useState([]);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch Series to Redux by URL params
  const { serieId } = params;
  const { serie, serieLoading, serieError, message } = useSelector(
    (state) => state.series
  );
  const { tags } = useSelector((state) => state.tags);

  useEffect(() => {
    if (serieError) {
      toast.error(message);
    }

    dispatch(getSerie(serieId));
    // eslint-disable-next-line
  }, [serieError, message, serieId]);

  useEffect(() => {
    dispatch(getTags());
    // eslint-disable-next-line
  }, []);

  // Set default form data from series reducer
  useEffect(() => {
    setName(serie.name);
    setTagIds(serie.tags?.map((tag) => ({ id: tag.id, name: tag.name })));
    setDescription(!serie.description ? '' : serie.description);
    setVolumes(!serie.volumes ? [] : serie.volumes);
    dispatch(addedBy(serie.addedBy));
    dispatch(updatedBy(serie.lastUpdatedBy));
  }, [dispatch, serie]);

  const addNewVolume = () => {
    const existingVolumes = volumes.filter((volume) => !volume.isDeleted);
    let maxVolumeNumber = 0;

    existingVolumes.forEach((volume) => {
      const number = parseInt(volume.name.match(/\d+/), 10);
      if (!isNaN(number) && number > maxVolumeNumber) {
        maxVolumeNumber = number;
      }
    });

    const newVolumeNumber = maxVolumeNumber + 1;

    const newVolume = {
      name: `${name} Vol ${newVolumeNumber}`,
      // ... (other properties for the new volume)
    };

    // Create connection to the previous volume if it exists
    if (existingVolumes.length > 0) {
      newVolume.previousVolumeId =
        existingVolumes[existingVolumes.length - 1].id;
    } else {
      newVolume.seriesId = serieId;
    }

    const createName = newVolume.name;

    const updatedVolumes = [...volumes, newVolume];
    setVolumes(updatedVolumes);

    dispatch(
      createVolume({
        name: createName,
        seriesId: serieId,
        previousVolumeId: newVolume.previousVolumeId,
      })
    );
  };

  const onDeleteVolume = (volumeId) => {
    const confirmed = window.confirm('Are you sure?');

    if (confirmed) {
      // Proceed with the deletion
      // Update the list of volumes and trigger a re-render
      const updatedVolumes = volumes.filter((volume) => volume.id !== volumeId);
      setVolumes(updatedVolumes);

      // Dispatch the action to delete the volume
      dispatch(deleteVolume(volumeId));
    }
  };

  // DRAG AND DROP ///////////////////////////////////////////////////////////

  const handleDragEnd = async (result) => {
    const { destination, source } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    const startIndex = source.index;
    const endIndex = destination.index;

    const updatedVolumes = Array.from(volumes);
    const [removed] = updatedVolumes.splice(startIndex, 1);
    updatedVolumes.splice(endIndex, 0, removed);

    setVolumes(updatedVolumes);

    try {
      for (let i = 0; i < updatedVolumes.length; i++) {
        const volume = updatedVolumes[i];
        let payload = {};

        if (i === 0) {
          payload = { seriesId: serieId, previousVolumeId: null };
        } else if (i === updatedVolumes.length - 1) {
          payload = { seriesId: serieId };
        } else {
          payload = {
            seriesId: serieId,
            previousVolumeId: updatedVolumes[i - 1].id,
          };
        }

        if (volume.id !== removed.id) {
          await dispatch(
            updateVolume({ volumeId: volume.id, volumeData: payload })
          );
        }
      }
    } catch (error) {
      console.error('Error updating volumes:', error);
      setVolumes(volumes); // Revert to the original state if update fails
    }
  };

  // END OF DRAG AND DROP ////////////////////////////////////////////////////

  // CREATE NEW TAG AND ASSIGNE IT TO PUBLISHING
  const handleCreateTag = async (inputValue) => {
    try {
      const { payload: createdTag } = await dispatch(
        createTag({ name: inputValue })
      );
      if (createdTag && createdTag.id) {
        dispatch(addNewTagToState(createdTag));
        setTagIds((prevTagIds) => [
          ...prevTagIds,
          { id: createdTag.id, name: createdTag.name },
        ]);
      } else {
        console.error('Failed to create Tag or no Tag ID returned.');
      }
    } catch (error) {
      console.error('Error creating Tag:', error);
    }
  };

  // Update series
  const onSubmit = (e) => {
    e.preventDefault();

    const serieData = {
      name,
      tagIds: tagIds.map(({ id }) => id),
      description,
    };

    dispatch(updateSerie({ serieId, serieData }));

    if (serieError) {
      toast.error(message);
    } else {
      toast.success('Series successfully updated!');
    }
  };

  // Delete series
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteSerie(serieId));
      navigate('/series');
      dispatch(getSeries());
    }
  };

  const creator = useSelector((state) => state.users.addedBy);
  const updator = useSelector((state) => state.users.updatedBy);

  const breadcrumbsPath = ['series', `${serie.name}`];

  if (serieLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <Breadcrumbs path={breadcrumbsPath} />
          <form onSubmit={onSubmit}>
            <div className='detail-form grid-4'>
              <div className='detail-form__heading'>
                <div className='heading-form'>
                  <SiBookstack className='detail-form__icon' /> {serie.name}
                </div>
                <div className='entity-info bold'>
                  <p className='entity-info__id'>ID: {serie.id}</p>
                  <div className='entity-info__slug mar-left-xs'>
                    {' '}
                    {serie.slug}
                  </div>
                </div>
              </div>

              <div className='detail-form__user-info'>
                <p className='pad-bottom-xs mar-left-s'>
                  Created:{' '}
                  <span className='bold'>
                    {new Date(serie.createdAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${creator.name} ${creator.surname}`}</span>
                </p>
                <p className='mar-left-s'>
                  Updated:{' '}
                  <span className='bold'>
                    {new Date(serie.updatedAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${updator.name} ${updator.surname}`}</span>
                </p>
              </div>

              <div className='detail-form__button-box'>
                <button type='submit' className='btn-new btn-save mar-right-xs'>
                  <HiSave className='button-icon-new' />
                </button>
                <button
                  type='button'
                  className='btn-new btn-delete'
                  onClick={deleteHandler}
                >
                  <MdDelete className='button-icon-new' />
                </button>
              </div>

              <ImageUploader />

              <div className='detail-form__main-new container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  Basic Info
                </label>
                <div className='input-box'>
                  <SiBookstack className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='name' className='input__label'>
                      Series Name
                    </label>
                    <input
                      type='text'
                      className='input'
                      id='name'
                      placeholder='Organization Name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete='off'
                      required
                    />
                  </div>
                  <div className='required-mark-box'>
                    <span className='required-mark'>&#10044;</span>
                  </div>
                </div>

                <div className='input-box'>
                  <HiOfficeBuilding className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='publishing' className='input__label'>
                      Publishing
                    </label>
                    <Select
                      className='react-single-select'
                      classNamePrefix='react-single-select'
                      placeholder='Add Publishing'
                      // options={publishings}
                      // value={publishingId}
                      // getOptionLabel={({ name }) => name}
                      // getOptionValue={({ id }) => id}
                      // onChange={setPublishingId}
                      // menuPortalTarget={document.body} // Set menuPortalTarget to document.body
                      // ref={selectRef} // Attach the ref to CreatableSelect
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <FaTags className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='tags' className='input__label'>
                      Tags
                    </label>
                    <CreatableSelect
                      className='react-select-container'
                      classNamePrefix='react-select'
                      placeholder='Add tags'
                      isMulti
                      options={tags}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      value={tagIds}
                      onChange={(e) => setTagIds(e)}
                      onCreateOption={handleCreateTag}
                      isValidNewOption={(inputValue) => inputValue}
                      getNewOptionData={(inputValue) => ({
                        value: inputValue,
                        name: `Create ${inputValue}`,
                      })}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </div>

                {/* <div className='detail-form__description container'> */}

                <div className='editor-box'>
                  <EditorProvider>
                    <Editor
                      className='rsw-editor'
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    >
                      <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                      </Toolbar>
                    </Editor>
                  </EditorProvider>
                </div>
              </div>
            </div>

            <div>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId='volumes'>
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {volumes.map((volume, index) => (
                        <Draggable
                          key={volume.id}
                          draggableId={volume.id}
                          index={index}
                        >
                          {(provided) => (
                            <VolumeBlock
                              key={volume.id}
                              volume={volume}
                              onDeleteVolume={onDeleteVolume}
                              provided={provided}
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className='add-volume-block'>
                <button
                  type='button'
                  className='add-volume-btn'
                  onClick={addNewVolume}
                >
                  +
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SeriesDetail;
