import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import VolumeIssueRow from '../components/VolumeIssueRow';
import { BsCheckCircle } from 'react-icons/bs';
import {
  BiSlider,
  BiSolidTrash,
  BiSolidPlusCircle,
  BiSolidLayerPlus,
  BiSolidXCircle,
} from 'react-icons/bi';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { getVolume, updateVolume } from '../features/volumes/volumeSlice';
import {
  createIssue,
  deleteIssue,
  updateIssue,
} from '../features/issues/issueSlice';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

const VolumeBlock = ({ volume, onDeleteVolume, provided }) => {
  const dispatch = useDispatch();
  const [volumeIssues, setVolumeIssues] = useState([]);
  const [volumeName, setVolumeName] = useState(volume.name);
  const [originalName, setOriginalName] = useState(volume.name);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [newIssueName, setNewIssueName] = useState('');
  const [isAreaMinimized, setIsAreaMinimized] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [periodicityMonths, setPeriodicityMonths] = useState('');
  const [usePeriodicity, setUsePeriodicity] = useState(false);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
  const [updatedDate, setUpdatedDate] = useState('');
  const [updatedPeriodicity, setUpdatedPeriodicity] = useState('');
  const [updatedIssues, setUpdatedIssues] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    // Logic to select/unselect all switches based on selectAll state
    const updatedSelectedIssues = selectAll
      ? []
      : volumeIssues.map((issue) => issue.id);
    setSelectedIssues(updatedSelectedIssues);
  };

  const volumeId = volume.id;
  const { volumeError, message } = useSelector((state) => state.volumes);
  const { serie } = useSelector((state) => state.series);

  const toggleArea = () => {
    setIsAreaMinimized(!isAreaMinimized); // Toggle the state to minimize or expand the area
  };

  // OPEN/CLOSE CREATE ISSUES MODAL
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // OPEN/CLOSE UPDATE ISSUES MODAL
  const openUpdateModal = () => setUpdateModalIsOpen(true);
  const closeUpdateModal = () => {
    setUpdateModalIsOpen(false);
  };

  useEffect(() => {
    const fetchVolumeIssues = async () => {
      try {
        const response = await dispatch(getVolume(volume.id));
        // Assuming your getVolume action returns the issues
        if (response.payload && response.payload.issues) {
          setVolumeIssues(response.payload.issues);
        }
      } catch (error) {
        // Handle error, e.g., display an error message
        console.error('Error fetching issues for the volume:', error);
      }
    };

    fetchVolumeIssues();
  }, [dispatch, volume.id]);

  useEffect(() => {
    // Check if the issueName is different from the original name
    setIsButtonVisible(volumeName !== originalName);
  }, [volumeName, originalName]);

  const addNewIssue = () => {
    const newIssueName = `${volumeName} #${volumeIssues.length + 1}`;

    const newIssue = {
      name: newIssueName,
      releaseDate: new Date(),
      volumeId,
    };
    dispatch(createIssue(newIssue));
    setVolumeIssues([...volumeIssues, newIssue]);
    setNewIssueName('');
  };

  // AUTOMATICALLY GENERATE ISSUES
  const generateMultipleIssues = async () => {
    const numberOfIssues = parseInt(newIssueName, 10);

    if (!isNaN(numberOfIssues) && numberOfIssues > 0) {
      if (
        (!startDate && periodicityMonths) ||
        (startDate && !periodicityMonths)
      ) {
        toast.warning(
          'Please select both the date and periodicity in months or leave both empty to use the current date.'
        );
        return;
      }

      for (let i = 0; i < numberOfIssues; i++) {
        const issueName = `${volumeName} #${volumeIssues.length + i + 1}`;
        const newIssue = {
          name: issueName,
          volumeId,
        };

        if (startDate && periodicityMonths) {
          const start = new Date(startDate);
          const releaseDate = new Date(start);
          releaseDate.setMonth(
            releaseDate.getMonth() + i * parseInt(periodicityMonths, 10)
          );
          newIssue.releaseDate = releaseDate;
        } else {
          newIssue.releaseDate = new Date();
        }

        try {
          await dispatch(createIssue(newIssue));
          setVolumeIssues((prevIssues) => [...prevIssues, newIssue]);
        } catch (error) {
          console.error('Error creating issue:', error);
          // Handle error if needed
        }
      }

      setNewIssueName('');
      closeModal();
      toast.success(`${numberOfIssues} issues were successfully created!`);
    } else {
      alert('Please enter a valid number of issues.');
    }
  };

  // AUTOMATICALLY UPDATE DATES
  const updateSelectedIssues = async () => {
    try {
      const updatedIssuesPromises = selectedIssues.map(
        async (issueId, index) => {
          const issueToUpdate = volumeIssues.find(
            (issue) => issue.id === issueId
          );

          if (issueToUpdate) {
            const updatedReleaseDate = new Date(updatedDate);
            updatedReleaseDate.setMonth(
              updatedReleaseDate.getMonth() + index * updatedPeriodicity
            );

            const updatedIssueData = {
              releaseDate: updatedReleaseDate.toISOString(),
              // Other fields you need to update...
            };

            try {
              const response = await dispatch(
                updateIssue({ issueId, issueData: updatedIssueData })
              );

              if (
                response.payload &&
                response.payload.id === issueId &&
                response.payload.releaseDate
              ) {
                return response.payload;
              }
            } catch (error) {
              console.error(
                `Failed to update issue with ID ${issueId}.`,
                error
              );
              return issueToUpdate; // Return the original issue if update fails
            }
          }

          return issueToUpdate;
        }
      );

      const updatedIssues = await Promise.all(updatedIssuesPromises);

      // Update all volume issues with the updated ones
      const updatedVolumeIssues = volumeIssues.map((volumeIssue) => {
        const updatedIssue = updatedIssues.find(
          (updated) => updated && updated.id === volumeIssue.id
        );
        return updatedIssue || volumeIssue; // Keep original if not updated
      });

      setVolumeIssues(updatedVolumeIssues);
      setUpdatedIssues(updatedIssues);

      closeUpdateModal();
      toast.success('Selected issues were successfully updated!');
    } catch (error) {
      console.error('Error updating issues:', error);
      toast.error('Failed to update selected issues.');
    }
  };

  // ISSUE SELECTION
  const handleIssueSelect = (issueId, isSelected) => {
    if (isSelected) {
      setSelectedIssues([...selectedIssues, issueId]);
    } else {
      const updatedSelectedIssues = selectedIssues.filter(
        (selectedIssueId) => selectedIssueId !== issueId
      );
      setSelectedIssues(updatedSelectedIssues);
    }
  };

  // FUNCTION FOR DELETE OF SELECTED ITEMS
  const deleteSelectedIssues = async () => {
    // Confirm before deleting
    const confirmation = window.confirm(
      'Are you sure you want to delete the selected issues?'
    );

    if (confirmation) {
      for (const issueId of selectedIssues) {
        try {
          // Dispatch the delete action
          dispatch(deleteIssue(issueId));

          // Update the UI immediately after dispatching the delete action
          setVolumeIssues((prevIssues) =>
            prevIssues.filter((issue) => issue.id !== issueId)
          );
          setSelectedIssues((prevSelected) =>
            prevSelected.filter((selectedId) => selectedId !== issueId)
          );
        } catch (error) {
          console.error(`Error deleting issue with ID ${issueId}:`, error);
          // Handle error if needed
        }
      }

      // Show toast message after successful deletion
      toast.success('Selected issues were successfully deleted!');
    }
  };

  const onVolumeSave = async (e) => {
    e.preventDefault();

    const volumeData = {
      name: volumeName,
    };

    dispatch(updateVolume({ volumeId, volumeData }));

    if (volumeError) {
      toast.error(message);
    } else {
      toast.success('Volume successfully updated!');
      setIsButtonVisible(false);
      setOriginalName(volumeName);
    }
  };

  return (
    <div ref={provided.innerRef} {...provided.draggableProps}>
      <div className='volume__box item-nonscale'>
        <div
          className={
            isAreaMinimized
              ? 'volume__item__header volume__item__header-closed'
              : 'volume__item__header volume__item__header-open'
          }
          {...provided.dragHandleProps}
        >
          <input
            type='checkbox'
            checked={selectAll}
            onChange={toggleSelectAll}
            className='entity-check'
            id={`input-issues-${volume.id}`}
          />
          <label
            htmlFor={`input-issues-${volume.id}`}
            className='mar-right-m mar-left-xs'
          ></label>
          <div className='volume-box-box mar-right-xl'>
            <input
              className='volume__title'
              placeholder='Volume name'
              value={volumeName}
              onChange={(e) => {
                setVolumeName(e.target.value);
                setIsButtonVisible(e.target.value !== originalName);
              }}
            />
            <button
              className={`btn-confirm btn-confirm-1 ${
                isButtonVisible ? '' : 'btn-invisible'
              }`}
              type='button'
              onClick={onVolumeSave}
              disabled={!isButtonVisible}
            >
              <BsCheckCircle />
            </button>
          </div>

          <div className='volume__issues-label mar-left-l'>
            Total issues: {volumeIssues.length}
          </div>
          <div className='volume-button-area'>
            <button
              type='button'
              onClick={toggleArea}
              className='volume__minimize-btn mar-right-xs'
            >
              {isAreaMinimized ? (
                <FiMaximize2
                  className='volume__minimize'
                  title='Maximize volume'
                />
              ) : (
                <FiMinimize2
                  className='volume__minimize'
                  title='Minimize volume'
                />
              )}
            </button>
            <button
              onClick={() => onDeleteVolume(volumeId)}
              className='volume__delete-btn'
              title='Delete volume'
            >
              <BiSolidTrash className='volume__delete' />
            </button>
          </div>
        </div>
        {!isAreaMinimized && (
          <div className='volume__item__area'>
            {volumeIssues.map((issue) => (
              <VolumeIssueRow
                key={issue.id}
                issue={issue}
                serie={serie}
                onIssueSelect={handleIssueSelect}
                updatedIssues={updatedIssues}
                selectedIssues={selectedIssues}
              />
            ))}
            <div className='add-volume-footer'>
              <button
                type='button'
                className='add-issue-btn add-issue-btn-blue'
                onClick={addNewIssue}
              >
                <BiSolidPlusCircle className='add-issue-btn-icon' /> add issue
              </button>
              <button
                type='button'
                className='add-issue-btn add-issue-btn-green'
                onClick={openModal}
              >
                <BiSolidLayerPlus className='add-issue-btn-icon' /> issue
                generator
              </button>
              <button
                type='button'
                className='add-issue-btn add-issue-btn-purple'
                disabled={selectedIssues.length === 0}
                onClick={openUpdateModal}
              >
                <BiSlider className='add-issue-btn-icon' /> mass editing
              </button>
              <button
                type='button'
                className='add-issue-btn add-issue-btn-red'
                disabled={selectedIssues.length === 0}
                onClick={deleteSelectedIssues}
              >
                <BiSolidTrash className='add-issue-btn-icon' /> delete selected
              </button>
            </div>
          </div>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className='bulk-edit-modal bulk-edit-modal-create'
          contentLabel='Bulk Edit'
          overlayClassName='bulk-edit-modal-overlay'
        >
          <div className='bulk-edit-create-issues'>
            <div className='bulk-edit-heading'>
              <p>Issue Generator</p>
              <BiSolidXCircle className='bulk-edit-icon' onClick={closeModal} />
            </div>
            <div className='bulk-edit-input-column'>
              <label for='issues' className='bulk-edit-input-label'>
                Count of issues to generate
              </label>
              <input
                id='issues'
                type='number'
                min='0'
                className='bulk-edit-input'
                placeholder='0'
                value={newIssueName}
                onChange={(e) => setNewIssueName(e.target.value)}
              />
            </div>

            <div className='bulk-edit-date-row'>
              <div className='bulk-edit-date-column'>
                <label for='date' className='bulk-edit-input-label'>
                  Generate release dates from
                </label>
                <input
                  id='date'
                  type='date'
                  className='bulk-edit-input'
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className='bulk-edit-date-column'>
                <label for='per' className='bulk-edit-input-label'>
                  Periodicity in months
                </label>
                <input
                  id='per'
                  type='number'
                  min='1'
                  className='bulk-edit-input'
                  placeholder='0'
                  value={usePeriodicity ? periodicityMonths : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    if (value >= 1 && value <= 12) {
                      setPeriodicityMonths(value);
                      setUsePeriodicity(true);
                    } else {
                      setPeriodicityMonths(0);
                      setUsePeriodicity(false);
                    }
                  }}
                />
              </div>
            </div>

            <button
              type='button'
              onClick={generateMultipleIssues}
              className='bulk-edit-btn'
            >
              Generate
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={updateModalIsOpen}
          onRequestClose={closeUpdateModal}
          className='bulk-edit-modal bulk-edit-modal-update'
          contentLabel='Bulk Edit'
          overlayClassName='bulk-edit-modal-overlay'
        >
          <div className='bulk-edit-create-issues'>
            <div className='bulk-edit-heading'>
              <p>Mass-editing of selected issues</p>
              <BiSolidXCircle
                className='bulk-edit-icon'
                onClick={closeUpdateModal}
              />
            </div>

            <div className='bulk-edit-date-row'>
              <div className='bulk-edit-date-column'>
                <label
                  htmlFor='updateReleaseDate'
                  className='bulk-edit-input-label'
                >
                  Update release dates from
                </label>
                <input
                  id='updateReleaseDate'
                  type='date'
                  className='bulk-edit-input'
                  value={updatedDate} // Use state variable here
                  onChange={(e) => setUpdatedDate(e.target.value)} // Update state on change
                />
              </div>
              <div className='bulk-edit-date-column'>
                <label
                  htmlFor='updatePeriodicity'
                  className='bulk-edit-input-label'
                >
                  Periodicity in months
                </label>
                <input
                  id='updatePeriodicity'
                  type='number'
                  min='1'
                  className='bulk-edit-input'
                  placeholder='0'
                  value={updatedPeriodicity} // Use state variable here
                  onChange={(e) => setUpdatedPeriodicity(e.target.value)} // Update state on change
                />
              </div>
            </div>

            <button
              type='button'
              onClick={updateSelectedIssues}
              className='bulk-edit-btn'
            >
              Update
            </button>
          </div>
        </Modal>
        ;
      </div>
    </div>
  );
};

export default VolumeBlock;
