import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTrashAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  getBooks,
  deleteBook,
  updateBook,
} from '../../features/books/bookSlice'

const BooksTableRow = ({ book, isChecked, onCheckboxChange }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const booksState = useSelector(state => state.books)
  const currentBook = booksState.books.find(s => s.id === book.id)

  const publishing = useSelector(state => {
    const publishingId = book.publishingId
    return state.publishings.publishings.find(
      publishing => publishing.id === publishingId
    )
  })

  // MANUAL CONTROL FUNCTION
  const updateManualControl = isChecked => {
    if (isChecked !== currentBook?.checked) {
      dispatch(
        updateBook({
          bookId: book.id,
          bookData: { checked: isChecked },
        })
      ).then(() => {
        dispatch(getBooks())
        toast.success('Books successfully updated')
      })
    }
  }

  // Delete book
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteBook(book.id))
      navigate('/books')
      dispatch(getBooks())
    }
  }
  return (
    <>
      <div className='entity-table__row books-table-row'>
        <input
          type='checkbox'
          className='entity-check'
          id={book.id}
          checked={isChecked}
          onChange={() => onCheckboxChange(book.id)}
        />
        <label htmlFor={book.id}></label>
        <Link to={`/books/${book.id}`} className='entity-table__row__link'>
          {`${book.name}`}
        </Link>
        <div>{publishing ? publishing.name : ''}</div>
        <div className='asc'>
          {currentBook?.checked ? 'Finished' : 'Unfinished'}
        </div>
        <label className='switch asc'>
          <input
            type='checkbox'
            checked={currentBook?.checked || false}
            onChange={e => {
              const newState = e.target.checked
              updateManualControl(newState)
              onCheckboxChange(book.id, newState)
            }}
          />
          <span className='slider'></span>
        </label>
        <div>{new Date(book.updatedAt).toLocaleString('en-GB')}</div>
        <Popup
          trigger={
            <button className='user-nav__user-photo'>
              <BsThreeDotsVertical className='entity-table__settings-icon' />
            </button>
          }
          position='bottom right'
        >
          <button
            className='btn-inner btn-inner__table'
            onClick={deleteHandler}
          >
            <FaTrashAlt /> &nbsp; Delete
          </button>
        </Popup>
      </div>
    </>
  )
}
export default BooksTableRow
