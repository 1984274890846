import { FaCloudUploadAlt } from 'react-icons/fa';
import { useState } from 'react';
import { HiXCircle } from 'react-icons/hi';

const ImageUploader = ({ onImageUpload }) => {
  const [show, setShow] = useState(true);
  const [file, setFile] = useState();

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(URL.createObjectURL(selectedFile));
    setShow(false);
    onImageUpload(selectedFile); // Pass the selected file to the callback
  };

  const handleDelete = () => {
    setShow(true);
    setFile();
  };

  return (
    <>
      <div className='img-upload container'>
        <label htmlFor='basicInfo' className='entity-label'>
          Image
        </label>
        {show ? (
          <>
            <input
              className='img-uploaded'
              id='upload-img'
              type='file'
              onChange={handleChange}
              accept='image/*,.png,.jpeg'
            />

            <label
              className={
                handleChange === setFile
                  ? 'upload-label__hiden'
                  : 'upload-label'
              }
              htmlFor='upload-img'
            >
              <div className='img-upload__box'>
                <FaCloudUploadAlt className='img-upload__icon' />
                <p className='img-upload__text'>Upload Picture</p>
                <p>Pictures must be less than 2 MB in size.</p>
              </div>
            </label>
          </>
        ) : (
          <>
            <img src={file} alt='Main preview' className='img-entity' />
            <HiXCircle className='btn-img-del' onClick={handleDelete} />
          </>
        )}
      </div>
    </>
  );
};

export default ImageUploader;
