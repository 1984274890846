import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTrashAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  getOrganizations,
  deleteOrganization,
  updateOrganization,
} from '../../features/organizations/organizationSlice'

const OrganizationsTableRow = ({
  organization,
  isChecked,
  onCheckboxChange,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const organizationsState = useSelector(state => state.organizations)
  const currentOrganization = organizationsState.organizations.find(
    s => s.id === organization.id
  )

  // Delete organzation
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteOrganization(organization.id))
      navigate('/organizations')
      dispatch(getOrganizations())
    }
  }

  // MANUAL CONTROL FUNCTION
  const updateManualControl = isChecked => {
    if (isChecked !== currentOrganization?.checked) {
      dispatch(
        updateOrganization({
          organizationId: organization.id,
          organizationData: { checked: isChecked },
        })
      ).then(() => {
        dispatch(getOrganizations())
        toast.success('Organizations successfully updated')
      })
    }
  }

  return (
    <>
      <div className='entity-table__row organizations-table-row'>
        <input
          type='checkbox'
          className='entity-check'
          id={organization.id}
          checked={isChecked}
          onChange={() => onCheckboxChange(organization.id)}
        />
        <label htmlFor={organization.id}></label>
        <Link
          to={`/organizations/${organization.id}`}
          className='entity-table__row__link'
        >
          {`${organization.name}`}
        </Link>
        <div>{organization.publishing.name}</div>
        <div>{organization.earths[0].name}</div>
        <div className='asc'>86</div>
        <div className='asc'>
          {currentOrganization?.checked ? 'Finished' : 'Unfinished'}
        </div>
        <label className='switch asc'>
          <input
            type='checkbox'
            checked={currentOrganization?.checked || false}
            onChange={e => {
              const newState = e.target.checked
              updateManualControl(newState)
              onCheckboxChange(organization.id, newState)
            }}
          />
          <span className='slider'></span>
        </label>
        <div>{new Date(organization.updatedAt).toLocaleString('en-GB')}</div>
        <Popup
          trigger={
            <button className='user-nav__user-photo'>
              <BsThreeDotsVertical className='entity-table__settings-icon' />
            </button>
          }
          position='bottom right'
        >
          <button
            className='btn-inner btn-inner__table'
            onClick={deleteHandler}
          >
            <FaTrashAlt /> &nbsp; Delete
          </button>
        </Popup>
      </div>
    </>
  )
}

export default OrganizationsTableRow
