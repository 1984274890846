import axios from 'axios'

const backendURL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${backendURL}/api/v1/characters/`

// Create author
const createCharacter = async (character, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.post(API_URL, character, config)
  return response.data
}

// Get all characters
const getCharacters = async token => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.get(API_URL, config)
  return response.data
}

// Get character
const getCharacter = async (characterId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + characterId, config)
  return response.data
}

// Update character
const updateCharacter = async (characterId, characterData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.put(API_URL + characterId, characterData, config)

  return response.data
}

// Delete character
const deleteCharacter = async (characterId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + characterId, config)
  return response.data
}

const characterService = {
  createCharacter,
  getCharacters,
  getCharacter,
  updateCharacter,
  deleteCharacter,
}

export default characterService
