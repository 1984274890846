import Navigation from '../layout/Navigation'
import Topbar from '../layout/Topbar'
import Spinner from '../components/Spinner'
import Select from 'react-select'
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg'
import { FaTags, FaCalendarAlt, FaInfoCircle } from 'react-icons/fa'
import { HiSave, HiOfficeBuilding, HiXCircle } from 'react-icons/hi'
import { RiCloseCircleFill } from 'react-icons/ri'
import { MdDelete } from 'react-icons/md'
import { toast } from 'react-toastify'
import CreatableSelect from 'react-select/creatable'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import {
  getPublishing,
  deletePublishing,
  getPublishings,
  updatePublishing,
} from '../features/publishings/publishingSlice'
import { getTags, createTag, addNewTagToState } from '../features/tags/tagSlice'
import {
  getEarths,
  createEarth,
  addNewEarthToState,
} from '../features/earths/earthSlice'
import {
  getAuthorRoles,
  createAuthorRole,
  addNewAuthorRoleToState,
} from '../features/authorRoles/authorRoleSlice'
import { addedBy, updatedBy } from '../features/users/userSlice'

import { MdDriveFileRenameOutline } from 'react-icons/md'
import { ImEarth } from 'react-icons/im'
import { GiPlanetConquest } from 'react-icons/gi'
import ImageUploader from '../components/ImageUploader'
import { uploadImage } from '../features/images/imageSlice'
import Breadcrumbs from '../layout/Breadcrumbs'
import { format } from 'date-fns'

const PublishingsDetail = () => {
  const [name, setName] = useState('')
  const [headquarters, setHeadquarters] = useState('')
  const [foundedDate, setFoundedDate] = useState()
  const [tagIds, setTagIds] = useState([])
  const [earthIds, setEarthIds] = useState('')
  const [description, setDescription] = useState('')
  const [defaultEarth, setDefaultEarth] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [authorRoles, setAuthorRoles] = useState([])
  const [allAuthorRolesOptions, setAllAuthorRolesOptions] = useState([])
  const [authorRoleValue, setAuthorRoleValue] = useState(null)
  const [imageId, setImageId] = useState()
  const [imageURL, setImageURL] = useState('')

  const onChange = e => {
    setSearchTerm(e.target.value)
  }

  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Fetch publisher to Redux by URL params
  const { publishingId } = params
  const { publishing, publishingLoading, publishingError, message } =
    useSelector(state => state.publishings)
  const { tags } = useSelector(state => state.tags)
  const { earths } = useSelector(state => state.earths)
  const { earths: publishingEarths } = useSelector(
    state => state.publishings.publishing
  )
  const { authorRoles: publishingAuthorRoles } = publishing
  const { authorRoles: allAuthorRoles } = useSelector(
    state => state.authorRoles
  )

  // CHECKBOX SELECT - DISABLE / AVAILABLE STATUS
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  useEffect(() => {
    if (publishingError) {
      toast.error(message)
    }

    dispatch(getPublishing(publishingId))
    // eslint-disable-next-line
  }, [publishingError, message, publishingId])

  const selectRef = useRef(null) // Create a ref for CreatableSelect

  useEffect(() => {
    // Focus the input of CreatableSelect when the component mounts
    if (selectRef.current) {
      selectRef.current.focus()
    }
  }, [])

  useEffect(() => {
    dispatch(getTags())
    dispatch(getEarths())
    dispatch(getAuthorRoles())
    // eslint-disable-next-line
  }, [])

  // Set default form data from publishings reducer
  useEffect(() => {
    setName(publishing.name && publishing.name)
    setHeadquarters(!publishing.headquarters ? '' : publishing.headquarters)
    setFoundedDate(
      publishing.foundedDate
        ? format(new Date(publishing.foundedDate), 'yyyy-MM-dd')
        : undefined
    )
    setDescription(!publishing.description ? '' : publishing.description)
    setEarthIds(
      publishing.earths?.map(earth => ({ id: earth.id, name: earth.name }))
    )
    setDefaultEarth(!publishing.defaultEarth ? '' : publishing.defaultEarth)
    setAuthorRoles(!publishing.authorRoles ? [] : publishing.authorRoles)
    setImageURL(!publishing.imageUrl ? '' : publishing.imageUrl)
    dispatch(addedBy(publishing.addedBy))
    dispatch(updatedBy(publishing.lastUpdatedBy))
  }, [dispatch, publishing])

  // EXISTING AUTHOR ROLES LOGIC
  const handleAddRole = selectedOption => {
    if (selectedOption) {
      // If a new author role is selected and not already in the assigned roles, add it
      if (!authorRoles.find(role => role.id === selectedOption.value)) {
        const updatedRoles = [
          ...authorRoles,
          {
            id: selectedOption.value,
            name: selectedOption.label,
          },
        ]
        // Update the state with the new roles
        setAuthorRoles(updatedRoles)

        // Update the authorRoleValue state with the selected author role
        setAuthorRoleValue(selectedOption)
      }
    }
  }

  const handleRemoveRole = roleId => {
    const updatedRoles = authorRoles.filter(role => role.id !== roleId)
    setAuthorRoles(updatedRoles)
  }

  const generateAuthorRolesOptions = () => {
    const unusedRoles = allAuthorRoles
      .filter(role => !authorRoles.some(ar => ar.id === role.id))
      .map(role => ({ value: role.id, label: role.name }))
    setAllAuthorRolesOptions(unusedRoles)
  }

  useEffect(() => {
    dispatch(getAuthorRoles())
    generateAuthorRolesOptions()
    // eslint-disable-next-line
  }, [authorRoles])

  // CREATE NEW AUTHOR ROLE AND ASSIGN IT TO PUBLISHING
  const handleCreateAuthorRole = async inputValue => {
    try {
      const { payload: createdAuthorRole } = await dispatch(
        createAuthorRole({ name: inputValue })
      )
      if (createdAuthorRole && createdAuthorRole.id) {
        dispatch(addNewAuthorRoleToState(createdAuthorRole))
        setAuthorRoles(prevAuthorRoles => [
          ...prevAuthorRoles,
          { id: createdAuthorRole.id, name: createdAuthorRole.name },
        ])

        // Update the value state of CreatableSelect with the newly created author role
        setAuthorRoleValue({
          value: createdAuthorRole.id,
          label: createdAuthorRole.name,
        })
      } else {
        console.error('Failed to create Author Role or no ID returned.')
      }
    } catch (error) {
      console.error('Error creating Author Role:', error)
    }
  }

  // CREATE NEW EARTH AND ASSIGNE IT TO PUBLISHING
  const handleCreateEarth = async inputValue => {
    try {
      const { payload: createdEarth } = await dispatch(
        createEarth({ name: inputValue })
      )
      if (createdEarth && createdEarth.id) {
        dispatch(addNewEarthToState(createdEarth))
        setEarthIds(prevEarthIds => [
          ...prevEarthIds,
          { id: createdEarth.id, name: createdEarth.name },
        ])
      } else {
        console.error('Failed to create Earth or no Earth ID returned.')
      }
    } catch (error) {
      console.error('Error creating Earth:', error)
    }
  }

  // CREATE NEW TAG AND ASSIGNE IT TO PUBLISHING
  const handleCreateTag = async inputValue => {
    try {
      const { payload: createdTag } = await dispatch(
        createTag({ name: inputValue })
      )
      if (createdTag && createdTag.id) {
        dispatch(addNewTagToState(createdTag))
        setTagIds(prevTagIds => [
          ...prevTagIds,
          { id: createdTag.id, name: createdTag.name },
        ])
      } else {
        console.error('Failed to create Tag or no Tag ID returned.')
      }
    } catch (error) {
      console.error('Error creating Tag:', error)
    }
  }

  // UPLOAD IMAGE
  const handleImageUpload = async file => {
    try {
      const response = await dispatch(uploadImage(file))
      const uploadedImage = response.payload[0]

      if (uploadedImage) {
        setImageId(uploadedImage.id)
        toast.success('Image uploaded successfully!')
      } else {
        console.error('Failed to create image or no ID returned.')
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const handleImageChange = () => {
    setImageURL('')
  }

  // Update publishing
  const onSubmit = e => {
    e.preventDefault()

    const publishingData = {
      name,
      headquarters,
      foundedDate,
      description,
      earthIds: earthIds.map(({ id }) => id),
      tagIds: tagIds.map(({ id }) => id),
      defaultEarthId: defaultEarth.id,
      authorRoleIds: authorRoles.map(role => role.id), // Include the updated author roles
      imageId,
    }

    dispatch(updatePublishing({ publishingId, publishingData }))
    setAuthorRoleValue(null)

    // Rest of your existing code for toast notifications or error handling...
  }

  // Delete publishing
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deletePublishing(publishingId))
      navigate('/publishings')
      dispatch(getPublishings())
    }
  }

  const creator = useSelector(state => state.users.addedBy)
  const updator = useSelector(state => state.users.updatedBy)

  const breadcrumbsPath = ['publishings', `${publishing.name}`]

  if (publishingLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <Breadcrumbs path={breadcrumbsPath} />
          <form onSubmit={onSubmit}>
            <div className='detail-form grid-1'>
              <div className='detail-form__heading'>
                <div className='heading-form'>
                  <HiOfficeBuilding className='detail-form__icon' />{' '}
                  {publishing.name}{' '}
                </div>
                <div className='entity-info bold'>
                  <p className='entity-info__id'>ID: {publishing.id}</p>
                  <div className='entity-info__slug mar-left-xs'>
                    {publishing.slug}
                  </div>
                </div>
              </div>

              <div className='detail-form__user-info'>
                <p className='pad-bottom-xs mar-left-s'>
                  Created:{' '}
                  <span className='bold'>
                    {new Date(publishing.createdAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${creator.name} ${creator.surname}`}</span>
                </p>
                <p className='mar-left-s'>
                  Updated:{' '}
                  <span className='bold'>
                    {new Date(publishing.updatedAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${updator.name} ${updator.surname}`}</span>
                </p>
              </div>

              <div className='detail-form__button-box'>
                <button type='submit' className='btn-new btn-save mar-right-xs'>
                  <HiSave className='button-icon-new' />
                </button>
                <button
                  type='button'
                  className='btn-new btn-delete'
                  onClick={deleteHandler}
                >
                  <MdDelete className='button-icon-new' />
                </button>
              </div>

              {!imageURL ? (
                <ImageUploader onImageUpload={handleImageUpload} />
              ) : (
                <>
                  <div className='image-upload container'>
                    <label htmlFor='basicInfo' className='entity-label'>
                      Image
                    </label>
                    <img
                      src={imageURL}
                      alt='Main preview'
                      className='img-entity'
                    />
                    <HiXCircle
                      className='btn-img-del'
                      onClick={handleImageChange}
                    />
                  </div>
                </>
              )}

              <div className='detail-form__main container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  Basic Info
                </label>
                <div className='input-box'>
                  <MdDriveFileRenameOutline className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='name' className='input__label'>
                      Publishing Name
                    </label>
                    <input
                      type='text'
                      className='input'
                      id='name'
                      placeholder='Publishing Name'
                      value={name}
                      onChange={e => setName(e.target.value)}
                      autoComplete='off'
                      required
                    />
                  </div>
                  <div className='required-mark-box'>
                    <span className='required-mark'>&#10044;</span>
                  </div>
                </div>

                <div className='input-box'>
                  <GiPlanetConquest className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='earth' className='input__label'>
                      Default Earth
                    </label>
                    <Select
                      className='react-single-select'
                      classNamePrefix='react-single-select'
                      placeholder='Add Default Earth'
                      value={defaultEarth}
                      options={publishingEarths}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      onChange={e => setDefaultEarth(e)}
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <HiOfficeBuilding className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='headquarters' className='input__label'>
                      Headquarters
                    </label>
                    <input
                      type='text'
                      className='input'
                      id='headquarters'
                      value={headquarters}
                      onChange={e => setHeadquarters(e.target.value)}
                      placeholder='Add Headquarters'
                      autoComplete='off'
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <FaCalendarAlt className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='date' className='input__label'>
                      Founded Date
                    </label>
                    <input
                      type='date'
                      className='input'
                      id='date'
                      placeholder='Date'
                      autoComplete='off'
                      value={foundedDate}
                      onChange={e => setFoundedDate(e.target.value)}
                    />
                  </div>
                </div>

                <div className='input-box-1'>
                  <ImEarth className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='tags' className='input__label'>
                      Earths
                    </label>
                    <CreatableSelect
                      className='react-select-container'
                      classNamePrefix='react-select'
                      placeholder='Add Earths'
                      isMulti
                      options={earths}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      value={earthIds}
                      onChange={e => setEarthIds(e)}
                      onCreateOption={handleCreateEarth}
                      isValidNewOption={inputValue => inputValue}
                      getNewOptionData={inputValue => ({
                        value: inputValue,
                        name: `Create ${inputValue}`,
                      })}
                      menuPortalTarget={document.body}
                      ref={selectRef}
                    />
                  </div>
                </div>

                <div className='input-box-1'>
                  <FaTags className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='tags' className='input__label'>
                      Tags
                    </label>
                    <CreatableSelect
                      className='react-select-container'
                      classNamePrefix='react-select'
                      placeholder='Add Tags'
                      isMulti
                      options={tags}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      value={tagIds}
                      onChange={e => setTagIds(e)}
                      onCreateOption={handleCreateTag}
                      isValidNewOption={inputValue => inputValue}
                      getNewOptionData={inputValue => ({
                        value: inputValue,
                        name: `Create ${inputValue}`,
                      })}
                      menuPortalTarget={document.body}
                      ref={selectRef}
                    />
                  </div>
                </div>

                <div
                  className={isChecked ? 'input-box' : 'input-box-unchecked'}
                >
                  <input
                    type='checkbox'
                    className='entity-check'
                    id='ch-imprint'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor='ch-imprint'></label>
                  <div className='input-area-checkbox'>
                    <label
                      htmlFor='imprint'
                      className={
                        isChecked ? 'input__label' : 'input__label-disabled'
                      }
                    >
                      Imprint
                    </label>
                    <Select
                      id='imprint'
                      className='react-single-select'
                      classNamePrefix='react-single-select'
                      placeholder='Add parent publishing'
                      isDisabled={!isChecked}
                      // value={defaultEarth}
                      // options={publishingEarths}
                      // getOptionLabel={({ name }) => name}
                      // getOptionValue={({ id }) => id}
                      // onChange={(e) => setDefaultEarth(e)}
                    />
                  </div>
                </div>
              </div>

              <div className='detail-form__author-roles container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  Author Team
                </label>
                <label className='input-label'>
                  {!publishingAuthorRoles?.length ? `` : `Selected Roles:`}
                </label>
                {publishingAuthorRoles?.map(role => (
                  <div className='select-cont-flex' key={role.id}>
                    <Select
                      className='single-select-disabled'
                      classNamePrefix='single-select-disabled'
                      value={
                        allAuthorRoles.find(
                          authorRole => authorRole.name === role.name
                        )
                          ? { value: role.name, label: role.name }
                          : null
                      }
                      options={[{ value: role.name, label: role.name }]}
                      isDisabled
                    />
                    <button
                      className='select-del-btn'
                      onClick={() => handleRemoveRole(role.id)}
                    >
                      <RiCloseCircleFill className='icon-btn' />
                    </button>
                  </div>
                ))}
                <div>
                  <div className='select-cont-flex'>
                    <label className='input-label-info'>
                      <FaInfoCircle className='info-icon' />
                      <p>
                        Click on all roles you want to add and save the form:
                      </p>
                    </label>
                    <CreatableSelect
                      className='single-select-disabled'
                      classNamePrefix='single-select-disabled'
                      value={authorRoleValue}
                      options={allAuthorRolesOptions}
                      onChange={selectedOption => handleAddRole(selectedOption)}
                      onCreateOption={handleCreateAuthorRole}
                    />
                  </div>
                </div>
              </div>

              <div className='detail-form__description-2 container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  description
                </label>
                <EditorProvider>
                  <Editor
                    className='rsw-editor'
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default PublishingsDetail
