import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTrashAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  getStoryArcs,
  deleteStoryArc,
  updateStoryArc,
} from '../../features/storyArcs/storyArcSlice'

const StoryArcsTableRow = ({ storyArc, isChecked, onCheckboxChange }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const storyArcsState = useSelector(state => state.storyArcs)
  const currentStoryArc = storyArcsState.storyArcs.find(
    s => s.id === storyArc.id
  )

  // MANUAL CONTROL FUNCTION
  const updateManualControl = isChecked => {
    if (isChecked !== currentStoryArc?.checked) {
      dispatch(
        updateStoryArc({
          storyArcId: storyArc.id,
          storyArcData: { checked: isChecked },
        })
      ).then(() => {
        dispatch(getStoryArcs())
        toast.success('Story Arc successfully updated')
      })
    }
  }

  // Delete storyArc
  const deleteHandler = async () => {
    if (window.confirm('Are you sure?')) {
      await dispatch(deleteStoryArc(storyArc.id))
      await navigate('/story-arcs')
      await dispatch(getStoryArcs())
    }
  }
  return (
    <>
      <div className='entity-table__row storyArcs-table-row'>
        <input
          type='checkbox'
          className='entity-check'
          id={storyArc.id}
          checked={isChecked}
          onChange={() => onCheckboxChange(storyArc.id)}
        />
        <label htmlFor={storyArc.id}></label>
        <Link
          to={`/story-arcs/${storyArc.id}`}
          className='entity-table__row__link'
        >
          {`${storyArc.name}`}
        </Link>
        <div>Marvel Comics, Atlas Comics</div>
        <div className='asc'>
          {currentStoryArc?.checked ? 'Finished' : 'Unfinished'}
        </div>
        <label className='switch asc'>
          <input
            type='checkbox'
            checked={currentStoryArc?.checked || false}
            onChange={e => {
              const newState = e.target.checked
              updateManualControl(newState)
              onCheckboxChange(storyArc.id, newState)
            }}
          />
          <span className='slider'></span>
        </label>
        <div>{new Date(storyArc.updatedAt).toLocaleString('en-GB')}</div>
        <Popup
          trigger={
            <button className='user-nav__user-photo'>
              <BsThreeDotsVertical className='entity-table__settings-icon' />
            </button>
          }
          position='bottom right'
        >
          <button
            className='btn-inner btn-inner__table'
            onClick={deleteHandler}
          >
            <FaTrashAlt /> &nbsp; Delete
          </button>
        </Popup>
      </div>
    </>
  )
}
export default StoryArcsTableRow
