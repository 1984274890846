import logo from '../utils/img/CH_logo_orange.png'
import Menu from '../components/Menu/Menu'

const Navigation = () => {
  return (
    <>
      <div className='side-bar'>
        <div className='logo-box'>
          <img src={logo} alt='Logo' className='logo-box__logo' />
          <p className='logo-box__text'>comicshub</p>
        </div>
        <Menu />
      </div>
    </>
  )
}

export default Navigation
