import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg'
import Navigation from '../layout/Navigation'
import Topbar from '../layout/Topbar'
import Spinner from '../components/Spinner'
import { FaTags } from 'react-icons/fa'
import { HiXCircle } from 'react-icons/hi'
import { SiUnity } from 'react-icons/si'
import { BiSolidPlusCircle, BiSolidTrash } from 'react-icons/bi'
import { IoIosSave } from 'react-icons/io'
import { toast } from 'react-toastify'
import CreatableSelect from 'react-select/creatable'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import Select from 'react-select'
import {
  getEvent,
  deleteEvent,
  getEvents,
  updateEvent,
} from '../features/events/eventSlice'
import { getTags, createTag, addNewTagToState } from '../features/tags/tagSlice'
import { addedBy, updatedBy } from '../features/users/userSlice'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

// import { id } from 'date-fns/esm/locale'
import ImageUploader from '../components/ImageUploader'
import { uploadImage } from '../features/images/imageSlice'

import Breadcrumbs from '../layout/Breadcrumbs'

const EventsDetail = () => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [tagIds, setTagIds] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [imageId, setImageId] = useState()
  const [imageURL, setImageURL] = useState('')

  const [books, setBooks] = useState([''])
  const [issues, setIssues] = useState([''])

  const updateIssues = (index, value) => {
    const newIssues = [...issues]
    newIssues[index] = value
    setIssues(newIssues)
  }
  const addIssueInput = () => {
    setIssues([...issues, ''])
  }
  const removeIssue = index => {
    const newIssues = [...issues]
    newIssues.splice(index, 1) // Odstranění řádku s daným indexem
    setIssues(newIssues)
  }

  const updateBooks = (index, value) => {
    const newBooks = [...books]
    newBooks[index] = value
    setBooks(newBooks)
  }
  const addBookConnection = () => {
    setBooks([...books, ''])
  }
  const removeBook = index => {
    const newBooks = [...books]
    newBooks.splice(index, 1) // Odstranění řádku s daným indexem
    setBooks(newBooks)
  }

  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onChange = e => {
    setSearchTerm(e.target.value)
  }

  // Fetch user to Redux by URL params
  const { eventId } = params
  const { event, eventLoading, eventError, message } = useSelector(
    state => state.events
  )
  const { tags } = useSelector(state => state.tags)

  useEffect(() => {
    if (eventError) {
      toast.error(message)
    }

    dispatch(getEvent(eventId))
    // eslint-disable-next-line
  }, [eventError, message, eventId])

  const selectRef = useRef(null) // Create a ref for CreatableSelect

  useEffect(() => {
    // Focus the input of CreatableSelect when the component mounts
    if (selectRef.current) {
      selectRef.current.focus()
    }
  }, [])

  useEffect(() => {
    dispatch(getTags())
    // eslint-disable-next-line
  }, [])

  // Set default form data from events reducer
  useEffect(() => {
    setName(event.name && event.name)
    setTagIds(event.tags?.map(tag => ({ id: tag.id, name: tag.name })))
    setDescription(!event.description ? '' : event.description)
    setImageURL(!event.imageUrl ? '' : event.imageUrl)
    dispatch(addedBy(event.addedBy))
    dispatch(updatedBy(event.lastUpdatedBy))
  }, [dispatch, event])

  // CREATE NEW TAG AND ASSIGNE IT TO PUBLISHING
  const handleCreateTag = async inputValue => {
    try {
      const { payload: createdTag } = await dispatch(
        createTag({ name: inputValue })
      )
      if (createdTag && createdTag.id) {
        dispatch(addNewTagToState(createdTag))
        setTagIds(prevTagIds => [
          ...prevTagIds,
          { id: createdTag.id, name: createdTag.name },
        ])
      } else {
        console.error('Failed to create Tag or no Tag ID returned.')
      }
    } catch (error) {
      console.error('Error creating Tag:', error)
    }
  }

  // UPLOAD IMAGE
  const handleImageUpload = async file => {
    try {
      const response = await dispatch(uploadImage(file))
      const uploadedImage = response.payload[0]

      if (uploadedImage) {
        setImageId(uploadedImage.id)
        toast.success('Image uploaded successfully!')
      } else {
        console.error('Failed to create image or no ID returned.')
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const handleImageChange = () => {
    setImageURL('')
  }

  // Update event
  const onSubmit = e => {
    e.preventDefault()

    const eventData = {
      name,
      description,
      tagIds: tagIds.map(({ id }) => id),
      imageId,
    }

    dispatch(updateEvent({ eventId, eventData }))

    if (eventError) {
      toast.error(message)
    } else {
      toast.success('Event successfully updated!')
    }
  }

  // Delete event
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteEvent(eventId))
      navigate('/events')
      dispatch(getEvents())
    }
  }

  const creator = useSelector(state => state.users.addedBy)
  const updator = useSelector(state => state.users.updatedBy)

  const breadcrumbsPath = ['events', `${event.name}`]

  if (eventLoading || !creator || !updator) {
    return <Spinner />
  }
  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <Breadcrumbs path={breadcrumbsPath} />
          <form onSubmit={onSubmit}>
            <div className='detail-form grid-2 container-2'>
              <div className='detail-form__heading'>
                <div className='heading-form'>
                  <SiUnity className='detail-form__icon' /> {event.name}{' '}
                </div>
                <div className='entity-info bold'>
                  <p className='entity-info__id bold'>ID: {event.id}</p>
                  <div className='entity-info__slug bold mar-left-xs'>
                    {' '}
                    {event.slug}
                  </div>
                </div>
              </div>

              <div className='detail-form__user-info'>
                <p className='pad-bottom-xs mar-left-s'>
                  Created:{' '}
                  <span className='bold'>
                    {new Date(event.createdAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${creator.name} ${creator.surname}`}</span>
                </p>
                <p className='mar-left-s'>
                  Updated:{' '}
                  <span className='bold'>
                    {new Date(event.updatedAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${updator.name} ${updator.surname}`}</span>
                </p>
              </div>

              <div className='detail-form__button-box'>
                <button type='submit' className='btn-new btn-save mar-right-xs'>
                  <IoIosSave className='button-icon-new' />
                </button>
                <button
                  type='button'
                  className='btn-new btn-delete'
                  onClick={deleteHandler}
                >
                  <BiSolidTrash className='button-icon-new' />
                </button>
              </div>

              {!imageURL ? (
                <ImageUploader onImageUpload={handleImageUpload} />
              ) : (
                <>
                  <div className='image-upload container'>
                    <label htmlFor='basicInfo' className='entity-label'>
                      Image
                    </label>
                    <img
                      src={imageURL}
                      alt='Main preview'
                      className='img-entity'
                    />
                    <HiXCircle
                      className='btn-img-del'
                      onClick={handleImageChange}
                    />
                  </div>
                </>
              )}

              <div className='detail-form__main-new container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  Basic Info
                </label>
                <div className='input-box'>
                  <SiUnity className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='name' className='input__label'>
                      Name of Event
                    </label>
                    <input
                      type='text'
                      className='input'
                      id='name'
                      placeholder='Name of Event'
                      autoComplete='off'
                      required
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>
                  <div className='required-mark-box'>
                    <span className='required-mark'>&#10044;</span>
                  </div>
                </div>
                <div className='input-box-1'>
                  <FaTags className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='tags' className='input__label'>
                      Tags
                    </label>
                    <CreatableSelect
                      className='react-select-container'
                      classNamePrefix='react-select'
                      placeholder='Add tags'
                      isMulti
                      options={tags}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      value={tagIds}
                      onCreateOption={handleCreateTag} // Handle tag creation
                      onChange={e => setTagIds(e)}
                      isValidNewOption={inputValue => inputValue}
                      getNewOptionData={inputValue => ({
                        value: inputValue,
                        name: `Create ${inputValue}`,
                      })}
                      menuPortalTarget={document.body} // Set menuPortalTarget to document.body
                      ref={selectRef} // Attach the ref to CreatableSelect
                    />
                  </div>
                </div>

                <div className='editor-box'>
                  <EditorProvider>
                    <Editor
                      className='rsw-editor'
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                    >
                      <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                      </Toolbar>
                    </Editor>
                  </EditorProvider>
                </div>
              </div>

              <div className='detail-form__connect-box container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  connected issues
                </label>
                {!issues?.length ? (
                  <p className='input-label-info'>
                    {' '}
                    No issues added to this entity yet.
                  </p>
                ) : (
                  <DragDropContext className='flex-row-box'>
                    <Droppable droppableId='issues'>
                      {provided => (
                        <div
                          className='flex-row-box'
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {issues.map((issue, index) => (
                            <Draggable
                              key={index}
                              draggableId={`issue-${index}`}
                              index={index}
                            >
                              {provided => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    className='flex-row'
                                    key={index}
                                    // style={{ marginBottom: '20px' }}
                                  >
                                    <div className='select-cont-flex'>
                                      <div className='connect-num'>
                                        {index + 1}
                                      </div>

                                      <Select
                                        className='single-select-number-disabled'
                                        classNamePrefix='single-select-number-disabled'
                                        id={`issue${index}`}
                                        placeholder='Connected Issue'
                                        autoComplete='off'
                                        value={issue}
                                        isDisabled
                                        onChange={e =>
                                          updateIssues(index, e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className='delete-btn-box mar-left-xs'>
                                      <HiXCircle
                                        className='btn-icon-2'
                                        onClick={() => removeIssue(index)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
                <button
                  type='button'
                  className='add-issue-btn add-issue-btn-blue'
                  onClick={addIssueInput}
                >
                  <BiSolidPlusCircle className='add-issue-btn-icon' />
                  add issue
                </button>
              </div>

              <div className='detail-form__connect-box container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  connected books
                </label>
                {!books?.length ? (
                  <p className='input-label-info'>
                    {' '}
                    No books added to this entity yet.
                  </p>
                ) : (
                  <div className='flex-row-box'>
                    {books.map((book, index) => (
                      <div className='flex-row' key={index}>
                        <div className='select-cont-flex'>
                          <div className='connect-num'>{index + 1}</div>

                          <Select
                            className='single-select-number-disabled'
                            classNamePrefix='single-select-number-disabled'
                            placeholder='Connected Book'
                            autoComplete='off'
                            id={`book${index}`}
                            value={book}
                            isDisabled
                            onChange={e => updateBooks(index, e.target.value)}
                          />
                        </div>

                        <div className='delete-btn-box mar-left-xs'>
                          <HiXCircle
                            className='btn-icon-2'
                            onClick={() => removeBook(index)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  type='button'
                  className='add-issue-btn add-issue-btn-blue'
                  onClick={addBookConnection}
                >
                  <BiSolidPlusCircle className='add-issue-btn-icon' />
                  add book
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default EventsDetail
