import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTrashAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  getEvents,
  deleteEvent,
  updateEvent,
} from '../../features/events/eventSlice'

const EventsTableRow = ({ event, isChecked, onCheckboxChange }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const eventsState = useSelector(state => state.events)
  const currentEvent = eventsState.events.find(s => s.id === event.id)

  // MANUAL CONTROL FUNCTION
  const updateManualControl = isChecked => {
    if (isChecked !== currentEvent?.checked) {
      dispatch(
        updateEvent({
          eventId: event.id,
          eventData: { checked: isChecked },
        })
      ).then(() => {
        dispatch(getEvents())
        toast.success('Events successfully updated')
      })
    }
  }

  // Delete Event
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteEvent(event.id))
      navigate('/events')
      dispatch(getEvents())
    }
  }
  return (
    <>
      <div className='entity-table__row events-table-row'>
        <input
          type='checkbox'
          className='entity-check'
          id={event.id}
          checked={isChecked}
          onChange={() => onCheckboxChange(event.id)}
        />
        <label htmlFor={event.id}></label>
        <Link to={`/events/${event.id}`} className='entity-table__row__link'>
          {`${event.name}`}
        </Link>
        <div>Marvel Comics, Atlas Comics</div>
        <div className='asc'>
          {currentEvent?.checked ? 'Finished' : 'Unfinished'}
        </div>
        <label className='switch asc'>
          <input
            type='checkbox'
            checked={currentEvent?.checked || false}
            onChange={e => {
              const newState = e.target.checked
              updateManualControl(newState)
              onCheckboxChange(event.id, newState)
            }}
          />
          <span className='slider'></span>
        </label>
        <div>{new Date(event.updatedAt).toLocaleString('en-GB')}</div>
        <Popup
          trigger={
            <button className='user-nav__user-photo'>
              <BsThreeDotsVertical className='entity-table__settings-icon' />
            </button>
          }
          position='bottom right'
        >
          <button
            className='btn-inner btn-inner__table'
            onClick={deleteHandler}
          >
            <FaTrashAlt /> &nbsp; Delete
          </button>
        </Popup>
      </div>
    </>
  )
}
export default EventsTableRow
