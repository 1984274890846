import { BsDiscord, BsSlack } from 'react-icons/bs'

const DashTeamEvents = () => {
  return (
    <>
      <div className='item team-events'>
        <h3 className='heading-tertiary-dark'>Upcoming Team Events</h3>
        <div className='team-events__container'>
          <div className='team-events__item'>
            <BsDiscord className='team-events__icon' />
            <div className='team-events__item__type'>Peridocial Video Call</div>
            <div className='team-events__item__date'>Today, 9:00 pm</div>
          </div>

          <div className='team-events__item'>
            <BsSlack className='team-events__icon' />
            <div className='team-events__item__type'>Management Meeting</div>
            <div className='team-events__item__date'>April 23, 2:00 pm</div>
          </div>

          <div className='team-events__item'>
            <BsDiscord className='team-events__icon' />
            <div className='team-events__item__type'>Whole Team Call</div>
            <div className='team-events__item__date'>April 24, 9:00 pm</div>
          </div>

          <div className='team-events__item'>
            <BsSlack className='team-events__icon' />
            <div className='team-events__item__type'>Coding Party</div>
            <div className='team-events__item__date'>April 26, 10:00 am</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashTeamEvents
