import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateUser, reset, getUser } from '../features/users/userSlice'
import { HiXCircle } from 'react-icons/hi'
import Spinner from '../components/Spinner'
import Navigation from '../layout/Navigation'
import Topbar from '../layout/Topbar'
import UserAvatar from '../components/UserAvatar'
import { uploadImage } from '../features/images/imageSlice'
import ImageUploader from '../components/ImageUploader'
import { toast } from 'react-toastify'

const MyProfile = () => {
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [imageId, setImageId] = useState()
  const [imageURL, setImageURL] = useState('')

  const dispatch = useDispatch()

  // Fetch active user from auth reducer
  const authId = useSelector(state => state.users.auth.user.id)

  // Load user into user reducer
  useEffect(() => {
    dispatch(getUser(authId))
  }, [dispatch, authId])

  const { user, userLoading, userSuccess, userError, message } = useSelector(
    state => state.users
  )
  const userId = user.id

  // Set default form data from user reducer
  useEffect(() => {
    setName(user.name)
    setSurname(user.surname)
    setEmail(user.email)
    setImageURL(!user.imageUrl ? '' : user.imageUrl)
  }, [dispatch, user])

  // UPLOAD AUTHORS MAIN IMAGE
  const handleImageUpload = async file => {
    try {
      const response = await dispatch(uploadImage(file))
      const uploadedImage = response.payload[0]

      if (uploadedImage) {
        setImageId(uploadedImage.id)
        toast.success('Image uploaded successfully!')
      } else {
        console.error('Failed to create image or no ID returned.')
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const handleImageChange = () => {
    setImageURL('')
  }

  // Update user
  const onSubmit = e => {
    e.preventDefault()

    const userData = {
      name,
      surname,
      oldPassword,
      newPassword,
      imageId,
    }

    dispatch(updateUser({ userId, userData }))

    if (userError) {
      toast.error(message)
    } else {
      toast.success('Profile successfully updated!')
    }
  }

  if (userLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <div className='my-profile__bio'>
            <div className='my-profile__initials'>
              {!imageURL ? (
                <div className='my-profile__initials'>
                  <UserAvatar />
                  <ImageUploader onImageUpload={handleImageUpload} />
                </div>
              ) : (
                <div className='image-upload container'>
                  <label htmlFor='basicInfo' className='entity-label'>
                    Image
                  </label>
                  <img
                    src={imageURL}
                    alt='Main preview'
                    className='img-entity'
                  />
                  <HiXCircle
                    className='btn-img-del'
                    onClick={handleImageChange}
                  />
                </div>
              )}
            </div>
            <div className='my-profile__body'>
              <form className='my-profile__form' onSubmit={onSubmit}>
                <div className='my-profile__heading'>Change Name</div>
                <div className='my-profile__form-group'>
                  <label htmlFor='email'>Email</label>
                  <input
                    type='email'
                    className='my-profile__form-control'
                    placeholder={user.email}
                    disabled
                  />
                </div>
                <div className='my-profile__form-group'>
                  <label htmlFor='name'>Name</label>
                  <input
                    type='text'
                    className='my-profile__form-control'
                    placeholder={user.name}
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </div>
                <div className='my-profile__form-group'>
                  <label htmlFor='name'>Surname</label>
                  <input
                    type='text'
                    className='my-profile__form-control'
                    placeholder={user.surname}
                    value={surname}
                    onChange={e => setSurname(e.target.value)}
                  />
                </div>
                <div className='my-profile__heading'>Change Password</div>
                <div className='my-profile__form-group'>
                  <label htmlFor='oldPassword'>Old Password</label>
                  <input
                    type='password'
                    className='my-profile__form-control'
                    placeholder='Old Password'
                    value={oldPassword}
                    onChange={e => setOldPassword(e.target.value)}
                  />
                </div>
                <div className='my-profile__form-group'>
                  <label htmlFor='newPassword'>New Password</label>
                  <input
                    type='password'
                    className='my-profile__form-control'
                    placeholder='New Password'
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value)}
                  />
                </div>
                <div className='my-profile__form-group'>
                  <button className='my-profile__btn'>Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyProfile
