import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg'
import Navigation from '../layout/Navigation'
import Topbar from '../layout/Topbar'
import Spinner from '../components/Spinner'
import { FaUser, FaCalendarAlt, FaTags, FaPenNib } from 'react-icons/fa'
import { HiSave, HiSearch, HiUsers, HiXCircle } from 'react-icons/hi'
import { RiContactsFill } from 'react-icons/ri'
import { MdDelete } from 'react-icons/md'
import { toast } from 'react-toastify'
import CreatableSelect from 'react-select/creatable'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import {
  getAuthor,
  deleteAuthor,
  getAuthors,
  updateAuthor,
} from '../features/authors/authorSlice'
import { getCountries } from '../features/countries/countrySlice'
import { getTags, createTag, addNewTagToState } from '../features/tags/tagSlice'
import { uploadImage } from '../features/images/imageSlice'
import ImageUploader from '../components/ImageUploader'

import { addedBy, updatedBy } from '../features/users/userSlice'

import { format } from 'date-fns'
import Breadcrumbs from '../layout/Breadcrumbs'

const AuthorsDetail = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [fullName, setFullName] = useState('')
  const [birthday, setBirthday] = useState()
  const [altNames, setAltNames] = useState([])
  const [description, setDescription] = useState('')
  const [nationality, setNationality] = useState([])
  const [countryId, setCountryId] = useState()
  const [tagIds, setTagIds] = useState([])
  const [imageId, setImageId] = useState()
  const [imageURL, setImageURL] = useState('')

  const [searchTerm, setSearchTerm] = useState('')

  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onChange = e => {
    setSearchTerm(e.target.value)
  }

  // Fetch user to Redux by URL params
  const { authorId } = params
  const { author, authorLoading, authorError, message } = useSelector(
    state => state.authors
  )
  const { tags } = useSelector(state => state.tags)
  const { countries } = useSelector(state => state.countries)

  useEffect(() => {
    if (authorError) {
      toast.error(message)
    }

    dispatch(getAuthor(authorId))
    // eslint-disable-next-line
  }, [authorError, message, authorId])

  const selectRef = useRef(null) // Create a ref for CreatableSelect

  useEffect(() => {
    // Focus the input of CreatableSelect when the component mounts
    if (selectRef.current) {
      selectRef.current.focus()
    }
  }, [])

  useEffect(() => {
    const getCountries = async () => {
      await setNationality(countries)
    }
    getCountries()
  }, [countries])

  useEffect(() => {
    dispatch(getTags())
    dispatch(getCountries())
    // eslint-disable-next-line
  }, [])

  // Set default form data from authors reducer
  useEffect(() => {
    setFirstName(author.firstName && author.firstName)
    setLastName(author.lastName && author.lastName)
    setBirthday(
      author.birthday
        ? format(new Date(author.birthday), 'yyyy-MM-dd')
        : undefined
    )
    setFullName(!author.fullName ? '' : author.fullName)
    setAltNames(
      author.altNames?.map(altName => ({ value: altName, label: altName }))
    )
    setCountryId(author.countryId ? author.countryId : undefined)
    setTagIds(author.tags?.map(tag => ({ id: tag.id, name: tag.name })))
    setDescription(!author.description ? '' : author.description)
    setImageURL(!author.imageUrl ? '' : author.imageUrl)
    dispatch(addedBy(author.addedBy))
    dispatch(updatedBy(author.lastUpdatedBy))
  }, [dispatch, author])

  const [sortedNationality, setSortedNationality] = useState([])

  useEffect(() => {
    setSortedNationality([...nationality]) // Initialize sorted nationality with the original list
  }, [nationality])

  useEffect(() => {
    const sorted = sortedNationality.sort((a, b) => {
      if (a.id === countryId) return -1 // Move the checked country to the top
      if (b.id === countryId) return 1
      return 0
    })
    setSortedNationality([...sorted])
  }, [countryId])

  useEffect(() => {
    const sorted = [...nationality]

    // Sort the countries alphabetically
    sorted.sort((a, b) => a.name.localeCompare(b.name))

    // Move the checked country to the top
    const checkedIndex = sorted.findIndex(country => country.id === countryId)
    if (checkedIndex !== -1) {
      const checkedCountry = sorted.splice(checkedIndex, 1)[0]
      sorted.unshift(checkedCountry)
    }

    setSortedNationality(sorted)
  }, [nationality, countryId])

  // UPLOAD AUTHORS MAIN IMAGE
  const handleImageUpload = async file => {
    try {
      const response = await dispatch(uploadImage(file))
      const uploadedImage = response.payload[0]

      if (uploadedImage) {
        setImageId(uploadedImage.id)
        toast.success('Image uploaded successfully!')
      } else {
        console.error('Failed to create image or no ID returned.')
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const handleImageChange = () => {
    setImageURL('')
  }

  // CREATE NEW TAG AND ASSIGNE IT TO PUBLISHING
  const handleCreateTag = async inputValue => {
    try {
      const { payload: createdTag } = await dispatch(
        createTag({ name: inputValue })
      )
      if (createdTag && createdTag.id) {
        dispatch(addNewTagToState(createdTag))
        setTagIds(prevTagIds => [
          ...prevTagIds,
          { id: createdTag.id, name: createdTag.name },
        ])
      } else {
        console.error('Failed to create Tag or no Tag ID returned.')
      }
    } catch (error) {
      console.error('Error creating Tag:', error)
    }
  }

  // Update author
  const onSubmit = e => {
    e.preventDefault()

    const authorData = {
      firstName,
      lastName,
      fullName,
      birthday,
      altNames: altNames.map(({ value }) => value),
      description,
      countryId,
      tagIds: tagIds.map(({ id }) => id),
      imageId,
    }

    dispatch(updateAuthor({ authorId, authorData }))

    if (authorError) {
      toast.error(message)
    } else {
      toast.success('Author successfully updated!')
    }
  }

  // Delete author
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteAuthor(authorId))
      navigate('/authors')
      dispatch(getAuthors())
    }
  }

  const creator = useSelector(state => state.users.addedBy)
  const updator = useSelector(state => state.users.updatedBy)

  const breadcrumbsPath = ['authors', `${author.firstName} ${author.lastName}`]

  if (authorLoading || !creator || !updator) {
    return <Spinner />
  }
  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <Breadcrumbs path={breadcrumbsPath} />
          <form onSubmit={onSubmit}>
            <div className='detail-form grid-2 container-2'>
              <div className='detail-form__heading'>
                <div className='heading-form'>
                  <FaUser className='detail-form__icon' /> {author.firstName}{' '}
                  {author.lastName}{' '}
                </div>
                <div className='entity-info bold'>
                  <p className='entity-info__id'>ID: {author.id}</p>
                  <div className='entity-info__slug mar-left-xs'>
                    {' '}
                    {author.slug}
                  </div>
                </div>
              </div>

              <div className='detail-form__user-info'>
                <p className='pad-bottom-xs mar-left-s'>
                  Created:{' '}
                  <span className='bold'>
                    {new Date(author.createdAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${creator.name} ${creator.surname}`}</span>
                </p>
                <p className='mar-left-s'>
                  Updated:{' '}
                  <span className='bold'>
                    {new Date(author.updatedAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${updator.name} ${updator.surname}`}</span>
                </p>
              </div>

              <div className='detail-form__button-box'>
                <button type='submit' className='btn-new btn-save mar-right-xs'>
                  <HiSave className='button-icon-new' />
                </button>
                <button
                  type='button'
                  className='btn-new btn-delete'
                  onClick={deleteHandler}
                >
                  <MdDelete className='button-icon-new' />
                </button>
              </div>

              {!imageURL ? (
                <ImageUploader onImageUpload={handleImageUpload} />
              ) : (
                <>
                  <div className='image-upload container'>
                    <label htmlFor='basicInfo' className='entity-label'>
                      Image
                    </label>
                    <img
                      src={imageURL}
                      alt='Main preview'
                      className='img-entity'
                    />
                    <HiXCircle
                      className='btn-img-del'
                      onClick={handleImageChange}
                    />
                  </div>
                </>
              )}

              <div id='basicInfo' className='detail-form__main container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  Basic info
                </label>
                <div className='input-box'>
                  <FaUser className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='name' className='input__label'>
                      First name
                    </label>
                    <input
                      type='text'
                      className='input'
                      id='name'
                      placeholder='First name'
                      autoComplete='off'
                      required
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className='required-mark-box'>
                    <span className='required-mark'>&#10044;</span>
                  </div>
                </div>
                <div className='input-box'>
                  <HiUsers className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='lastname' className='input__label'>
                      Last name
                    </label>
                    <input
                      type='text'
                      className='input'
                      id='lastname'
                      placeholder='Last name'
                      autoComplete='off'
                      required
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                    />
                  </div>
                  <div className='required-mark-box'>
                    <span className='required-mark'>&#10044;</span>
                  </div>
                </div>

                <div className='input-box-2'>
                  <FaTags className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='pseudo' className='input__label'>
                      Tags
                    </label>
                    <CreatableSelect
                      className='react-select-xl'
                      classNamePrefix='react-select-xl'
                      placeholder='Add tags'
                      isMulti
                      options={tags}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      value={tagIds}
                      onCreateOption={handleCreateTag} // Handle tag creation
                      onChange={e => setTagIds(e)}
                      isValidNewOption={inputValue => inputValue}
                      getNewOptionData={inputValue => ({
                        value: inputValue,
                        name: `Create ${inputValue}`,
                      })}
                      menuPortalTarget={document.body} // Set menuPortalTarget to document.body
                      ref={selectRef} // Attach the ref to CreatableSelect
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <RiContactsFill className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='fullname' className='input__label'>
                      Full name
                    </label>
                    <input
                      type='text'
                      className='input'
                      id='fullname'
                      name='fullname'
                      placeholder='Full name'
                      autoComplete='off'
                      value={fullName}
                      onChange={e => setFullName(e.target.value)}
                    />
                  </div>
                </div>

                <div className='input-box-1'>
                  <FaPenNib className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='pseudo' className='input__label'>
                      Pseudonyms
                    </label>
                    <CreatableSelect
                      className='react-select-container'
                      classNamePrefix='react-select'
                      placeholder='Add pseudonyms'
                      isMulti
                      value={altNames}
                      onChange={e => setAltNames(e)}
                    />
                  </div>
                </div>
                <div className='input-box'>
                  <FaCalendarAlt className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='date' className='input__label'>
                      Birthdate
                    </label>
                    <input
                      type='date'
                      className='input'
                      id='date'
                      placeholder='Date'
                      autoComplete='off'
                      value={birthday}
                      onChange={e => setBirthday(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div
                id='nationalityBox'
                className='detail-form__nationality container'
              >
                <label htmlFor='nationalityBox' className='entity-label'>
                  Nationality
                </label>
                <div className='detail-form__nationality__searchbar'>
                  <HiSearch className='detail-form__nationality__icon' />
                  <div className='detail-form__nationality__separator'>|</div>
                  <input
                    type='text'
                    className='detail-form__nationality__input'
                    placeholder='Search countries...'
                    onChange={onChange}
                  />
                </div>
                <div className='detail-form__nationality__country-container'>
                  {sortedNationality
                    .filter(country => {
                      if (searchTerm === '') {
                        return country
                      } else if (
                        `${country.name}`
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return country
                      }
                    })
                    .map(country => (
                      <div
                        className={`detail-form__nationality__country-row ${
                          countryId === country.id ? 'checked' : ''
                        }`}
                        key={country.id}
                      >
                        {getUnicodeFlagIcon(`${country.countryCode}`)}
                        <div className='detail-form__nationality__country-row__item '>
                          <input
                            type='radio'
                            className='detail-form__nationality__country-row__item__check entity-check-1'
                            name='countries-radio'
                            id={`countries-radio-${country.id}`}
                            value={country.id}
                            checked={countryId === country.id}
                            onChange={e => setCountryId(e.target.value)}
                          />
                          <label
                            className='checkbox-countries'
                            htmlFor={`countries-radio-${country.id}`}
                          >
                            {country.name}
                          </label>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div
                id='descriptionBox'
                className='detail-form__description-1 container'
              >
                <label htmlFor='descriptionBox' className='entity-label'>
                  Description
                </label>
                <EditorProvider>
                  <Editor
                    className='rsw-editor'
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default AuthorsDetail
