import { Link } from 'react-router-dom'
import Avatar from 'react-avatar'

const UserCart = ({ user }) => {
  return (
    <div className='user__cart'>
      <Avatar name={`${user.name} ${user.surname}`} size='80px' round='30px' />
      <div className='user__cart__name'>
        {`${user.name} ${user.surname}`}
        <span className='user__cart__function'>Not working yet</span>
      </div>
      <div className='user__cart__email'>{user.email}</div>
      <Link to={`/users/${user.id}`} className='user__cart__button'>
        View Profile
      </Link>
    </div>
  )
}

export default UserCart
