import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import publishingService from './publishingService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  publishings: [],
  publishing: {},
  publishingError: false,
  publishingSuccess: false,
  publishingLoading: false,
  message: '',
}

// Create publishing
export const createPublishing = createAsyncThunk(
  'publishings/create',
  async (publishing, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await publishingService.createPublishing(publishing, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get all publishings
export const getPublishings = createAsyncThunk(
  'publishings/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await publishingService.getPublishings(token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get publishing
export const getPublishing = createAsyncThunk(
  'publishings/get',
  async (publishingId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await publishingService.getPublishing(publishingId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Update publishing
export const updatePublishing = createAsyncThunk(
  'publishings/update',
  async (publishing, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await publishingService.updatePublishing(
        publishing.publishingId,
        publishing.publishingData,
        token
      )
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Delete publishing
export const deletePublishing = createAsyncThunk(
  'publishings/delete',
  async (publishingId, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await publishingService.deletePublishing(publishingId, token)
  }
)

export const publishingSlice = createSlice({
  name: 'publishing',
  initialState,
  reducers: {
    reset: state => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(createPublishing.pending, state => {
        state.publishingLoading = true
      })
      .addCase(createPublishing.fulfilled, (state, action) => {
        state.publishingLoading = false
        state.publishingSuccess = true
        state.publishing = action.payload
      })
      .addCase(createPublishing.rejected, (state, action) => {
        state.publishingLoading = false
        state.publishingError = true
        state.message = action.payload
        state.publishing = null
      })
      .addCase(getPublishings.pending, state => {
        state.publishingLoading = true
      })
      .addCase(getPublishings.fulfilled, (state, action) => {
        state.publishingLoading = false
        state.publishingSuccess = true
        state.publishings = action.payload
      })
      .addCase(getPublishings.rejected, (state, action) => {
        state.publishingLoading = false
        state.publishingError = true
        state.message = action.payload
      })
      .addCase(getPublishing.pending, state => {
        state.publishingLoading = true
      })
      .addCase(getPublishing.fulfilled, (state, action) => {
        state.publishingLoading = false
        state.publishingSuccess = true
        state.publishing = action.payload
      })
      .addCase(getPublishing.rejected, (state, action) => {
        state.publishingLoading = false
        state.publishingError = true
        state.message = action.payload
      })
      .addCase(updatePublishing.pending, state => {
        state.publishingLoading = true
      })
      .addCase(updatePublishing.fulfilled, (state, action) => {
        state.publishingLoading = false
        state.publishingSuccess = true
        state.publishing = action.payload
      })
      .addCase(updatePublishing.rejected, (state, action) => {
        state.publishingLoading = false
        state.publishingError = true
        state.message = action.payload
      })
      .addCase(deletePublishing.fulfilled, state => {
        state.publishingSuccess = true
      })
  },
})

export const { reset } = publishingSlice.actions
export default publishingSlice.reducer
