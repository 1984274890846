import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUsers, reset } from '../features/users/userSlice'
import Spinner from '../components/Spinner'
import Avatar from 'react-avatar'

const DashHubmates = () => {
  const { users, isLoading, isSuccess } = useSelector(state => state.users)

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      if (isSuccess) {
        dispatch(reset())
      }
    }
  }, [dispatch, isSuccess])

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='item humbates'>
        <h3 className='heading-3--dark'>Hubmates list</h3>
        <div className='hubmates__container'>
          {users.map(user => (
            <div className='hubmates__status--inactive' key={user.id}>
              <div className='hubmates__photo'>
                {user.imageUrl ? (
                  <img
                    src={user.imageUrl}
                    alt={`${user.name} ${user.surname}`}
                    style={{ borderRadius: '100%' }}
                  />
                ) : (
                  <Avatar
                    name={`${user.name} ${user.surname}`}
                    size='100%'
                    round='100%'
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default DashHubmates
