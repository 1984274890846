import { menuItems } from './menuItems'
import MenuSubItems from './MenuSubItems'

const Menu = () => {
  return (
    <nav>
      <ul className='side-nav'>
        {menuItems.map((menu, index) => {
          return <MenuSubItems items={menu} key={index} />
        })}
      </ul>
    </nav>
  )
}

export default Menu
