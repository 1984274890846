import { useSelector } from 'react-redux'
import Avatar from 'react-avatar'

const UserAvatar = () => {
  const { user } = useSelector(state => state.users.auth)

  return <Avatar name={`${user.name} ${user.surname}`} size='100%' />
}

export default UserAvatar
