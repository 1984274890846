import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getSeries, deleteSerie, reset } from '../features/series/serieSlice'
import {
  HiCheckCircle,
  HiXCircle,
  HiDatabase,
  HiAdjustments,
  HiFilter,
} from 'react-icons/hi'
import { BiSlider, BiSolidTrash } from 'react-icons/bi'
import { FaEdit } from 'react-icons/fa'
import Navigation from '../layout/Navigation'
import Topbar from '../layout/Topbar'
import SeriesTableRow from '../components/entityTables/SeriesTableRow'
import Spinner from '../components/Spinner'
import { toast } from 'react-toastify'

const SeriesTable = () => {
  const { series, serieLoading, serieSuccess } = useSelector(
    state => state.series
  )

  const [searchTerm, setSearchTerm] = useState('')
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [checkedSeries, setCheckedSeries] = useState([])
  const [filterFullfilled, setFilterFullfilled] = useState(false)
  const [filterUnfinished, setFilterUnfinished] = useState(false)
  const [activeFilter, setActiveFilter] = useState('all')

  const dispatch = useDispatch()

  const onChange = e => {
    setSearchTerm(e.target.value)
  }

  const handleCheckboxChange = id => {
    if (checkedSeries.includes(id)) {
      const updatedCheckedSeries = checkedSeries.filter(
        serieId => serieId !== id
      )
      setCheckedSeries(updatedCheckedSeries)
    } else {
      const updatedCheckedSeries = [...checkedSeries, id]
      setCheckedSeries(updatedCheckedSeries)
    }
  }

  // Delete fce for selected series
  const deleteSelectedSeries = async () => {
    if (window.confirm('Are you sure?'))
      try {
        const deleteRequests = checkedSeries.map(serieId =>
          dispatch(deleteSerie(serieId))
        )
        await Promise.all(deleteRequests)
        dispatch(getSeries())
      } catch (error) {
        console.error('Chyba při mazání eventu', error)
      }
    toast.success('Selected series were successfully deleted!')
  }

  useEffect(() => {
    return () => {
      if (serieSuccess) {
        dispatch(reset())
      }
    }
  }, [dispatch, serieSuccess])

  useEffect(() => {
    dispatch(getSeries())
  }, [dispatch])

  useEffect(() => {
    if (isCheckedAll) {
      setCheckedSeries(series.map(serie => serie.id))
    } else {
      setCheckedSeries([])
    }
  }, [isCheckedAll, series])

  if (serieLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <div className='table-bookmark'>
            <button
              className={`table-bookmark__button ${
                activeFilter === 'all' ? 'table-bookmark__button--active' : ''
              }`}
              onClick={() => {
                setActiveFilter('all')
                setFilterFullfilled(false)
                setFilterUnfinished(false)
              }}
            >
              <HiDatabase
                className={`table-bookmark__icon ${
                  activeFilter === 'all' ? 'table-bookmark__icon--active' : ''
                }`}
              />{' '}
              All Series
            </button>
            <button
              className={`table-bookmark__button ${
                activeFilter === 'fullfilled'
                  ? 'table-bookmark__button--active'
                  : ''
              }`}
              onClick={() => {
                setActiveFilter('fullfilled')
                setFilterFullfilled(true)
                setFilterUnfinished(false)
              }}
            >
              <HiCheckCircle
                className={`table-bookmark__icon ${
                  activeFilter === 'fullfilled'
                    ? 'table-bookmark__icon--active'
                    : ''
                }`}
              />{' '}
              Fullfilled
            </button>
            <button
              className={`table-bookmark__button ${
                activeFilter === 'unfinished'
                  ? 'table-bookmark__button--active'
                  : ''
              }`}
              onClick={() => {
                setActiveFilter('unfinished')
                setFilterFullfilled(false)
                setFilterUnfinished(true)
              }}
            >
              <HiXCircle
                className={`table-bookmark__icon ${
                  activeFilter === 'unfinished'
                    ? 'table-bookmark__icon--active'
                    : ''
                }`}
              />{' '}
              Unfinished
            </button>
          </div>
          <div className='authors-bulk'>
            <div className='entity-search'>
              <HiFilter className='entity-search__icon' />
              <input
                type='text'
                className='entity-search__input'
                placeholder='Filter series'
                onChange={onChange}
              />
              <div className='entity-search__filter-box'>
                <HiAdjustments className='entity-search__filter-box__icon' />
              </div>
            </div>
            <button className='btn btn-l'>
              <FaEdit className='button-box__icon' />
              Bulk edit
            </button>
          </div>
          <div className='authors'>
            <div className='entity-table'>
              <div className='entity-table__header series-table-row'>
                <input
                  type='checkbox'
                  className='entity-check'
                  id='selectAll'
                  checked={isCheckedAll}
                  onChange={() => setIsCheckedAll(!isCheckedAll)}
                />
                <label htmlFor='selectAll'></label>
                <div>Name</div>
                <div>Publishing</div>
                <div className='asc'>Volumes</div>
                <div className='asc'>Issues</div>
                <div className='asc'>Status</div>
                <div className='asc'>Manual control</div>
                <div>Last Update</div>
                <div>Actions</div>
              </div>
            </div>
            {series
              .filter(serie => {
                if (activeFilter === 'fullfilled' && serie.checked) {
                  return true
                } else if (activeFilter === 'unfinished' && !serie.checked) {
                  return true
                } else if (activeFilter === 'all') {
                  return true
                } else {
                  return false
                }
              })
              .filter(serie => {
                if (searchTerm === '') {
                  return true
                } else if (
                  `${serie.name}`
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ) {
                  return true
                } else {
                  return false
                }
              })
              .map(serie => (
                <SeriesTableRow
                  key={serie.id}
                  serie={serie}
                  isChecked={checkedSeries.includes(serie.id)}
                  onCheckboxChange={handleCheckboxChange}
                />
              ))
              .reverse()}
            <div className='table-footer'>
              <button
                type='button'
                className='add-issue-btn add-issue-btn-purple'
                disabled='true'
              >
                <BiSlider className='add-issue-btn-icon' /> mass editing
              </button>
              <button
                type='button'
                className='add-issue-btn add-issue-btn-red'
                onClick={deleteSelectedSeries}
                disabled={checkedSeries.length === 0}
              >
                <BiSolidTrash className='add-issue-btn-icon' /> delete selected
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SeriesTable
