import Navigation from '../layout/Navigation';
import Topbar from '../layout/Topbar';
import Spinner from '../components/Spinner';
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg';
import { HiSave, HiOfficeBuilding } from 'react-icons/hi';
import { ImEarth } from 'react-icons/im';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import {
  getEarth,
  deleteEarth,
  getEarths,
  updateEarth,
} from '../features/earths/earthSlice';
import {
  getTags,
  createTag,
  addNewTagToState,
} from '../features/tags/tagSlice';
import { getPublishings } from '../features/publishings/publishingSlice';
import { addedBy, updatedBy } from '../features/users/userSlice';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FaTags } from 'react-icons/fa';
import Breadcrumbs from '../layout/Breadcrumbs';

const EarthDetail = () => {
  const [name, setName] = useState('');
  const [tagIds, setTagIds] = useState([]);
  const [publishingIds, setPublishingIds] = useState([]);
  const [description, setDescription] = useState('');

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch earth to Redux by URL params
  const { earthId } = params;
  const { earth, earthLoading, earthError, message } = useSelector(
    (state) => state.earths
  );
  const { tags } = useSelector((state) => state.tags);

  const { publishings } = useSelector((state) => state.publishings);

  const selectRef = useRef(null); // Create a ref for CreatableSelect

  useEffect(() => {
    if (earthError) {
      toast.error(message);
    }

    dispatch(getEarth(earthId));
    // eslint-disable-next-line
  }, [earthError, message, earthId]);

  useEffect(() => {
    dispatch(getTags());
    dispatch(getPublishings());
    // eslint-disable-next-line
  }, []);

  // Set default form data from earths reducer
  useEffect(() => {
    setName(earth.name && earth.name);
    setDescription(!earth.description ? '' : earth.description);
    setTagIds(earth.tags?.map((tag) => ({ id: tag.id, name: tag.name })));
    setPublishingIds(earth.publishingIds);
    dispatch(addedBy(earth.addedBy));
    dispatch(updatedBy(earth.lastUpdatedBy));
  }, [dispatch, earth]);

  // CREATE NEW TAG AND ASSIGNE IT TO PUBLISHING
  const handleCreateTag = async (inputValue) => {
    try {
      const { payload: createdTag } = await dispatch(
        createTag({ name: inputValue })
      );
      if (createdTag && createdTag.id) {
        dispatch(addNewTagToState(createdTag));
        setTagIds((prevTagIds) => [
          ...prevTagIds,
          { id: createdTag.id, name: createdTag.name },
        ]);
      } else {
        console.error('Failed to create Tag or no Tag ID returned.');
      }
    } catch (error) {
      console.error('Error creating Tag:', error);
    }
  };

  // Update earth
  const onSubmit = (e) => {
    e.preventDefault();

    const earthData = {
      name,
      tagIds: tagIds?.map(({ id }) => id),
      description,
    };

    dispatch(updateEarth({ earthId, earthData }));

    if (earthError) {
      toast.error(message);
    } else {
      toast.success('Earth successfully updated!');
    }
  };

  // Delete publishing
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteEarth(earthId));
      navigate('/earths');
      dispatch(getEarths());
    }
  };

  const earthPublishings = publishings.filter((publishing) =>
    publishingIds?.includes(publishing.id)
  );

  const creator = useSelector((state) => state.users.addedBy);
  const updator = useSelector((state) => state.users.updatedBy);

  const breadcrumbsPath = ['earths', `${earth.name}`];

  if (earthLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <Breadcrumbs path={breadcrumbsPath} />
          <form onSubmit={onSubmit}>
            <div className='detail-form grid-3'>
              <div className='detail-form__heading'>
                <div className='heading-form'>
                  <ImEarth className='detail-form__icon' /> {earth.name}{' '}
                </div>
                <div className='entity-info'>
                  <p className='entity-info__id bold'>ID: {earth.id}</p>
                  <div className='entity-info__slug bold mar-left-xs'>
                    {earth.slug}
                  </div>
                </div>
              </div>

              <div className='detail-form__user-info'>
                <p className='pad-bottom-xs mar-left-s'>
                  Created:{' '}
                  <span className='bold'>
                    {new Date(earth.createdAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${creator.name} ${creator.surname}`}</span>
                </p>
                <p className='mar-left-s'>
                  Updated:{' '}
                  <span className='bold'>
                    {new Date(earth.updatedAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${updator.name} ${updator.surname}`}</span>
                </p>
              </div>

              <div className='detail-form__button-box'>
                <button type='submit' className='btn-new btn-save mar-right-xs'>
                  <HiSave className='button-icon-new' />
                </button>
                <button
                  type='button'
                  className='btn-new btn-delete'
                  onClick={deleteHandler}
                >
                  <MdDelete className='button-icon-new' />
                </button>
              </div>

              <div className='detail-form__main-2 container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  Basic Info
                </label>
                <div className='input-box'>
                  <ImEarth className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='name' className='input__label'>
                      Earth Name
                    </label>
                    <input
                      type='text'
                      className='input'
                      id='name'
                      placeholder='Earth Name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete='off'
                      required
                    />
                  </div>
                  <div className='required-mark-box'>
                    <span className='required-mark'>&#10044;</span>
                  </div>
                </div>

                <div className='input-box-2'>
                  <FaTags className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='tags' className='input__label'>
                      Tags
                    </label>
                    <CreatableSelect
                      className='react-select-xl'
                      classNamePrefix='react-select-xl'
                      placeholder='Add tags'
                      isMulti
                      options={tags}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      value={tagIds}
                      onCreateOption={handleCreateTag} // Handle tag creation
                      onChange={(e) => setTagIds(e)}
                      isValidNewOption={(inputValue) => inputValue}
                      getNewOptionData={(inputValue) => ({
                        value: inputValue,
                        name: `Create ${inputValue}`,
                      })}
                      menuPortalTarget={document.body} // Set menuPortalTarget to document.body
                      ref={selectRef} // Attach the ref to CreatableSelect
                    />
                  </div>
                </div>

                <div className='input-box-1--disabled'>
                  <HiOfficeBuilding className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='publishings' className='input__label'>
                      Publishings
                    </label>
                    <Select
                      className='react-select-disabled'
                      classNamePrefix='react-select-disabled'
                      placeholder='Publishings'
                      isMulti
                      isSearchable={false}
                      isClearable={false}
                      options={false}
                      value={earthPublishings}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      //menuPortalTarget={document.body} // Set menuPortalTarget to document.body
                    />
                  </div>
                </div>
              </div>

              <div className='detail-form__description container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  description
                </label>
                <EditorProvider>
                  <Editor
                    className='rsw-editor'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EarthDetail;
