import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import issueService from './issueService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  issues: [],
  issue: {},
  issueError: false,
  issueSuccess: false,
  issueLoading: false,
  message: '',
}

// Create issue
export const createIssue = createAsyncThunk(
  'issues/create',
  async (issue, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await issueService.createIssue(issue, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get all issues
export const getIssues = createAsyncThunk(
  'issues/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await issueService.getIssues(token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get issue
export const getIssue = createAsyncThunk(
  'issues/get',
  async (issueId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await issueService.getIssue(issueId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Update issue
export const updateIssue = createAsyncThunk(
  'issues/update',
  async (issue, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await issueService.updateIssue(
        issue.issueId,
        issue.issueData,
        token
      )
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Delete issue
export const deleteIssue = createAsyncThunk(
  'issues/delete',
  async (issueId, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await issueService.deleteIssue(issueId, token)
  }
)

export const issueSlice = createSlice({
  name: 'issue',
  initialState,
  reducers: {
    reset: state => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(createIssue.pending, state => {
        state.issueLoading = true
      })
      .addCase(createIssue.fulfilled, (state, action) => {
        state.issueLoading = false
        state.issueSuccess = true
        state.issue = action.payload
      })
      .addCase(createIssue.rejected, (state, action) => {
        state.issueLoading = false
        state.issueError = true
        state.message = action.payload
        state.issue = null
      })
      .addCase(getIssues.pending, state => {
        state.issueLoading = true
      })
      .addCase(getIssues.fulfilled, (state, action) => {
        state.issueLoading = false
        state.issueSuccess = true
        state.issues = action.payload
      })
      .addCase(getIssues.rejected, (state, action) => {
        state.issueLoading = false
        state.issueError = true
        state.message = action.payload
      })
      .addCase(getIssue.pending, state => {
        state.issueLoading = true
      })
      .addCase(getIssue.fulfilled, (state, action) => {
        state.issueLoading = false
        state.issueSuccess = true
        state.issue = action.payload
      })
      .addCase(getIssue.rejected, (state, action) => {
        state.issueLoading = false
        state.issueError = true
        state.message = action.payload
      })
      .addCase(updateIssue.pending, state => {
        state.issueLoading = true
      })
      .addCase(updateIssue.fulfilled, (state, action) => {
        state.issueLoading = false
        state.issueSuccess = true
        state.issue = action.payload
      })
      .addCase(updateIssue.rejected, (state, action) => {
        state.issueLoading = false
        state.issueError = true
        state.message = action.payload
      })
      .addCase(deleteIssue.fulfilled, state => {
        state.issueSuccess = true
      })
  },
})

export const { reset } = issueSlice.actions
export default issueSlice.reducer
