import axios from 'axios'

const backendURL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${backendURL}/api/v1/users/`
const AUTH_URL = `${backendURL}/api/v1/auth/`

// Login user
const login = async userData => {
  const response = await axios.post(`${AUTH_URL}sign-in`, userData)

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }

  return response.data
}

// Logout user
const logout = () => {
  localStorage.removeItem('user')
  window.location.reload(false)
}

// Get all users
const getUsers = async token => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL, config)
  return response.data
}

// Get user
const getUser = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + userId, config)
  return response.data
}

// Update user
const updateUser = async (userId, userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }
  const response = await axios.put(API_URL + userId, userData, config)

  return response.data
}

const userService = {
  login,
  logout,
  getUsers,
  getUser,
  updateUser,
}

export default userService
