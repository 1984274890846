import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getBookSeries,
  deleteBookSerie,
  reset,
} from '../features/bookSeries/bookSerieSlice'
import {
  HiCheckCircle,
  HiXCircle,
  HiDatabase,
  HiAdjustments,
  HiFilter,
} from 'react-icons/hi'
import { BiSlider, BiSolidTrash } from 'react-icons/bi'
import { FaEdit } from 'react-icons/fa'
import Navigation from '../layout/Navigation'
import Topbar from '../layout/Topbar'
import BookSeriesTableRow from '../components/entityTables/BookSeriesTableRow'
import Spinner from '../components/Spinner'
import { toast } from 'react-toastify'

const BookSeriesTable = () => {
  const { bookSeries, bookSerieLoading, bookSerieSuccess } = useSelector(
    state => state.bookSeries
  )

  const [searchTerm, setSearchTerm] = useState('')
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [checkedBookSeries, setCheckedBookSeries] = useState([])
  const [filterFullfilled, setFilterFullfilled] = useState(false)
  const [filterUnfinished, setFilterUnfinished] = useState(false)
  const [activeFilter, setActiveFilter] = useState('all')

  const dispatch = useDispatch()

  const onChange = e => {
    setSearchTerm(e.target.value)
  }

  const handleCheckboxChange = id => {
    if (checkedBookSeries.includes(id)) {
      const updatedCheckedBookSeries = checkedBookSeries.filter(
        bookSerieId => bookSerieId !== id
      )
      setCheckedBookSeries(updatedCheckedBookSeries)
    } else {
      const updatedCheckedBookSeries = [...checkedBookSeries, id]
      setCheckedBookSeries(updatedCheckedBookSeries)
    }
  }

  // Delete fce for selected series
  const deleteSelectedBookSeries = async () => {
    if (window.confirm('Are you sure?'))
      try {
        const deleteRequests = checkedBookSeries.map(bookSerieId =>
          dispatch(deleteBookSerie(bookSerieId))
        )
        await Promise.all(deleteRequests)
        dispatch(getBookSeries())
      } catch (error) {
        console.error('Chyba při mazání ', error)
      }
    toast.success('Selected Book(s) Series were successfully deleted!')
  }

  useEffect(() => {
    return () => {
      if (bookSerieSuccess) {
        dispatch(reset())
      }
    }
  }, [dispatch, bookSerieSuccess])

  useEffect(() => {
    dispatch(getBookSeries())
  }, [dispatch])

  useEffect(() => {
    if (isCheckedAll) {
      setCheckedBookSeries(bookSeries.map(bookSerie => bookSerie.id))
    } else {
      setCheckedBookSeries([])
    }
  }, [isCheckedAll, bookSeries])

  if (bookSerieLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <div className='table-bookmark'>
            <button
              className={`table-bookmark__button ${
                activeFilter === 'all' ? 'table-bookmark__button--active' : ''
              }`}
              onClick={() => {
                setActiveFilter('all')
                setFilterFullfilled(false)
                setFilterUnfinished(false)
              }}
            >
              <HiDatabase
                className={`table-bookmark__icon ${
                  activeFilter === 'all' ? 'table-bookmark__icon--active' : ''
                }`}
              />{' '}
              All Book Series
            </button>
            <button
              className={`table-bookmark__button ${
                activeFilter === 'fullfilled'
                  ? 'table-bookmark__button--active'
                  : ''
              }`}
              onClick={() => {
                setActiveFilter('fullfilled')
                setFilterFullfilled(true)
                setFilterUnfinished(false)
              }}
            >
              <HiCheckCircle
                className={`table-bookmark__icon ${
                  activeFilter === 'fullfilled'
                    ? 'table-bookmark__icon--active'
                    : ''
                }`}
              />{' '}
              Fullfilled
            </button>
            <button
              className={`table-bookmark__button ${
                activeFilter === 'unfinished'
                  ? 'table-bookmark__button--active'
                  : ''
              }`}
              onClick={() => {
                setActiveFilter('unfinished')
                setFilterFullfilled(false)
                setFilterUnfinished(true)
              }}
            >
              <HiXCircle
                className={`table-bookmark__icon ${
                  activeFilter === 'unfinished'
                    ? 'table-bookmark__icon--active'
                    : ''
                }`}
              />{' '}
              Unfinished
            </button>
          </div>
          <div className='authors-bulk'>
            <div className='entity-search'>
              <HiFilter className='entity-search__icon' />
              <input
                type='text'
                className='entity-search__input'
                placeholder='Filter book series'
                onChange={onChange}
              />
              <div className='entity-search__filter-box'>
                <HiAdjustments className='entity-search__filter-box__icon' />
              </div>
            </div>
            <button className='btn btn-l'>
              <FaEdit className='button-box__icon' />
              Bulk edit
            </button>
          </div>
          <div className='authors'>
            <div className='entity-table'>
              <div className='entity-table__header bookSeries-table-row'>
                <input
                  type='checkbox'
                  className='entity-check'
                  id='selectAll'
                  checked={isCheckedAll}
                  onChange={() => setIsCheckedAll(!isCheckedAll)}
                />
                <label htmlFor='selectAll'></label>
                <div>Name</div>
                <div>Number of assigned books</div>
                <div className='asc'>Status</div>
                <div className='asc'>Manual control</div>
                <div>Last Updated</div>
                <div>Actions</div>
              </div>
            </div>
            {bookSeries
              .filter(bookSerie => {
                if (activeFilter === 'fullfilled' && bookSerie.checked) {
                  return true
                } else if (
                  activeFilter === 'unfinished' &&
                  !bookSerie.checked
                ) {
                  return true
                } else if (activeFilter === 'all') {
                  return true
                } else {
                  return false
                }
              })
              .filter(bookSerie => {
                if (searchTerm === '') {
                  return true
                } else if (
                  `${bookSerie.name}`
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ) {
                  return true
                } else {
                  return false
                }
              })
              .map(bookSerie => (
                <BookSeriesTableRow
                  key={bookSerie.id}
                  bookSerie={bookSerie}
                  isChecked={checkedBookSeries.includes(bookSerie.id)}
                  onCheckboxChange={handleCheckboxChange}
                />
              ))
              .reverse()}
            <div className='table-footer'>
              <button
                type='button'
                className='add-issue-btn add-issue-btn-purple'
                disabled='true'
              >
                <BiSlider className='add-issue-btn-icon' /> mass editing
              </button>
              <button
                type='button'
                className='add-issue-btn add-issue-btn-red'
                onClick={deleteSelectedBookSeries}
                disabled={checkedBookSeries.length === 0}
              >
                <BiSolidTrash className='add-issue-btn-icon' /> delete selected
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BookSeriesTable
