import Navigation from '../layout/Navigation'
import Topbar from '../layout/Topbar'
import DashLastAdded from '../components/DashLastAdded'
import DashOverview from '../components/DashOverview'
import DashHubmates from '../components/DashHubmates'
import DashLastActivity from '../components/DashLastActivity'
import DashMonthResult from '../components/DashMonthResult'
import DashTeamEvents from '../components/DashTeamEvents'
import DashRank from '../components/DashRank'
import DashNothing from '../components/DashNothing'

const Dashboard = () => {
  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <div className='dashboard'>
            <DashLastAdded />
            <DashOverview />
            <DashHubmates />
            <DashLastActivity />
            <DashMonthResult />
            <DashTeamEvents />
            <DashRank />
            <DashNothing />
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
