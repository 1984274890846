import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PrivateRoute from './components/PrivateRoute'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import CharactersTable from './pages/CharacterTable'
import CharacterDetail from './pages/CharacterDetail'
import AuthorsTable from './pages/AuthorsTable'
import AuthorsDetail from './pages/AuthorsDetail'
import PublishingsTable from './pages/PublishingsTable'
import PublishingsDetail from './pages/PublishingsDetail'
import EarthsTable from './pages/EarthsTable'
import EarthDetail from './pages/EarthDetail'
import OrganizationsTable from './pages/OrganizationsTable'
import OrganizationDetail from './pages/OrganizationDetail'
import SeriesTable from './pages/SeriesTable'
import SeriesDetail from './pages/SeriesDetail'
import BooksTable from './pages/BooksTable'
import BookDetail from './pages/BookDetail'
import BookSeriesTable from './pages/BookSeriesTable'
import BookSerieDetail from './pages/BookSerieDetail'
import StoryArcsTable from './pages/StoryArcsTable'
import StoryArcsDetail from './pages/StoryArcsDetail'
import EventsTable from './pages/EventsTable'
import EventsDetail from './pages/EventsDetail'
import IssueDetail from './pages/IssueDetail'
import Users from './pages/Users'
import UserProfile from './pages/UserProfile'
import MyProfile from './pages/MyProfile'

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/dashboard' element={<PrivateRoute />}>
            <Route path='/dashboard' element={<Dashboard />} />
          </Route>
          <Route path='/characters' element={<PrivateRoute />}>
            <Route path='/characters' element={<CharactersTable />} />
          </Route>
          <Route path='/characters/:characterId' element={<PrivateRoute />}>
            <Route
              path='/characters/:characterId'
              element={<CharacterDetail />}
            />
          </Route>
          <Route path='/authors' element={<PrivateRoute />}>
            <Route path='/authors' element={<AuthorsTable />} />
          </Route>
          <Route path='/authors/:authorId' element={<PrivateRoute />}>
            <Route path='/authors/:authorId' element={<AuthorsDetail />} />
          </Route>
          <Route path='/publishings' element={<PrivateRoute />}>
            <Route path='/publishings' element={<PublishingsTable />} />
          </Route>
          <Route path='/publishings/:publishingId' element={<PrivateRoute />}>
            <Route
              path='/publishings/:publishingId'
              element={<PublishingsDetail />}
            />
          </Route>
          <Route path='/earths' element={<PrivateRoute />}>
            <Route path='/earths' element={<EarthsTable />} />
          </Route>
          <Route path='/earths/:earthId' element={<PrivateRoute />}>
            <Route path='/earths/:earthId' element={<EarthDetail />} />
          </Route>
          <Route path='/organizations' element={<PrivateRoute />}>
            <Route path='/organizations' element={<OrganizationsTable />} />
          </Route>
          <Route
            path='/organizations/:organizationId'
            element={<PrivateRoute />}
          >
            <Route
              path='/organizations/:organizationId'
              element={<OrganizationDetail />}
            />
          </Route>
          <Route path='/series' element={<PrivateRoute />}>
            <Route path='/series' element={<SeriesTable />} />
          </Route>
          <Route path='/series/:serieId' element={<PrivateRoute />}>
            <Route path='/series/:serieId' element={<SeriesDetail />} />
          </Route>
          <Route path='/series/:serieId/:issueId' element={<PrivateRoute />}>
            <Route path='/series/:serieId/:issueId' element={<IssueDetail />} />
          </Route>
          <Route path='/users' element={<PrivateRoute />}>
            <Route path='/users' element={<Users />} />
          </Route>
          <Route path='/books' element={<PrivateRoute />}>
            <Route path='/books' element={<BooksTable />} />
          </Route>
          <Route path='/books/:bookId' element={<PrivateRoute />}>
            <Route path='/books/:bookId' element={<BookDetail />} />
          </Route>
          <Route path='/book-series' element={<PrivateRoute />}>
            <Route path='/book-series' element={<BookSeriesTable />} />
          </Route>
          <Route path='/book-series/:bookSerieId' element={<PrivateRoute />}>
            <Route
              path='/book-series/:bookSerieId'
              element={<BookSerieDetail />}
            />
          </Route>
          <Route path='/story-arcs' element={<PrivateRoute />}>
            <Route path='/story-arcs' element={<StoryArcsTable />} />
          </Route>
          <Route path='/story-arcs/:storyArcId' element={<PrivateRoute />}>
            <Route
              path='/story-arcs/:storyArcId'
              element={<StoryArcsDetail />}
            />
          </Route>
          <Route path='/events' element={<PrivateRoute />}>
            <Route path='/events' element={<EventsTable />} />
          </Route>
          <Route path='/events/:eventId' element={<PrivateRoute />}>
            <Route path='/events/:eventId' element={<EventsDetail />} />
          </Route>
          <Route path='/users/:userId' element={<PrivateRoute />}>
            <Route path='/users/:userId' element={<UserProfile />} />
          </Route>
          <Route path='/my-profile' element={<PrivateRoute />}>
            <Route path='/my-profile' element={<MyProfile />} />
          </Route>
          <Route path='*' element={<Login />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  )
}

export default App
