import axios from 'axios'

const backendURL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${backendURL}/api/v1/stories/`

// Create story
const createStory = async (story, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.post(API_URL, story, config)
  return response.data
}

// Get all stories
const getStories = async token => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.get(API_URL, config)
  return response.data
}

// Get story
const getStory = async (storyId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + storyId, config)
  return response.data
}

// Update story
const updateStory = async (storyId, storyData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.put(API_URL + storyId, storyData, config)

  return response.data
}

// Delete story
const deleteStory = async (storyId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + storyId, config)
  return response.data
}

const storyService = {
  createStory,
  getStories,
  getStory,
  updateStory,
  deleteStory,
}

export default storyService
