import { useEffect, useState } from 'react'
import { getTags } from '../../features/tags/tagSlice'
import { createEarth, getEarths } from '../../features/earths/earthSlice'
import { getPublishings } from '../../features/publishings/publishingSlice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { FaTags } from 'react-icons/fa'
import { MdLibraryAddCheck } from 'react-icons/md'
import { ImEarth } from 'react-icons/im'
import { HiOfficeBuilding } from 'react-icons/hi'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'

const CreateEarth = () => {
  const [name, setName] = useState('')

  const [tagIds, setTagIds] = useState([])
  const [publishingIds, setPublishingIds] = useState([])

  const dispatch = useDispatch()

  const { earthError, message } = useSelector(state => state.earths)
  const { tags } = useSelector(state => state.tags)
  const { publishings } = useSelector(state => state.publishings)

  useEffect(() => {
    dispatch(getTags())
    dispatch(getPublishings())
    // eslint-disable-next-line
  }, [])

  const onSubmit = e => {
    e.preventDefault()

    const earthData = {
      name,
      publishingIds: publishingIds.map(({ id }) => id),
      tagIds: tagIds.map(({ value }) => value),
    }

    dispatch(createEarth(earthData))
    dispatch(getEarths())

    if (earthError) {
      toast.error(message)
    } else {
      toast.success(`Earth created!`)
    }
  }
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className='detail-form grid-2'>
          <div className='detail-form__heading'>
            <div className='heading-form'>Create Earth</div>
          </div>

          <div className='detail-form__user-info'>
            <p>
              <span className='required-mark'>&#10044;</span>Data marked with an
              asterisk are required, please fill them in before saving ...{' '}
            </p>
          </div>

          <div className='detail-form__button-box'>
            <button type='submit' className='btn btn-l' onSubmit={onSubmit}>
              <MdLibraryAddCheck className='button-icon' />
              Create
            </button>
          </div>

          <div className='detail-form__main-2 container'>
            <div className='input-box'>
              <ImEarth className='input__icon' />
              <div className='input-area'>
                <label htmlFor='name' className='form__label'>
                  Earth Name
                </label>
                <input
                  type='text'
                  className='input'
                  id='name'
                  placeholder='Add Earth Name'
                  value={name}
                  onChange={e => setName(e.target.value)}
                  autoComplete='off'
                  required
                />
              </div>
              <div className='required-mark-box'>
                <span className='required-mark'>&#10044;</span>
              </div>
            </div>

            <div className='input-box-2'>
              <FaTags className='input__icon' />
              <div className='input-area-multi'>
                <label htmlFor='tags' className='form__label'>
                  Tags
                </label>
                <CreatableSelect
                  className='react-select-container'
                  classNamePrefix='react-select'
                  placeholder='Add tags'
                  isMulti
                  options={tags}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  value={tagIds}
                  onChange={e => setTagIds(e)}
                />
              </div>
            </div>

            <div className='input-box-1'>
              <HiOfficeBuilding className='input__icon' />
              <div className='input-area-multi'>
                <label htmlFor='publishing' className='form__label'>
                  Publishing
                </label>
                <Select
                  className='react-select-container'
                  classNamePrefix='react-select'
                  id='publishing'
                  placeholder='Publishing'
                  isMulti
                  options={publishings}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  value={publishingIds}
                  onChange={e => setPublishingIds(e)}
                />
              </div>
              <div className='required-mark-box'>
                <span className='required-mark'>&#10044;</span>
              </div>
            </div>
          </div>

          <div className='detail-form__description container'>
            <textarea
              rows='num'
              cols='num'
              type='text'
              className='input__textarea'
              name='description'
              placeholder='Input description..'
              autoComplete='off'
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default CreateEarth
