const DashNothing = () => {
  return (
    <>
      <div className='item nothing-so-far'>
        <h3 className='heading-tertiary-dark'>Nothing So Far</h3>
      </div>
    </>
  )
}

export default DashNothing
