import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import organizationService from './organizationService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  organizations: [],
  organization: {},
  organizationError: false,
  organizationSuccess: false,
  organizationLoading: false,
  message: '',
}

// Create organization
export const createOrganization = createAsyncThunk(
  'organizations/create',
  async (organization, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await organizationService.createOrganization(organization, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get all organizations
export const getOrganizations = createAsyncThunk(
  'organizations/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await organizationService.getOrganizations(token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get organization
export const getOrganization = createAsyncThunk(
  'organizations/get',
  async (organizationId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await organizationService.getOrganization(organizationId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Update organization
export const updateOrganization = createAsyncThunk(
  'organizations/update',
  async (organization, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await organizationService.updateOrganization(
        organization.organizationId,
        organization.organizationData,
        token
      )
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Delete organization
export const deleteOrganization = createAsyncThunk(
  'organization/delete',
  async (organizationId, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await organizationService.deleteOrganization(organizationId, token)
  }
)

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    reset: state => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(createOrganization.pending, state => {
        state.organizationLoading = true
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.organizationLoading = false
        state.organizationSuccess = true
        state.organization = action.payload
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.organizationLoading = false
        state.organizationError = true
        state.message = action.payload
        state.organization = null
      })
      .addCase(getOrganizations.pending, state => {
        state.organizationLoading = true
      })
      .addCase(getOrganizations.fulfilled, (state, action) => {
        state.organizationLoading = false
        state.organizationSuccess = true
        state.organizations = action.payload
      })
      .addCase(getOrganizations.rejected, (state, action) => {
        state.organizationLoading = false
        state.organizationError = true
        state.message = action.payload
      })
      .addCase(getOrganization.pending, state => {
        state.organizationLoading = true
      })
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.organizationLoading = false
        state.organizationSuccess = true
        state.organization = action.payload
      })
      .addCase(getOrganization.rejected, (state, action) => {
        state.organizationLoading = false
        state.organizationError = true
        state.message = action.payload
      })
      .addCase(updateOrganization.pending, state => {
        state.organizationLoading = true
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.organizationLoading = false
        state.organizationSuccess = true
        state.organization = action.payload
      })
      .addCase(updateOrganization.rejected, (state, action) => {
        state.organizationLoading = false
        state.organizationError = true
        state.message = action.payload
      })
      .addCase(deleteOrganization.fulfilled, state => {
        state.organizationSuccess = true
      })
  },
})

export const { reset } = organizationSlice.actions
export default organizationSlice.reducer
