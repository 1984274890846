import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import userService from './userService'
import { handleCatchBlock } from '../utils/slices'

// Get user from localstorage
const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
  auth: user ? user : null,
  users: [],
  user: {},
  addedBy: {},
  updatedBy: {},
  userError: false,
  userSuccess: false,
  userLoading: false,
  message: '',
}

// Login user
export const login = createAsyncThunk('user/login', async (user, thunkAPI) => {
  try {
    return await userService.login(user)
  } catch (error) {
    return handleCatchBlock(error, thunkAPI, false)
  }
})

// Logout user
export const logout = createAction('user/logout', () => {
  userService.logout()
  return {}
})

// Get all users
export const getUsers = createAsyncThunk(
  'users/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await userService.getUsers(token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get user
export const getUser = createAsyncThunk(
  'users/get',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await userService.getUser(userId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Update user
export const updateUser = createAsyncThunk(
  'users/update',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await userService.updateUser(data.userId, data.userData, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Added by
export const addedBy = createAsyncThunk(
  'users/addedBy',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await userService.getUser(userId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Updated by
export const updatedBy = createAsyncThunk(
  'users/updatedBy',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await userService.getUser(userId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: state => {
      state.users = []
      state.user = {}
      state.addedBy = {}
      state.updatedBy = {}
      state.userLoading = false
      state.userError = false
      state.userSuccess = false
      state.message = ''
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.userLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.userLoading = false
        state.userSuccess = true
        state.auth = action.payload
      })
      .addCase(login.rejected, (state, action) => {
        state.userLoading = false
        state.userError = true
        state.message = action.payload
        state.auth = null
      })
      .addCase(logout.type, state => {
        state.auth = null
      })
      .addCase(getUsers.pending, state => {
        state.userLoading = true
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.userLoading = false
        state.userSuccess = true
        state.users = action.payload
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.userLoading = false
        state.userError = true
        state.message = action.payload
      })
      .addCase(getUser.pending, state => {
        state.userLoading = true
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.userLoading = false
        state.userSuccess = true
        state.user = action.payload
      })
      .addCase(getUser.rejected, (state, action) => {
        state.userLoading = false
        state.userError = true
        state.message = action.payload
      })
      .addCase(updateUser.pending, state => {
        state.userLoading = true
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.userLoading = false
        state.userSuccess = true
        state.user = action.payload
        state.auth.user = action.payload
        const updateLS = localStorage.setItem(
          'user',
          JSON.stringify({
            user: action.payload,
            credentials: state.auth.credentials,
          })
        )
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.userLoading = false
        state.userError = true
        state.message = action.payload
      })
      .addCase(addedBy.pending, state => {
        state.userLoading = true
      })
      .addCase(addedBy.fulfilled, (state, action) => {
        state.userLoading = false
        state.userSuccess = true
        state.addedBy = action.payload
      })
      .addCase(addedBy.rejected, (state, action) => {
        state.userLoading = false
        state.userError = true
        state.message = action.payload
      })
      .addCase(updatedBy.pending, state => {
        state.userLoading = true
      })
      .addCase(updatedBy.fulfilled, (state, action) => {
        state.userLoading = false
        state.userSuccess = true
        state.updatedBy = action.payload
      })
      .addCase(updatedBy.rejected, (state, action) => {
        state.userLoading = false
        state.userError = true
        state.message = action.payload
      })
  },
})

export const { reset } = userSlice.actions
export default userSlice.reducer
