import axios from 'axios'

const backendURL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${backendURL}/api/v1/issues/`

// Create issue
const createIssue = async (issue, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.post(API_URL, issue, config)
  return response.data
}

// Get all issues
const getIssues = async token => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.get(API_URL, config)
  return response.data
}

// Get issue
const getIssue = async (issueId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + issueId, config)
  return response.data
}

// Update issue
const updateIssue = async (issueId, issueData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.put(API_URL + issueId, issueData, config)

  return response.data
}

// Delete issue
const deleteIssue = async (issueId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + issueId, config)
  return response.data
}

const issueService = {
  createIssue,
  getIssues,
  getIssue,
  updateIssue,
  deleteIssue,
}

export default issueService
