import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { getUser, reset } from '../features/users/userSlice'
import { useParams } from 'react-router-dom'
//import BackButton from '../components/BackButton';
import Spinner from '../components/Spinner'
import Navigation from '../layout/Navigation'
import Topbar from '../layout/Topbar'
import Avatar from 'react-avatar'

const UserProfile = () => {
  const { user, userLoading, userError, userSuccess, message } = useSelector(
    state => state.users
  )

  const params = useParams()
  const dispatch = useDispatch()

  const { userId } = params

  useEffect(() => {
    if (userError) {
      toast.error(message)
    }

    dispatch(getUser(userId))
    // eslint-disable-next-line
  }, [userError, message, userId])

  if (userLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <div className='user-profile__bio'>
            <div className='user-profile__initials'>
              <div className='user-profile__img'>
                <Avatar name={`${user.name} ${user.surname}`} size='100%' />
              </div>
              <div className='user-profile__name'>
                {`${user.name} ${user.surname}`}
              </div>
              <div className='user-profile__info'>Co-Founder</div>
              <div className='user-profile__info'>{user.email}</div>
              <div className='user-profile__info'>User ID: {user.id}</div>
            </div>
            <div className='user-profile__body'>
              <div className='user-profile__bigtext'>Will be added</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserProfile
