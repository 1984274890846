import Popup from 'reactjs-popup'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from '../features/users/userSlice'
import UserAvatar from '../components/UserAvatar'
import { useState } from 'react'
import Modal from 'react-modal'
import CreateSerie from './modal/CreateSerie'
import CreateBook from './modal/CreateBook'
import CreateBookSerie from './modal/CreateBookSerie'
import CreateEvent from './modal/CreateEvent'
import CreateStoryArc from './modal/CreateStoryArc'
import CreateAuthor from './modal/CreateAuthor'
import CreateEarth from './modal/CreateEarth'
import CreatePublishing from './modal/CreatePublishing'
import CreateCharacter from './modal/CreateCharacter'
import CreateOrganization from './modal/CreateOrganization'
import { tagReset } from '../features/tags/tagSlice'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { FaUser, FaBook, FaPenAlt } from 'react-icons/fa'
import { ImEarth, ImBooks, ImBubble } from 'react-icons/im'
import { RiShieldStarLine, RiOrganizationChart } from 'react-icons/ri'
import { SiUnity } from 'react-icons/si'
import { RiUserSettingsFill, RiLogoutBoxRFill } from 'react-icons/ri'
import {
  HiSearch,
  HiPlusCircle,
  HiAnnotation,
  HiXCircle,
  HiOfficeBuilding,
} from 'react-icons/hi'

Modal.setAppElement('#root')

const Topbar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Modal setting up
  const [modalIsOpen, setModalIsOpen] = useState(false)

  // Open/Close Modal
  const openModal = () => setModalIsOpen(true)
  const closeModal = () => {
    setModalIsOpen(false)
    dispatch(tagReset())
  }

  // Active states of Tabs
  const [active, setActive] = useState('1')

  const handleClick = e => {
    setActive(e.target.id)
  }

  const onLogout = () => {
    dispatch(logout())
    //navigate('/')
  }

  const onProfile = () => {
    navigate('/my-profile')
  }

  let date = new Date()
  let options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  return (
    <>
      <div className='topbar-box'>
        <div className='top-bar'>
          <form action='#' className='search'>
            <HiSearch className='search__icon' />
            <input type='text' className='search__input' placeholder='Search' />
          </form>
          <nav className='user-nav'>
            <div className='user-nav__icon-box'>
              <button className='btn-inner' onClick={openModal}>
                <HiPlusCircle className='user-nav__icon--add' />
              </button>
            </div>
            <div className='user-nav__icon-box user-nav__icon-box--bg'>
              <HiAnnotation className='user-nav__icon' />
              <span className='user-nav__notification'>13</span>
            </div>
            <div className='user-nav__info'>
              <div className='user-nav__date'>
                <p className='p-date'>
                  {date.toLocaleDateString('en-GB', options)}
                </p>
              </div>
              <Popup
                trigger={
                  <button className='user-nav__user-photo'>
                    <UserAvatar />
                  </button>
                }
                position='bottom right'
              >
                <button className='btn-inner' onClick={onProfile}>
                  <RiUserSettingsFill />
                  <p>My profile</p>
                </button>
                <button className='btn-inner' onClick={onLogout}>
                  <RiLogoutBoxRFill title='Logout' />
                  <p>Logout</p>
                </button>
              </Popup>

              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className='adding-modal'
                contentLabel='Adding Modal'
                overlayClassName='adding-modal-overlay'
              >
                <Tabs className='adding-tabs'>
                  <TabList className='adding-tabs__nav'>
                    <div className='adding-tabs__box'>
                      {/* Adding bookmark for Serie */}
                      <Tab className='adding-tabs__tab'>
                        <button
                          onClick={handleClick}
                          id={'1'}
                          key={1}
                          className={
                            active === '1'
                              ? 'adding-tabs__nav-item--selected adding-tabs__nav-item'
                              : 'adding-tabs__nav-item'
                          }
                        >
                          <ImBubble /> Serie
                        </button>
                      </Tab>

                      {/* Adding bookmark for Book */}
                      <Tab className='adding-tabs__tab'>
                        <button
                          onClick={handleClick}
                          id={'2'}
                          key={2}
                          className={
                            active === '2'
                              ? 'adding-tabs__nav-item--selected adding-tabs__nav-item'
                              : 'adding-tabs__nav-item'
                          }
                        >
                          <FaBook /> Book
                        </button>
                      </Tab>

                      {/* Adding bookmark for Book Serie */}
                      <Tab className='adding-tabs__tab'>
                        <button
                          onClick={handleClick}
                          id={'3'}
                          key={3}
                          className={
                            active === '3'
                              ? 'adding-tabs__nav-item--selected adding-tabs__nav-item'
                              : 'adding-tabs__nav-item'
                          }
                        >
                          <ImBooks /> Book Serie
                        </button>
                      </Tab>

                      {/* Adding bookmark for Character */}
                      <Tab className='adding-tabs__tab'>
                        <button
                          onClick={handleClick}
                          id={'4'}
                          key={4}
                          className={
                            active === '4'
                              ? 'adding-tabs__nav-item--selected adding-tabs__nav-item'
                              : 'adding-tabs__nav-item'
                          }
                        >
                          <RiShieldStarLine /> Character
                        </button>
                      </Tab>

                      {/* Adding bookmark for Author */}
                      <Tab className='adding-tabs__tab'>
                        <button
                          onClick={handleClick}
                          id={'5'}
                          key={5}
                          className={
                            active === '5'
                              ? 'adding-tabs__nav-item--selected adding-tabs__nav-item'
                              : 'adding-tabs__nav-item'
                          }
                        >
                          <FaUser /> Author
                        </button>
                      </Tab>

                      {/* Adding bookmark for Publishing */}
                      <Tab className='adding-tabs__tab'>
                        <button
                          onClick={handleClick}
                          id={'6'}
                          key={6}
                          className={
                            active === '6'
                              ? 'adding-tabs__nav-item--selected adding-tabs__nav-item'
                              : 'adding-tabs__nav-item'
                          }
                        >
                          <HiOfficeBuilding /> Publishing
                        </button>
                      </Tab>

                      {/* Adding bookmark for Earth */}
                      <Tab className='adding-tabs__tab'>
                        <button
                          onClick={handleClick}
                          id={'7'}
                          key={7}
                          className={
                            active === '7'
                              ? 'adding-tabs__nav-item--selected adding-tabs__nav-item'
                              : 'adding-tabs__nav-item'
                          }
                        >
                          <ImEarth /> Earth
                        </button>
                      </Tab>

                      {/* Adding bookmark for Organization */}
                      <Tab className='adding-tabs__tab'>
                        <button
                          onClick={handleClick}
                          id={'8'}
                          key={8}
                          className={
                            active === '8'
                              ? 'adding-tabs__nav-item--selected adding-tabs__nav-item'
                              : 'adding-tabs__nav-item'
                          }
                        >
                          <RiOrganizationChart /> Organization
                        </button>
                      </Tab>

                      {/* Adding bookmark for Event */}
                      <Tab className='adding-tabs__tab'>
                        <button
                          onClick={handleClick}
                          id={'9'}
                          key={9}
                          className={
                            active === '9'
                              ? 'adding-tabs__nav-item--selected adding-tabs__nav-item'
                              : 'adding-tabs__nav-item'
                          }
                        >
                          <SiUnity /> Event
                        </button>
                      </Tab>

                      {/* Adding bookmark for Story Arc */}
                      <Tab className='adding-tabs__tab'>
                        <button
                          onClick={handleClick}
                          id={'10'}
                          key={10}
                          className={
                            active === '10'
                              ? 'adding-tabs__nav-item--selected adding-tabs__nav-item'
                              : 'adding-tabs__nav-item'
                          }
                        >
                          <FaPenAlt /> Story Arc
                        </button>
                      </Tab>
                    </div>
                    <HiXCircle className='btn-icon-1' onClick={closeModal} />
                  </TabList>

                  <TabPanel>
                    <CreateSerie />
                  </TabPanel>
                  <TabPanel>
                    <CreateBook />
                  </TabPanel>
                  <TabPanel>
                    <CreateBookSerie />
                  </TabPanel>
                  <TabPanel>
                    <CreateCharacter />
                  </TabPanel>
                  <TabPanel>
                    <CreateAuthor />
                  </TabPanel>
                  <TabPanel>
                    <CreatePublishing />
                  </TabPanel>
                  <TabPanel>
                    <CreateEarth />
                  </TabPanel>
                  <TabPanel>
                    <CreateOrganization />
                  </TabPanel>
                  <TabPanel>
                    <CreateEvent />
                  </TabPanel>
                  <TabPanel>
                    <CreateStoryArc />
                  </TabPanel>
                </Tabs>
              </Modal>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Topbar
