import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import crossoverService from './crossoverService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  crossovers: [],
  crossover: {},
  crossoverError: false,
  crossoverSuccess: false,
  crossoverLoading: false,
  message: '',
}

// Create crossover
export const createCrossover = createAsyncThunk(
  'crossovers/create',
  async (crossover, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await crossoverService.createCrossover(crossover, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get all crossovers
export const getCrossovers = createAsyncThunk(
  'crossovers/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await crossoverService.getCrossovers(token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get crossover
export const getCrossover = createAsyncThunk(
  'crossovers/get',
  async (crossoverId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await crossoverService.getCrossover(crossoverId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Update crossover
export const updateCrossover = createAsyncThunk(
  'crossovers/update',
  async (crossover, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await crossoverService.updateCrossover(
        crossover.crossoverId,
        crossover.crossoverData,
        token
      )
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Delete crossover
export const deleteCrossover = createAsyncThunk(
  'crossovers/delete',
  async (crossoverId, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await crossoverService.deleteCrossover(crossoverId, token)
  }
)

export const crossoverSlice = createSlice({
  name: 'crossover',
  initialState,
  reducers: {
    reset: state => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(createCrossover.pending, state => {
        state.crossoverLoading = true
      })
      .addCase(createCrossover.fulfilled, (state, action) => {
        state.crossoverLoading = false
        state.crossoverSuccess = true
        state.crossover = action.payload
      })
      .addCase(createCrossover.rejected, (state, action) => {
        state.crossoverLoading = false
        state.crossoverError = true
        state.message = action.payload
        state.crossover = null
      })
      .addCase(getCrossovers.pending, state => {
        state.crossoverLoading = true
      })
      .addCase(getCrossovers.fulfilled, (state, action) => {
        state.crossoverLoading = false
        state.crossoverSuccess = true
        state.crossovers = action.payload
      })
      .addCase(getCrossovers.rejected, (state, action) => {
        state.crossoverLoading = false
        state.crossoverError = true
        state.message = action.payload
      })
      .addCase(getCrossover.pending, state => {
        state.crossoverLoading = true
      })
      .addCase(getCrossover.fulfilled, (state, action) => {
        state.crossoverLoading = false
        state.crossoverSuccess = true
        state.crossover = action.payload
      })
      .addCase(getCrossover.rejected, (state, action) => {
        state.crossoverLoading = false
        state.crossoverError = true
        state.message = action.payload
      })
      .addCase(updateCrossover.pending, state => {
        state.crossoverLoading = true
      })
      .addCase(updateCrossover.fulfilled, (state, action) => {
        state.crossoverLoading = false
        state.crossoverSuccess = true
        state.crossover = action.payload
      })
      .addCase(updateCrossover.rejected, (state, action) => {
        state.crossoverLoading = false
        state.crossoverError = true
        state.message = action.payload
      })
      .addCase(deleteCrossover.fulfilled, state => {
        state.crossoverSuccess = true
      })
  },
})

export const { reset } = crossoverSlice.actions
export default crossoverSlice.reducer
