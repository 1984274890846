import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import earthService from './earthService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  earths: [],
  earth: {},
  earthError: false,
  earthSuccess: false,
  earthLoading: false,
  message: '',
}

// Create earth
export const createEarth = createAsyncThunk(
  'earths/create',
  async (earth, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await earthService.createEarth(earth, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get all earths
export const getEarths = createAsyncThunk(
  'earths/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await earthService.getEarths(token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get earth
export const getEarth = createAsyncThunk(
  'earths/get',
  async (earthId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await earthService.getEarth(earthId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Update earth
export const updateEarth = createAsyncThunk(
  'earths/update',
  async (earth, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await earthService.updateEarth(
        earth.earthId,
        earth.earthData,
        token
      )
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Delete earth
export const deleteEarth = createAsyncThunk(
  'earths/delete',
  async (earthId, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await earthService.deleteEarth(earthId, token)
  }
)

export const updateEarthStatus = createAsyncThunk(
  'earths/updateStatus',
  async ({ earthId, completed }, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    await earthService.updateEarthStatus(earthId, completed, token)
    return { earthId, completed }
  }
)

export const earthSlice = createSlice({
  name: 'earth',
  initialState,
  reducers: {
    reset: state => initialState,
    addNewEarthToState: (state, action) => {
      state.earths.push(action.payload) // Assuming action.payload contains new Earth data
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createEarth.pending, state => {
        state.earthLoading = true
      })
      .addCase(createEarth.fulfilled, (state, action) => {
        state.earthLoading = false
        state.earthSuccess = true
        earthSlice.caseReducers.addNewEarthToState(state, action)
        state.earth = action.payload
      })
      .addCase(createEarth.rejected, (state, action) => {
        state.earthLoading = false
        state.earthError = true
        state.message = action.payload
        state.earth = null
      })
      .addCase(getEarths.pending, state => {
        state.earthLoading = true
      })
      .addCase(getEarths.fulfilled, (state, action) => {
        state.earthLoading = false
        state.earthSuccess = true
        state.earths = action.payload
      })
      .addCase(getEarths.rejected, (state, action) => {
        state.earthLoading = false
        state.earthError = true
        state.message = action.payload
      })
      .addCase(getEarth.pending, state => {
        state.earthLoading = true
      })
      .addCase(getEarth.fulfilled, (state, action) => {
        state.earthLoading = false
        state.earthSuccess = true
        state.earth = action.payload
      })
      .addCase(getEarth.rejected, (state, action) => {
        state.earthLoading = false
        state.earthError = true
        state.message = action.payload
      })
      .addCase(updateEarth.pending, state => {
        state.earthLoading = true
      })
      .addCase(updateEarth.fulfilled, (state, action) => {
        state.earthLoading = false
        state.earthSuccess = true
        state.earth = action.payload
      })
      .addCase(updateEarth.rejected, (state, action) => {
        state.earthLoading = false
        state.earthError = true
        state.message = action.payload
      })
      .addCase(deleteEarth.pending, state => {
        state.earthSuccess = true
      })
      .addCase(deleteEarth.fulfilled, (state, action) => {
        const earthId = action.payload
        state.earths = state.earths.filter(earth => earth.id !== earthId)
      })
      .addCase(updateEarthStatus.fulfilled, (state, action) => {
        const { earthId, completed } = action.payload
        const earthToUpdate = state.earths.find(earth => earth.id === earthId)
        if (earthToUpdate) {
          earthToUpdate.completed = completed
          earthToUpdate.status = completed ? 'Finished' : 'Unfinished'
        }
      })
  },
})

export const { reset, addNewEarthToState } = earthSlice.actions
export default earthSlice.reducer
