import { useEffect, useState } from 'react'
import { createAuthor, getAuthors } from '../../features/authors/authorSlice'
import { getTags } from '../../features/tags/tagSlice'
import { getCountries } from '../../features/countries/countrySlice'
import { useDispatch, useSelector } from 'react-redux'
import CreatableSelect from 'react-select/creatable'
import { toast } from 'react-toastify'
import { FaUser, FaCalendarAlt, FaTags, FaPenNib, FaLink } from 'react-icons/fa'
import { MdLibraryAddCheck } from 'react-icons/md'
import ImageUploader from '../../components/ImageUploader'

const CreateAuthor = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [fullName, setFullName] = useState('')
  const [birthday, setBirthday] = useState()
  const [altNames, setAltNames] = useState([])
  const [nationality, setNationality] = useState([])
  const [countryId, setCountryId] = useState()
  const [tagIds, setTagIds] = useState([])

  const dispatch = useDispatch()

  const { authorError, message } = useSelector(state => state.authors)
  const { tags } = useSelector(state => state.tags)

  const { countries } = useSelector(state => state.countries)

  useEffect(() => {
    const getCountries = async () => {
      await setNationality(countries)
    }
    getCountries()
  }, [countries])

  useEffect(() => {
    dispatch(getTags())
    dispatch(getCountries())
    // eslint-disable-next-line
  }, [])

  const onSubmit = e => {
    e.preventDefault()

    const authorData = {
      firstName,
      lastName,
      fullName,
      birthday,
      altNames: altNames.map(({ value }) => value),
      countryId,
      tagIds: tagIds.map(({ id }) => id),
    }

    dispatch(createAuthor(JSON.stringify(authorData)))
    dispatch(getAuthors())

    if (authorError) {
      toast.error(message)
    } else {
      toast.success(`Author created!`)
    }
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className='detail-form grid-2'>
          <div className='detail-form__heading'>
            <div className='heading-form'>Create Author</div>
          </div>

          <div className='detail-form__user-info'>
            <p>
              <span className='required-mark'>&#10044;</span>Data marked with an
              asterisk are required, please fill them in before saving ...{' '}
            </p>
          </div>

          <div className='detail-form__button-box'>
            <button type='submit' className='btn btn-l' onSubmit={onSubmit}>
              <MdLibraryAddCheck className='button-icon' />
              Create
            </button>
          </div>

          <ImageUploader />

          <div className='detail-form__main container'>
            <div className='input-box'>
              <FaUser className='input__icon' />
              <div className='input-area'>
                <label htmlFor='name' className='form__label'>
                  First name
                </label>
                <input
                  type='text'
                  className='input'
                  id='name'
                  placeholder='Add First name'
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  autoComplete='off'
                  required
                />
              </div>
              <div className='required-mark-box'>
                <span className='required-mark'>&#10044;</span>
              </div>
            </div>
            <div className='input-box'>
              <FaUser className='input__icon' />
              <div className='input-area'>
                <label htmlFor='lastname' className='form__label'>
                  Last name
                </label>
                <input
                  type='text'
                  className='input'
                  id='lastname'
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  placeholder='Add Last name'
                  autoComplete='off'
                  required
                />
              </div>
              <div className='required-mark-box'>
                <span className='required-mark'>&#10044;</span>
              </div>
            </div>
            <div className='input-box'>
              <FaCalendarAlt className='input__icon' />
              <div className='input-area'>
                <label htmlFor='date' className='form__label'>
                  Birthdate
                </label>
                <input
                  type='date'
                  className='input'
                  id='date'
                  value={birthday}
                  onChange={e => setBirthday(e.target.value)}
                  placeholder='Date'
                  autoComplete='off'
                />
              </div>
            </div>
            <div className='input-box'>
              <FaUser className='input__icon' />
              <div className='input-area'>
                <label htmlFor='fullname' className='form__label'>
                  Full name
                </label>
                <input
                  type='text'
                  className='input'
                  id='fullname'
                  value={fullName}
                  onChange={e => setFullName(e.target.value)}
                  name='fullname'
                  placeholder='Full name'
                  autoComplete='off'
                />
              </div>
            </div>
            <div className='input-box-1'>
              <FaPenNib className='input__icon' />
              <div className='input-area-multi'>
                <label htmlFor='pseudo' className='form__label'>
                  Pseudonyms
                </label>
                <CreatableSelect
                  className='react-select-container'
                  classNamePrefix='react-select'
                  placeholder='Add pseudonyms'
                  id='pseudo'
                  isMulti
                  value={altNames}
                  onChange={e => setAltNames(e)}
                />
              </div>
            </div>

            <div className='input-box-1'>
              <FaTags className='input__icon' />
              <div className='input-area-multi'>
                <label htmlFor='tags' className='form__label'>
                  Tags
                </label>
                <CreatableSelect
                  className='react-select-container'
                  classNamePrefix='react-select'
                  placeholder='Add tags'
                  id='tags'
                  isMulti
                  options={tags}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  value={tagIds}
                  onChange={e => setTagIds(e)}
                />
              </div>
            </div>

            <div className='input-box'>
              <FaLink className='input__icon' />
              <div className='input-area'>
                <label htmlFor='country' className='form__label'>
                  Nationality
                </label>
                <div className='select-box'>
                  <select
                    className='input__select input__select-selected'
                    id='country'
                    name='country'
                    placeholder='Choose country'
                    autoComplete='off'
                    options={nationality}
                    value={countryId}
                    onChange={e => setCountryId(e.target.value)}
                  >
                    <option label='Select an option'></option>
                    {nationality.map(id => (
                      <option key={id.id} value={id.id}>
                        {id.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className='detail-form__description container'>
            <textarea
              rows='num'
              cols='num'
              type='text'
              className='input__textarea'
              name='description'
              placeholder='Input description..'
              autoComplete='off'
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default CreateAuthor
