import Dropdown from './Dropdown'
import { useState, useEffect, useRef } from 'react'

import { Link } from 'react-router-dom'


const MenuSubItems = ({ items }) => {
    const [dropdown, setDropdown] = useState(false);
    let ref = useRef()

    useEffect(() => {
        const handler = (event) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [dropdown]);

    const onMouseEnter = () => {
        window.innerWidth > 960 && setDropdown(true);
    };

    const onMouseLeave = () => {
        window.innerWidth > 960 && setDropdown(false);
    };


    // // Active states of MENU
    // const [active, setActive] = useState('1')

    // const handleClick = (e) => {
    //     setActive(e.target.id)
    // }

    return (
        <li
            ref={ref} onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave} title={items.description} >
            {items.submenu ? (
                <>
                    <button className='side-nav__btn' type="button" aria-haspopup="menu"
                        aria-expanded={dropdown ? "true" : "false"}
                        onClick={() => setDropdown((prev) => !prev)}>
                        {items.title}{' '}
                    </button>
                    <Dropdown submenus={items.submenu}
                        dropdown={dropdown} />
                </>
            ) : (
                <Link to={items.url}>
                    <button
                        className='side-nav__btn'> {items.title}
                    </button>
                </Link>
            )
            }
        </li >
    );
};

export default MenuSubItems