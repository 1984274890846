import { Link, useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTrashAlt } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import {
  getPublishings,
  deletePublishing,
} from '../../features/publishings/publishingSlice'

const PublishingsTableRow = ({ publishing, isChecked, onCheckboxChange }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Delete publishing
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deletePublishing(publishing.id))
      navigate('/publishings')
      dispatch(getPublishings())
    }
  }
  return (
    <>
      <div className='entity-table__row publishings-table-row'>
        <input
          type='checkbox'
          className='entity-check'
          id={publishing.id}
          checked={isChecked}
          onChange={() => onCheckboxChange(publishing.id)}
        />
        <label htmlFor={publishing.id}></label>
        <Link
          to={`/publishings/${publishing.id}`}
          className='entity-table__row__link'
        >
          {`${publishing.name}`}
        </Link>
        <div>Earth-616</div>
        <div className='asc'>42000</div>
        <div className='asc'>800</div>
        <div className='asc'>Unfinished</div>
        <label className='switch asc'>
          <input type='checkbox' />
          <span className='slider'></span>
        </label>
        <div>{new Date(publishing.updatedAt).toLocaleString('en-GB')}</div>
        <Popup
          trigger={
            <button className='user-nav__user-photo'>
              <BsThreeDotsVertical className='entity-table__settings-icon' />
            </button>
          }
          position='bottom right'
        >
          <button
            className='btn-inner btn-inner__table'
            onClick={deleteHandler}
          >
            <FaTrashAlt /> &nbsp; Delete
          </button>
        </Popup>
      </div>
    </>
  )
}
export default PublishingsTableRow
