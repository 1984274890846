import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import storyService from './storyService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  stories: [],
  story: {},
  storyError: false,
  storySuccess: false,
  storyLoading: false,
  message: '',
}

// Create story
export const createStory = createAsyncThunk(
  'stories/create',
  async (story, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await storyService.createStory(story, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get all stories
export const getStories = createAsyncThunk(
  'stories/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await storyService.getStories(token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get story
export const getStory = createAsyncThunk(
  'stories/get',
  async (storyId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await storyService.getStory(storyId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Update story
export const updateStory = createAsyncThunk(
  'stories/update',
  async (story, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await storyService.updateStory(
        story.storyId,
        story.storyData,
        token
      )
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Delete story
export const deleteStory = createAsyncThunk(
  'stories/delete',
  async (storyId, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await storyService.deleteStory(storyId, token)
  }
)

export const storySlice = createSlice({
  name: 'story',
  initialState,
  reducers: {
    reset: state => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(createStory.pending, state => {
        state.storyLoading = true
      })
      .addCase(createStory.fulfilled, (state, action) => {
        state.storyLoading = false
        state.storySuccess = true
        state.story = action.payload
      })
      .addCase(createStory.rejected, (state, action) => {
        state.storyLoading = false
        state.storyError = true
        state.message = action.payload
        state.story = null
      })
      .addCase(getStories.pending, state => {
        state.storyLoading = true
      })
      .addCase(getStories.fulfilled, (state, action) => {
        state.storyLoading = false
        state.storySuccess = true
        state.stories = action.payload
      })
      .addCase(getStories.rejected, (state, action) => {
        state.storyLoading = false
        state.storyError = true
        state.message = action.payload
      })
      .addCase(getStory.pending, state => {
        state.storyLoading = true
      })
      .addCase(getStory.fulfilled, (state, action) => {
        state.storyLoading = false
        state.storySuccess = true
        state.story = action.payload
      })
      .addCase(getStory.rejected, (state, action) => {
        state.storyLoading = false
        state.storyError = true
        state.message = action.payload
      })
      .addCase(updateStory.pending, state => {
        state.storyLoading = true
      })
      .addCase(updateStory.fulfilled, (state, action) => {
        state.storyLoading = false
        state.storySuccess = true
        state.story = action.payload
      })
      .addCase(updateStory.rejected, (state, action) => {
        state.storyLoading = false
        state.storyError = true
        state.message = action.payload
      })
      .addCase(deleteStory.fulfilled, state => {
        state.storySuccess = true
      })
  },
})

export const { reset } = storySlice.actions
export default storySlice.reducer
