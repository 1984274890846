import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import aliasService from './aliasService';
import { handleCatchBlock } from '../utils/slices';

const initialState = {
  aliases: [],
  alias: {},
  aliasError: false,
  aliasSuccess: false,
  aliasLoading: false,
  message: '',
};

// Create alias
export const createAlias = createAsyncThunk(
  'aliases/create',
  async (alias, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken;
      return await aliasService.createAlias(alias, token);
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true);
    }
  }
);

// Get all aliases
export const getAliases = createAsyncThunk(
  'aliases/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken;
      return await aliasService.getAliases(token);
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true);
    }
  }
);

// Get alias
export const getAlias = createAsyncThunk(
  'aliases/get',
  async (aliasId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken;
      return await aliasService.getAlias(aliasId, token);
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true);
    }
  }
);

// Update alias
export const updateAlias = createAsyncThunk(
  'aliases/update',
  async (alias, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken;
      return await aliasService.updateAlias(
        alias.aliasId,
        alias.aliasData,
        token
      );
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true);
    }
  }
);

// Delete alias
export const deleteAlias = createAsyncThunk(
  'aliases/delete',
  async (aliasId, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken;
    return await aliasService.deleteAlias(aliasId, token);
  }
);

export const aliasSlice = createSlice({
  name: 'alias',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAlias.pending, (state) => {
        state.aliasLoading = true;
      })
      .addCase(createAlias.fulfilled, (state, action) => {
        state.aliasLoading = false;
        state.aliasSuccess = true;
        state.alias = action.payload;
      })
      .addCase(createAlias.rejected, (state, action) => {
        state.aliasLoading = false;
        state.aliasError = true;
        state.message = action.payload;
        state.alias = null;
      })
      .addCase(getAliases.pending, (state) => {
        state.aliasLoading = true;
      })
      .addCase(getAliases.fulfilled, (state, action) => {
        state.aliasLoading = false;
        state.aliasSuccess = true;
        state.aliases = action.payload;
      })
      .addCase(getAliases.rejected, (state, action) => {
        state.aliasLoading = false;
        state.aliasError = true;
        state.message = action.payload;
      })
      .addCase(getAlias.pending, (state) => {
        state.aliasLoading = true;
      })
      .addCase(getAlias.fulfilled, (state, action) => {
        state.aliasLoading = false;
        state.aliasSuccess = true;
        state.alias = action.payload;
      })
      .addCase(getAlias.rejected, (state, action) => {
        state.aliasLoading = false;
        state.aliasError = true;
        state.message = action.payload;
      })
      .addCase(updateAlias.pending, (state) => {
        state.aliasLoading = true;
      })
      .addCase(updateAlias.fulfilled, (state, action) => {
        state.aliasLoading = false;
        state.aliasSuccess = true;
        state.alias = action.payload;
      })
      .addCase(updateAlias.rejected, (state, action) => {
        state.aliasLoading = false;
        state.aliasError = true;
        state.message = action.payload;
      })
      .addCase(deleteAlias.fulfilled, (state) => {
        state.aliasSuccess = true;
      });
  },
});

export const { reset } = aliasSlice.actions;
export default aliasSlice.reducer;
