import axios from 'axios'

const backendURL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${backendURL}/api/v1/book-series/`

// Create book series
const createBookSerie = async (bookSerie, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.post(API_URL, bookSerie, config)
  return response.data
}

// Get all book series
const getBookSeries = async token => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.get(API_URL, config)
  return response.data
}

// Get book serie
const getBookSerie = async (bookSerieId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + bookSerieId, config)
  return response.data
}

// Update book series
const updateBookSerie = async (bookSerieId, bookSerieData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.put(API_URL + bookSerieId, bookSerieData, config)

  return response.data
}

// Delete book series
const deleteBookSerie = async (bookSerieId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + bookSerieId, config)
  return response.data
}

const bookSerieService = {
  createBookSerie,
  getBookSeries,
  getBookSerie,
  updateBookSerie,
  deleteBookSerie,
}

export default bookSerieService
