import Navigation from '../layout/Navigation';
import Topbar from '../layout/Topbar';
import Spinner from '../components/Spinner';
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg';
import { FaTags } from 'react-icons/fa';
import { HiSave, HiOfficeBuilding, HiXCircle } from 'react-icons/hi';
import { BiSolidPlusCircle } from 'react-icons/bi';
import { ImEarth, ImBooks } from 'react-icons/im';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import {
  getBookSerie,
  deleteBookSerie,
  getBookSeries,
  updateBookSerie,
} from '../features/bookSeries/bookSerieSlice';
import {
  getTags,
  createTag,
  addNewTagToState,
} from '../features/tags/tagSlice';
import {
  getPublishings,
  getPublishing,
} from '../features/publishings/publishingSlice';
import { addedBy, updatedBy } from '../features/users/userSlice';
import CreatableSelect from 'react-select/creatable';
import { format } from 'date-fns';
import Select from 'react-select';
import Breadcrumbs from '../layout/Breadcrumbs';

const BookSerieDetail = () => {
  const [name, setName] = useState('');
  const [tagIds, setTagIds] = useState([]);
  const [publishingIds, setPublishingIds] = useState([]);
  const [description, setDescription] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const [books, setBooks] = useState(['']);

  const updateBooks = (index, value) => {
    const newBooks = [...books];
    newBooks[index] = value;
    setBooks(newBooks);
  };
  const addBookConnection = () => {
    setBooks([...books, '']);
  };
  const removeBook = (index) => {
    const newBooks = [...books];
    newBooks.splice(index, 1); // Odstranění řádku s daným indexem
    setBooks(newBooks);
  };

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Fetch book serie to Redux by URL params
  const { bookSerieId } = params;
  const { bookSerie, bookSerieLoading, bookSerieError, message } = useSelector(
    (state) => state.bookSeries
  );
  const { tags } = useSelector((state) => state.tags);

  const { publishings } = useSelector((state) => state.publishings);

  useEffect(() => {
    if (bookSerieError) {
      toast.error(message);
    }

    dispatch(getBookSerie(bookSerieId));
    // eslint-disable-next-line
  }, [bookSerieError, message, bookSerieId]);

  const selectRef = useRef(null); // Create a ref for CreatableSelect

  useEffect(() => {
    dispatch(getTags());
    dispatch(getPublishings());
    // eslint-disable-next-line
  }, []);

  // Set default form data from bookSeries reducer
  useEffect(() => {
    setName(bookSerie.name && bookSerie.name);
    setDescription(!bookSerie.description ? '' : bookSerie.description);
    setTagIds(bookSerie.tags?.map((tag) => ({ id: tag.id, name: tag.name })));
    setPublishingIds(bookSerie.publishingIds);
    dispatch(addedBy(bookSerie.addedBy));
    dispatch(updatedBy(bookSerie.lastUpdatedBy));
  }, [dispatch, bookSerie, format]);

  // CREATE NEW TAG AND ASSIGNE IT TO PUBLISHING
  const handleCreateTag = async (inputValue) => {
    try {
      const { payload: createdTag } = await dispatch(
        createTag({ name: inputValue })
      );
      if (createdTag && createdTag.id) {
        dispatch(addNewTagToState(createdTag));
        setTagIds((prevTagIds) => [
          ...prevTagIds,
          { id: createdTag.id, name: createdTag.name },
        ]);
      } else {
        console.error('Failed to create Tag or no Tag ID returned.');
      }
    } catch (error) {
      console.error('Error creating Tag:', error);
    }
  };

  // Update book serie
  const onSubmit = (e) => {
    e.preventDefault();

    const bookSerieData = {
      name,
      tagIds: tagIds.map(({ id }) => id),
      description,
    };

    dispatch(updateBookSerie({ bookSerieId, bookSerieData }));

    if (bookSerieError) {
      toast.error(message);
    } else {
      toast.success('Book Serie successfully updated!');
    }
  };

  // Delete publishing
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteBookSerie(bookSerieId));
      navigate('/book-series');
      dispatch(getBookSeries());
    }
  };

  const creator = useSelector((state) => state.users.addedBy);
  const updator = useSelector((state) => state.users.updatedBy);

  const breadcrumbsPath = ['book series', `${bookSerie.name}`];

  if (bookSerieLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <Breadcrumbs path={breadcrumbsPath} />
          <form onSubmit={onSubmit}>
            <div className='detail-form grid-3'>
              <div className='detail-form__heading'>
                <div className='heading-form'>
                  <ImBooks className='detail-form__icon' /> {bookSerie.name}{' '}
                </div>
                <div className='entity-info bold'>
                  <p className='entity-info__id'>ID: {bookSerie.id}</p>
                  <div className='entity-info__slug mar-left-xs'>
                    {bookSerie.slug}
                  </div>
                </div>
              </div>

              <div className='detail-form__user-info'>
                <p className='pad-bottom-xs mar-left-s'>
                  Created:{' '}
                  <span className='bold'>
                    {new Date(bookSerie.createdAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${creator.name} ${creator.surname}`}</span>
                </p>
                <p className='mar-left-s'>
                  Updated:{' '}
                  <span className='bold'>
                    {new Date(bookSerie.updatedAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${updator.name} ${updator.surname}`}</span>
                </p>
              </div>

              <div className='detail-form__button-box'>
                <button type='submit' className='btn-new btn-save mar-right-xs'>
                  <HiSave className='button-icon-new' />
                </button>
                <button
                  type='button'
                  className='btn-new btn-delete'
                  onClick={deleteHandler}
                >
                  <MdDelete className='button-icon-new' />
                </button>
              </div>

              <div className='detail-form__main-2 container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  Basic Info
                </label>
                <div className='input-box'>
                  <ImBooks className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='name' className='input__label'>
                      Book serie title
                    </label>
                    <input
                      type='text'
                      className='input'
                      id='name'
                      placeholder='Earth Name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete='off'
                      required
                    />
                  </div>
                  <div className='required-mark-box'>
                    <span className='required-mark'>&#10044;</span>
                  </div>
                </div>

                <div className='input-box-1'>
                  <FaTags className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='pseudo' className='input__label'>
                      Tags
                    </label>
                    <CreatableSelect
                      className='react-select-container'
                      classNamePrefix='react-select'
                      placeholder='Add tags'
                      isMulti
                      options={tags}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      value={tagIds}
                      onCreateOption={handleCreateTag} // Handle tag creation
                      onChange={(e) => setTagIds(e)}
                      isValidNewOption={(inputValue) => inputValue}
                      getNewOptionData={(inputValue) => ({
                        value: inputValue,
                        name: `Create ${inputValue}`,
                      })}
                      menuPortalTarget={document.body} // Set menuPortalTarget to document.body
                      ref={selectRef} // Attach the ref to CreatableSelect
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <HiOfficeBuilding className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='publishing' className='input__label'>
                      Publishing
                    </label>
                    <Select
                      className='react-single-select'
                      classNamePrefix='react-single-select'
                      placeholder='Add Publishing'
                      options={publishings}
                      // value={publishingId}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      // onChange={setPublishingId}
                      // menuPortalTarget={document.body} // Set menuPortalTarget to document.body
                      // ref={selectRef} // Attach the ref to CreatableSelect
                    />
                  </div>
                </div>
              </div>

              <div className='detail-form__connect-box container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  connected books
                </label>
                {!books?.length ? (
                  <p className='input-label-info'>
                    {' '}
                    No books added to this entity yet.
                  </p>
                ) : (
                  <div className='flex-row-box'>
                    {books.map((book, index) => (
                      <div className='flex-row' key={index}>
                        <div className='select-cont-flex'>
                          <div className='connect-num'>{index + 1}</div>

                          <Select
                            className='single-select-number-disabled'
                            classNamePrefix='single-select-number-disabled'
                            placeholder='Connected Book'
                            autoComplete='off'
                            id={`book${index}`}
                            value={book}
                            onChange={(e) => updateBooks(index, e.target.value)}
                          />
                        </div>

                        <div className='delete-btn-box mar-left-xs'>
                          <HiXCircle
                            className='btn-icon-2'
                            onClick={() => removeBook(index)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  type='button'
                  className='add-issue-btn add-issue-btn-blue'
                  onClick={addBookConnection}
                >
                  <BiSolidPlusCircle className='add-issue-btn-icon' />
                  add book
                </button>
              </div>

              <div className='detail-form__description-4 container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  description
                </label>
                <EditorProvider>
                  <Editor
                    className='rsw-editor'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BookSerieDetail;
