import axios from 'axios';

const backendURL = process.env.REACT_APP_BACKEND_URL;
const API_URL = `${backendURL}/api/v1/character-aliases/`;

// Create alias
const createAlias = async (alias, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.post(API_URL, alias, config);
  return response.data;
};

// Get all aliases
const getAliases = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.get(API_URL, config);
  return response.data;
};

// Get alias
const getAlias = async (aliasId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + aliasId, config);
  return response.data;
};

// Update alias
const updateAlias = async (aliasId, aliasData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.put(API_URL + aliasId, aliasData, config);

  return response.data;
};

// Delete alias
const deleteAlias = async (aliasId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + aliasId, config);
  return response.data;
};

const aliasService = {
  createAlias,
  getAliases,
  getAlias,
  updateAlias,
  deleteAlias,
};

export default aliasService;
