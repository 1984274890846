import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const Breadcrumbs = ({ path }) => {
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter(x => x)

  const breadcrumbs = pathnames.map((part, index) => {
    const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`
    const isLast = index === pathnames.length - 1
    const isId = /^\d+$/.test(part)

    const displayName = isId ? path[index] || part : part

    return (
      <span key={part}>
        <Link to={routeTo}>{displayName}</Link>
        {!isLast && <span className='breadcrumbs-arrow'> {'>'} </span>}
      </span>
    )
  })

  return <div className='breadcrumbs'>{breadcrumbs}</div>
}

export default Breadcrumbs
