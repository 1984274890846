import axios from 'axios'

const backendURL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${backendURL}/api/v1/series/`
const LIST_URL = `${backendURL}/api/v1/series`

// Create
const createSerie = async (serie, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.post(API_URL, serie, config)
  return response.data
}

// Get all
const getSeries = async token => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.get(`${LIST_URL}?order=id`, config)
  return response.data
}

// Get one
const getSerie = async (serieId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + serieId, config)
  return response.data
}

// Update
const updateSerie = async (serieId, serieData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.put(API_URL + serieId, serieData, config)

  return response.data
}

// Delete
const deleteSerie = async (serieId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + serieId, config)
  return response.data
}

const serieService = {
  createSerie,
  getSeries,
  getSerie,
  updateSerie,
  deleteSerie,
}

export default serieService
