import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUsers, reset } from '../features/users/userSlice'
import Spinner from '../components/Spinner'
import Navigation from '../layout/Navigation'
import Topbar from '../layout/Topbar'
import UserCart from '../components/UserCart'

const Users = () => {
  const { users, userLoading, userSuccess } = useSelector(state => state.users)

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      if (userSuccess) {
        dispatch(reset())
      }
    }
  }, [dispatch, userSuccess])

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  if (userLoading) {
    return <Spinner />
  }
  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <div className='users'>
            {users.map(user => (
              <UserCart key={user._id} user={user} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Users
