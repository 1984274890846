import Navigation from '../layout/Navigation'
import Topbar from '../layout/Topbar'
import Spinner from '../components/Spinner'
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg'
import Select from 'react-select'
import { HiXCircle, HiOfficeBuilding } from 'react-icons/hi'
import { FaTags, FaBook } from 'react-icons/fa'
import { HiSave } from 'react-icons/hi'
import { MdDelete } from 'react-icons/md'
import { BiSolidPlusCircle } from 'react-icons/bi'
import { toast } from 'react-toastify'
import CreatableSelect from 'react-select/creatable'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import {
  getBook,
  deleteBook,
  getBooks,
  updateBook,
} from '../features/books/bookSlice'
import { getTags, createTag, addNewTagToState } from '../features/tags/tagSlice'
import { addedBy, updatedBy } from '../features/users/userSlice'
import { uploadImage } from '../features/images/imageSlice'
import ImageUploader from '../components/ImageUploader'
import { getPublishings } from '../features/publishings/publishingSlice'
import BookFormats from '../components/entityComponents/BookFormats'
import AuthorTeam from '../components/entityComponents/AuthorTeam'
import AssignedCharacters from '../components/entityComponents/AssignedCharacters'
import Breadcrumbs from '../layout/Breadcrumbs'

const BookDetail = () => {
  const [name, setName] = useState('')
  const [tagIds, setTagIds] = useState([])
  const [publishingId, setPublishingId] = useState('')
  const [description, setDescription] = useState('')
  const [imageId, setImageId] = useState()
  const [imageURL, setImageURL] = useState('')

  // const [searchTerm, setSearchTerm] = useState('');
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // ASIGN ISSUES COMPONENT

  const [issues, setIssues] = useState([''])

  const updateIssues = (index, value) => {
    const newIssues = [...issues]
    newIssues[index] = value
    setIssues(newIssues)
  }
  const addIssueConnection = () => {
    setIssues([...issues, ''])
  }
  const removeIssue = index => {
    const newIssues = [...issues]
    newIssues.splice(index, 1) // Odstranění řádku s daným indexem
    setIssues(newIssues)
  }

  // const onChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  // Fetch Book to Redux by URL params
  const { bookId } = params
  const { book, bookLoading, bookError, message } = useSelector(
    state => state.books
  )
  const { tags } = useSelector(state => state.tags)
  const { publishings } = useSelector(state => state.publishings)
  const selectRef = useRef(null) // Create a ref for CreatableSelect

  // IS OGN CONDITION
  const [isOGN, setIsOGN] = useState(false)
  const handleIsOGNChange = () => {
    setIsOGN(!isOGN)
  }

  useEffect(() => {
    // Focus the input of CreatableSelect when the component mounts
    if (selectRef.current) {
      selectRef.current.focus()
    }
  }, [])

  useEffect(() => {
    if (bookError) {
      toast.error(message)
    }

    dispatch(getBook(bookId))
    // eslint-disable-next-line
  }, [bookError, message, bookId, dispatch])

  useEffect(() => {
    dispatch(getTags())
    dispatch(getPublishings)
    // eslint-disable-next-line
  }, [dispatch])

  // Set default form data from books reducer
  useEffect(() => {
    setName(book.name && book.name)
    setDescription(!book.description ? '' : book.description)
    setPublishingId(
      !book.publishingId
        ? ''
        : publishings.find(publishing => publishing.id === book.publishingId)
    )
    setTagIds(book.tags?.map(tag => ({ id: tag.id, name: tag.name })))
    setImageURL(!book.imageUrl ? '' : book.imageUrl)
    dispatch(addedBy(book.addedBy))
    dispatch(updatedBy(book.lastUpdatedBy))
  }, [dispatch, book, publishings])

  // CREATE NEW TAG AND ASSIGNE IT TO BOOK
  const handleCreateTag = async inputValue => {
    try {
      const { payload: createdTag } = await dispatch(
        createTag({ name: inputValue })
      )
      if (createdTag && createdTag.id) {
        dispatch(addNewTagToState(createdTag))
        setTagIds(prevTagIds => [
          ...prevTagIds,
          { id: createdTag.id, name: createdTag.name },
        ])
      } else {
        console.error('Failed to create Tag or no Tag ID returned.')
      }
    } catch (error) {
      console.error('Error creating Tag:', error)
    }
  }

  // UPLOAD IMAGE
  const handleImageUpload = async file => {
    try {
      const response = await dispatch(uploadImage(file))
      const uploadedImage = response.payload[0]

      if (uploadedImage) {
        setImageId(uploadedImage.id)
        toast.success('Image uploaded successfully!')
      } else {
        console.error('Failed to create image or no ID returned.')
      }
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const handleImageChange = () => {
    setImageURL('')
  }

  // Update book
  const onSubmit = e => {
    e.preventDefault()

    const bookData = {
      name,
      tagIds: tagIds.map(({ id }) => id),
      description,
      publishingId: publishingId.id,
      imageId,
    }

    dispatch(updateBook({ bookId, bookData }))

    if (bookError) {
      toast.error(message)
    } else {
      toast.success('Book successfully updated!')
    }
  }

  // Delete book
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteBook(bookId))
      navigate('/book')
      dispatch(getBooks())
    }
  }

  const creator = useSelector(state => state.users.addedBy)
  const updator = useSelector(state => state.users.updatedBy)

  const breadcrumbsPath = ['books', `${book.name}`]

  if (bookLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <Breadcrumbs path={breadcrumbsPath} />
          <form onSubmit={onSubmit}>
            <div className='detail-form grid-1'>
              <div className='detail-form__heading'>
                <div className='heading-form'>
                  <FaBook className='detail-form__icon' /> {book.name}{' '}
                </div>
                <div className='entity-info bold'>
                  <p className='entity-info__id'>ID: {book.id}</p>
                  <div className='entity-info__slug mar-left-xs'>
                    {' '}
                    {book.slug}
                  </div>
                </div>
              </div>
              <div className='detail-form__user-info'>
                <p className='pad-bottom-xs mar-left-s'>
                  Created:{' '}
                  <span className='bold'>
                    {new Date(book.createdAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${creator.name} ${creator.surname}`}</span>
                </p>
                <p className='mar-left-s'>
                  Updated:{' '}
                  <span className='bold'>
                    {new Date(book.updatedAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by{' '}
                  <span className='bold'>{`${updator.name} ${updator.surname}`}</span>
                </p>
              </div>

              <div className='detail-form__button-box'>
                <button type='submit' className='btn-new btn-save mar-right-xs'>
                  <HiSave className='button-icon-new' />
                </button>
                <button
                  type='button'
                  className='btn-new btn-delete'
                  onClick={deleteHandler}
                >
                  <MdDelete className='button-icon-new' />
                </button>
              </div>

              {!imageURL ? (
                <ImageUploader onImageUpload={handleImageUpload} />
              ) : (
                <>
                  <div className='image-upload container'>
                    <label htmlFor='basicInfo' className='entity-label'>
                      Image
                    </label>
                    <img
                      src={imageURL}
                      alt='Main preview'
                      className='img-entity'
                    />
                    <HiXCircle
                      className='btn-img-del'
                      onClick={handleImageChange}
                    />
                  </div>
                </>
              )}

              <div className='detail-form__main container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  Basic Info
                </label>
                <div className='input-box'>
                  <FaBook className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='name' className='input__label'>
                      Book Name
                    </label>
                    <input
                      type='text'
                      className='input'
                      id='name'
                      placeholder='Book Name'
                      value={name}
                      onChange={e => setName(e.target.value)}
                      autoComplete='off'
                      required
                    />
                  </div>
                  <div className='required-mark-box'>
                    <span className='required-mark'>&#10044;</span>
                  </div>
                </div>

                <div className='input-box-2'>
                  <FaTags className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='tags' className='input__label'>
                      Tags
                    </label>
                    <CreatableSelect
                      className='react-select-container'
                      classNamePrefix='react-select'
                      placeholder='Add tags'
                      isMulti
                      options={tags}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      value={tagIds}
                      onCreateOption={handleCreateTag} // Handle tag creation
                      onChange={e => setTagIds(e)}
                      isValidNewOption={inputValue => inputValue}
                      getNewOptionData={inputValue => ({
                        value: inputValue,
                        name: `Create ${inputValue}`,
                      })}
                      menuPortalTarget={document.body} // Set menuPortalTarget to document.body
                      ref={selectRef} // Attach the ref to CreatableSelect
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <HiOfficeBuilding className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='publishing' className='input__label'>
                      Publishing
                    </label>
                    <Select
                      className='react-single-select'
                      classNamePrefix='react-single-select'
                      placeholder='Add Publishing'
                      options={publishings}
                      value={publishingId}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      onChange={setPublishingId}
                      menuPortalTarget={document.body} // Set menuPortalTarget to document.body
                      ref={selectRef} // Attach the ref to CreatableSelect
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <FaTags className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='tags' className='input__label'>
                      Events
                    </label>
                    <CreatableSelect
                      className='react-select-container'
                      classNamePrefix='react-select'
                      placeholder='Add tags'
                      isMulti
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <FaBook className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='publishing' className='input__label'>
                      Language
                    </label>
                    <Select
                      className='react-single-select'
                      classNamePrefix='react-single-select'
                      placeholder='Select Language'
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <FaBook className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='publishing' className='input__label'>
                      Translation Of
                    </label>
                    <Select
                      className='react-single-select'
                      classNamePrefix='react-single-select'
                      placeholder='Select Book'
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <div className='checkbox-box mar-left-xs'>
                    <input
                      type='checkbox'
                      className='entity-check'
                      id='ch-imprint'
                      checked={isOGN}
                      onChange={handleIsOGNChange}
                    />
                    <label htmlFor='ch-imprint'></label>
                    <label className='checkbox-label'>
                      Is OGN (Original graphic novel)
                    </label>
                  </div>
                </div>
              </div>

              <BookFormats />

              {/* ASSIGN CONNECTED ISSUES */}
              {!isOGN && (
                <div
                  className='detail-form__connect-box container'
                  id='assigned-issues'
                >
                  <label htmlFor='assigned-issues' className='entity-label'>
                    assigned issues
                  </label>
                  {!issues?.length ? (
                    <p className='input-label-info'>
                      {' '}
                      No issues assigned to this entity yet.
                    </p>
                  ) : (
                    <div className='flex-row-box'>
                      {issues.map((issue, index) => (
                        <div className='flex-row' key={index}>
                          <div className='select-cont-flex'>
                            <div className='connect-num'>{index + 1}</div>

                            <Select
                              className='single-select-number-disabled'
                              classNamePrefix='single-select-number-disabled'
                              placeholder='Assigned Issue'
                              autoComplete='off'
                              id={`issue${index}`}
                              value={issue}
                              onChange={e =>
                                updateIssues(index, e.target.value)
                              }
                            />
                          </div>

                          <div className='delete-btn-box mar-left-xs'>
                            <HiXCircle
                              className='btn-icon-2'
                              onClick={() => removeIssue(index)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <button
                    type='button'
                    className='add-issue-btn add-issue-btn-blue'
                    onClick={addIssueConnection}
                  >
                    <BiSolidPlusCircle className='add-issue-btn-icon' />
                    asign issue
                  </button>
                </div>
              )}
              {/* AUTHOR TEAM IF IS OGN */}
              {isOGN && <AuthorTeam />}

              {/* DESCRIPTION OF BOOK */}
              <div
                className={
                  !handleIsOGNChange
                    ? 'detail-form__description-4 container'
                    : 'detail-form__description-3 container'
                }
              >
                <label htmlFor='basicInfo' className='entity-label'>
                  Description
                </label>
                <EditorProvider>
                  <Editor
                    className='rsw-editor'
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
              </div>
              {/* ASSIGNED CHARACTERS TO THIS BOOK */}
              {isOGN && <AssignedCharacters />}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default BookDetail
