import axios from 'axios'

const backendURL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${backendURL}/api/v1/books/`

// Create book
const createBook = async (book, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.post(API_URL, book, config)
  return response.data
}

// Get all books
const getBooks = async token => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.get(API_URL, config)
  return response.data
}

// Get book
const getBook = async (bookId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + bookId, config)
  return response.data
}

// Update book
const updateBook = async (bookId, bookData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.put(API_URL + bookId, bookData, config)

  return response.data
}

// Delete book
const deleteBook = async (bookId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + bookId, config)
  return response.data
}

const bookService = {
  createBook,
  getBooks,
  getBook,
  updateBook,
  deleteBook,
}

export default bookService
