import { useEffect, useState } from 'react'
import {
  createCharacter,
  getCharacters,
} from '../../features/characters/characterSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getPublishings } from '../../features/publishings/publishingSlice'
import { toast } from 'react-toastify'
import {
  FaUser,
  FaTags,
  FaPenNib,
  FaCloudUploadAlt,
  FaPlus,
} from 'react-icons/fa'
import { MdLibraryAddCheck } from 'react-icons/md'
import { HiOfficeBuilding } from 'react-icons/hi'
import { ImEarth } from 'react-icons/im'
import CreatableSelect from 'react-select/creatable'
import ImageUploader from '../../components/ImageUploader'

const CreateCharacter = () => {
  const [name, setName] = useState('')
  const [publishingIds, setPublishingIds] = useState([])

  const dispatch = useDispatch()

  const { characterError, message } = useSelector(state => state.characters)
  const { publishings } = useSelector(state => state.publishings)

  useEffect(() => {
    dispatch(getCharacters())
    dispatch(getPublishings())
    // eslint-disable-next-line
  }, [])

  const onSubmit = e => {
    e.preventDefault()

    const characterData = {
      name,
      publishingIds: publishingIds.map(({ id }) => id),
    }

    dispatch(createCharacter(JSON.stringify(characterData)))
    dispatch(getCharacters())

    if (characterError) {
      toast.error(message)
    } else {
      toast.success(`Character created!`)
    }
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className='detail-form grid-1'>
          <div className='detail-form__heading'>
            <div className='heading-form'>Create Character</div>
          </div>

          <div className='detail-form__user-info'>
            <p>
              <span className='required-mark'>&#10044;</span>Data marked with an
              asterisk are required, please fill them in before saving ...{' '}
            </p>
          </div>

          <div className='detail-form__button-box'>
            <button type='submit' className='btn btn-l' onSubmit={onSubmit}>
              <MdLibraryAddCheck className='button-icon' />
              Create
            </button>
          </div>

          <ImageUploader />

          <div className='detail-form__main container'>
            <div className='input-box'>
              <FaUser className='input__icon' />
              <div className='input-area'>
                <label htmlFor='name' className='form__label'>
                  Name
                </label>
                <input
                  type='text'
                  className='input'
                  id='name'
                  placeholder='Add Name'
                  autoComplete='off'
                  required
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </div>
              <div className='required-mark-box'>
                <span className='required-mark'>&#10044;</span>
              </div>
            </div>
            <div className='input-box'>
              <FaUser className='input__icon' />
              <div className='input-area'>
                <label htmlFor='civilName' className='form__label'>
                  Civil Name
                </label>
                <input
                  type='text'
                  className='input'
                  id='civilName'
                  placeholder='Add Civil Name'
                  autoComplete='off'
                />
              </div>
            </div>

            <div className='input-box'>
              <FaPenNib className='input__icon' />
              <div className='input-area-multi'>
                <label htmlFor='publishings' className='form__label'>
                  Publishings
                </label>
                <CreatableSelect
                  className='react-select-container'
                  classNamePrefix='react-select'
                  placeholder='Add Publishings'
                  id='publishings'
                  isMulti
                />
              </div>
            </div>

            <div className='input-box-1'>
              <FaPenNib className='input__icon' />
              <div className='input-area-multi'>
                <label htmlFor='pseudo' className='form__label'>
                  Alt Names
                </label>
                <CreatableSelect
                  className='react-select-container'
                  classNamePrefix='react-select'
                  placeholder='Add Alt Names'
                  isMulti
                />
              </div>
            </div>

            <div className='input-box-1'>
              <FaPenNib className='input__icon' />
              <div className='input-area-multi'>
                <label htmlFor='earths' className='form__label'>
                  Earths
                </label>
                <CreatableSelect
                  className='react-select-container'
                  classNamePrefix='react-select'
                  placeholder='Add Earths'
                  id='earths'
                  isMulti
                />
              </div>
            </div>

            <div className='input-box'>
              <FaPenNib className='input__icon' />
              <div className='input-area-multi'>
                <label htmlFor='authors' className='form__label'>
                  Authors
                </label>
                <CreatableSelect
                  className='react-select-container'
                  classNamePrefix='react-select'
                  placeholder='Add Authors'
                  id='authors'
                  isMulti
                />
              </div>
            </div>

            <div className='input-box'>
              <FaTags className='input__icon' />
              <div className='input-area-multi'>
                <label htmlFor='tags' className='form__label'>
                  Tags
                </label>
                <CreatableSelect
                  className='react-select-container'
                  classNamePrefix='react-select'
                  placeholder='Add tags'
                  id='tags'
                  isMulti
                />
              </div>
            </div>
          </div>

          {/* <div className='img-upload container'>

                        <>
                            <input
                                className='img-uploaded'
                                id='upload-img'
                                type='file'
                                // onChange={handleChange}
                                accept='image/*,.png,.jpeg'
                            />

                            <label
                                className={'upload-label'}
                                htmlFor='upload-img'
                            >

                                <FaCloudUploadAlt className='img-upload__icon' />
                                <p className='img-upload__text'>Upload Picture</p>

                            </label>
                        </>



                        <div>
                            <FaUser className='input__icon' />
                            <div className='input-area'>
                                <label htmlFor='civilName' className='form__label'>
                                    Alias
                                </label>
                                <input
                                    type='text'
                                    className='input'
                                    id='civilName'
                                    placeholder='Alias'
                                    autoComplete='off'
                                />
                            </div>

                        </div>

                    </div>

                    <button
                        type='button'
                        className='btn btn-l'
                    >
                        <FaPlus className='button-box__icon' />
                        Add Alias
                    </button> */}

          <div className='detail-form__description container'>
            <textarea
              rows='num'
              cols='num'
              type='text'
              className='input__textarea'
              name='description'
              placeholder='Input description..'
              autoComplete='off'
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default CreateCharacter
