import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import tagService from './tagService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  tags: [],
  tag: {},
  tagError: false,
  tagSuccess: false,
  tagLoading: false,
  message: '',
}

// Create tag
export const createTag = createAsyncThunk(
  'tags/create',
  async (tag, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await tagService.createTag(tag, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get all tags
export const getTags = createAsyncThunk('tags/getAll', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await tagService.getTags(token)
  } catch (error) {
    return handleCatchBlock(error, thunkAPI, true)
  }
})

// Get tag
export const getTag = createAsyncThunk('tags/get', async (tagId, thunkAPI) => {
  try {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await tagService.getTag(tagId, token)
  } catch (error) {
    return handleCatchBlock(error, thunkAPI, true)
  }
})

// Update tag
export const updateTag = createAsyncThunk(
  'tags/update',
  async (tag, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await tagService.updateTag(tag.tagId, tag.tagData, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Delete tag
export const deleteTag = createAsyncThunk(
  'tags/delete',
  async (tagId, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await tagService.deleteTag(tagId, token)
  }
)

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    tagReset: state => initialState,
    addNewTagToState: (state, action) => {
      state.tags.push(action.payload) // Assuming action.payload contains new Earth data
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createTag.pending, state => {
        state.tagLoading = true
      })
      .addCase(createTag.fulfilled, (state, action) => {
        state.tagLoading = false
        state.tagSuccess = true
        tagSlice.caseReducers.addNewTagToState(state, action)
        state.tag = action.payload
      })
      .addCase(createTag.rejected, (state, action) => {
        state.tagLoading = false
        state.tagError = true
        state.message = action.payload
      })
      .addCase(getTags.pending, state => {
        state.tagLoading = true
      })
      .addCase(getTags.fulfilled, (state, action) => {
        state.tagLoading = false
        state.tagSuccess = true
        state.tags = action.payload
      })
      .addCase(getTags.rejected, (state, action) => {
        state.tagLoading = false
        state.tagError = true
        state.message = action.payload
      })
      .addCase(getTag.pending, state => {
        state.tagLoading = true
      })
      .addCase(getTag.fulfilled, (state, action) => {
        state.tagLoading = false
        state.tagSuccess = true
        state.tag = action.payload
      })
      .addCase(getTag.rejected, (state, action) => {
        state.tagLoading = false
        state.tagError = true
        state.message = action.payload
      })
      .addCase(updateTag.pending, state => {
        state.tagLoading = true
      })
      .addCase(updateTag.fulfilled, (state, action) => {
        state.tagLoading = false
        state.tagSuccess = true
        state.tag = action.payload
      })
      .addCase(updateTag.rejected, (state, action) => {
        state.tagLoading = false
        state.tagError = true
        state.message = action.payload
      })
      .addCase(deleteTag.fulfilled, state => {
        state.tagSuccess = true
      })
  },
})

export const { tagReset, addNewTagToState } = tagSlice.actions
export default tagSlice.reducer
