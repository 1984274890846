import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { updateIssue, deleteIssue } from '../features/issues/issueSlice'
import { MdOutlineHourglassEmpty } from 'react-icons/md'
import { BsArrowsMove, BsFillXCircleFill, BsCheckCircle } from 'react-icons/bs'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { FaImage } from 'react-icons/fa6'

const VolumeIssueRow = ({
  issue,
  serie,
  onIssueSelect,
  updatedIssues,
  selectedIssues,
}) => {
  const dispatch = useDispatch()
  const [issueName, setIssueName] = useState(issue.name)
  const [isNameButtonVisible, setIsNameButtonVisible] = useState(false)
  const [isDateButtonVisible, setIsDateButtonVisible] = useState(false)
  const [originalName, setOriginalName] = useState(issue.name) // Store the original name
  const [issueDate, setIssueDate] = useState(issue.releaseDate)
  const [originalDate, setOriginalDate] = useState(issue.releaseDate)
  const [image, setImage] = useState(issue.imageUrl)
  const [isSelected, setIsSelected] = useState(false)

  const handleCheckboxChange = () => {
    setIsSelected(!isSelected)
    onIssueSelect(issue.id, !isSelected) // Notify parent component of the selection change
  }

  useEffect(() => {
    setIsSelected(selectedIssues.includes(issue.id))
  }, [selectedIssues, issue.id])
  const issueId = issue.id
  const { issueError, message } = useSelector(state => state.issues)

  const handleDateChange = e => {
    const { value } = e.target
    setIssueDate(value)
    setIsDateButtonVisible(value !== originalDate)
  }

  useEffect(() => {
    // Find the updated issue in the updatedIssues array
    const updatedIssue = updatedIssues.find(
      updatedIssue => updatedIssue.id === issue.id
    )

    // Update the state in VolumeIssueRow if the updatedIssue is found
    if (updatedIssue) {
      setIssueDate(updatedIssue.releaseDate)
      // Update other relevant state values if needed
    }
  }, [updatedIssues, issue.id])

  const onIssueSave = async e => {
    e.preventDefault()

    const issueData = {
      name: issueName,
      releaseDate: new Date(issueDate),
    }

    dispatch(updateIssue({ issueId, issueData }))

    if (issueError) {
      toast.error(message)
    } else {
      toast.success('Issue successfully updated!')
      setIsNameButtonVisible(false)
      setOriginalName(issueName) // Update the original name to the saved value
      setIsDateButtonVisible(false)
      setOriginalDate(issueDate) // Update the original date to the saved value
    }
  }

  // Delete issue
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteIssue(issueId))
    }
  }

  return (
    <div className='entity-table__row volume-table-row'>
      <input
        type='checkbox'
        className='entity-check'
        id={issue.id}
        checked={isSelected}
        onChange={handleCheckboxChange}
      />
      <label htmlFor={issue.id}></label>

      <Link to={`/series/${serie.id}/${issue.id}`}>
        {!image ? (
          <FaImage className='volume-table-image-x' />
        ) : (
          <img
            src={image}
            className='volume-table-image-2'
            alt='Main preview'
          />
        )}
      </Link>
      <div className='volume__container'>
        <input
          className='volume__title'
          placeholder='Volume name'
          value={issueName}
          onChange={e => {
            setIssueName(e.target.value)
            setIsNameButtonVisible(e.target.value !== originalName)
          }}
        />
        <button
          className={`btn-confirm btn-confirm-1 ${
            isNameButtonVisible ? '' : 'btn-invisible'
          }`}
          type='button'
          onClick={onIssueSave}
          disabled={!isNameButtonVisible}
        >
          <BsCheckCircle />
        </button>
      </div>
      <div className='volume__container'>
        <input
          type='date'
          className='input'
          id='date'
          placeholder='Date'
          autoComplete='off'
          value={format(new Date(issueDate), 'yyyy-MM-dd')}
          onChange={handleDateChange}
        />
        <button
          className={`btn-confirm btn-confirm-2 ${
            isDateButtonVisible ? '' : 'btn-invisible'
          }`}
          type='button'
          onClick={onIssueSave}
          disabled={!isDateButtonVisible}
        >
          <BsCheckCircle />
        </button>
      </div>

      <div className='volume-table-percent-box'>
        <MdOutlineHourglassEmpty className='volume-table-item-icon' />
        <div className='volume-table-item-text'>83%</div>
      </div>
      <label className='switch asc'>
        <input type='checkbox' />
        <span className='slider'></span>
      </label>
      <BsArrowsMove className='volume-table-item-button' />
      <button onClick={deleteHandler} className='volume-table-percent-box'>
        <BsFillXCircleFill className='volume-table-item-button' />
      </button>
    </div>
  )
}

export default VolumeIssueRow
