const DashMonthResult = () => {
  return (
    <>
      <div className='item month-results'>
        <h3 className='heading-tertiary-dark'>Current Month Results</h3>
        <div className='month-results__container'>
          <div className='month-results__loader'>
            <div className='month-results__value'>75%</div>
          </div>
          <div className='month-results__info'>
            150 / 200{' '}
            <span className='month-results__info--text'>
              We should increase activity!
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashMonthResult
