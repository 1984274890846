import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTrashAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSeries,
  deleteSerie,
  updateSerie,
} from '../../features/series/serieSlice'

const SeriesTableRow = ({ serie, isChecked, onCheckboxChange }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const seriesState = useSelector(state => state.series)
  const currentSerie = seriesState.series.find(s => s.id === serie.id)

  // Delete organzation
  const deleteHandler = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteSerie(serie.id))
      navigate('/series')
      dispatch(getSeries())
    }
  }

  // MANUAL CONTROL FUNCTION
  const updateManualControl = isChecked => {
    if (isChecked !== currentSerie?.checked) {
      dispatch(
        updateSerie({
          serieId: serie.id,
          serieData: { checked: isChecked },
        })
      ).then(() => {
        dispatch(getSeries())
        toast.success('Series successfully updated')
      })
    }
  }

  return (
    <>
      <div className='entity-table__row series-table-row'>
        <input
          type='checkbox'
          className='entity-check'
          id={serie.id}
          checked={isChecked}
          onChange={() => onCheckboxChange(serie.id)}
        />
        <label htmlFor={serie.id}></label>
        <Link to={`/series/${serie.id}`} className='entity-table__row__link'>
          {`${serie.name}`}
        </Link>
        <div>Marvel Comics</div>
        <div className='asc'>{serie.volumes.length}</div>
        <div className='asc'>0</div>
        <div className='asc'>
          {currentSerie?.checked ? 'Finished' : 'Unfinished'}
        </div>
        <label className='switch asc'>
          <input
            type='checkbox'
            checked={currentSerie?.checked || false}
            onChange={e => {
              const newState = e.target.checked
              updateManualControl(newState)
              onCheckboxChange(serie.id, newState)
            }}
          />
          <span className='slider'></span>
        </label>
        <div>{new Date(serie.updatedAt).toLocaleString('en-GB')}</div>
        <Popup
          trigger={
            <button className='user-nav__user-photo'>
              <BsThreeDotsVertical className='entity-table__settings-icon' />
            </button>
          }
          position='bottom right'
        >
          <button
            className='btn-inner btn-inner__table'
            onClick={deleteHandler}
          >
            <FaTrashAlt /> &nbsp; Delete
          </button>
        </Popup>
      </div>
    </>
  )
}

export default SeriesTableRow
