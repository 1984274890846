import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getAuthors,
  deleteAuthor,
  reset,
} from '../features/authors/authorSlice'
import {
  HiCheckCircle,
  HiXCircle,
  HiDatabase,
  HiAdjustments,
  HiFilter,
} from 'react-icons/hi'
import { FaEdit } from 'react-icons/fa'
import { BiSlider, BiSolidTrash } from 'react-icons/bi'
import Navigation from '../layout/Navigation'
import Topbar from '../layout/Topbar'
import AuthorsTableRow from '../components/entityTables/AuthorsTableRow'
import Spinner from '../components/Spinner'
import { toast } from 'react-toastify'

const AuthorsTable = () => {
  const { authors, authorLoading, authorSuccess } = useSelector(
    state => state.authors
  )
  const dispatch = useDispatch()

  const [searchTerm, setSearchTerm] = useState('')
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [checkedAuthors, setCheckedAuthors] = useState([])

  const onChange = e => {
    setSearchTerm(e.target.value)
  }

  const handleCheckboxChange = id => {
    if (checkedAuthors.includes(id)) {
      const updatedCheckedAuthors = checkedAuthors.filter(
        authorId => authorId !== id
      )
      setCheckedAuthors(updatedCheckedAuthors)
    } else {
      const updatedCheckedAuthors = [...checkedAuthors, id]
      setCheckedAuthors(updatedCheckedAuthors)
    }
  }

  // Delete fce for selected authors
  const deleteSelectedAuthors = async () => {
    if (window.confirm('Are you sure?'))
      try {
        const deleteRequests = checkedAuthors.map(authorId =>
          dispatch(deleteAuthor(authorId))
        )
        await Promise.all(deleteRequests)
        dispatch(getAuthors())
      } catch (error) {
        console.error('Chyba při mazání', error)
      }
    toast.success('Selected author(s) were successfully deleted!')
  }

  useEffect(() => {
    return () => {
      if (authorSuccess) {
        dispatch(reset())
      }
    }
  }, [dispatch, authorSuccess])

  useEffect(() => {
    dispatch(getAuthors())
  }, [dispatch])

  useEffect(() => {
    if (isCheckedAll) {
      setCheckedAuthors(authors.map(author => author.id))
    } else {
      setCheckedAuthors([])
    }
  }, [isCheckedAll, authors])

  if (authorLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <div className='table-bookmark'>
            <button className='table-bookmark__button table-bookmark__button--active'>
              <HiDatabase className='table-bookmark__icon table-bookmark__icon--active' />{' '}
              All Authors
            </button>
            <button className='table-bookmark__button'>
              <HiCheckCircle className='table-bookmark__icon' /> Fullfilled
            </button>
            <button className='table-bookmark__button'>
              <HiXCircle className='table-bookmark__icon' /> Unfinished
            </button>
          </div>
          <div className='authors-bulk'>
            <div className='entity-search'>
              <HiFilter className='entity-search__icon' />
              <input
                type='text'
                className='entity-search__input'
                placeholder='Filter authors'
                onChange={onChange}
              />
              <div className='entity-search__filter-box'>
                <HiAdjustments className='entity-search__filter-box__icon' />
              </div>
            </div>
            <button className='btn btn-l'>
              <FaEdit className='button-box__icon' />
              Bulk edit
            </button>
          </div>
          <div className='authors'>
            <div className='entity-table'>
              <div className='entity-table__header authors-table-row'>
                <input
                  type='checkbox'
                  className='entity-check'
                  id='selectAll'
                  checked={isCheckedAll}
                  onChange={() => setIsCheckedAll(!isCheckedAll)}
                />
                <label htmlFor='selectAll'></label>
                <div>Name</div>
                <div>Roles</div>
                <div className='asc'>Appearances</div>
                <div className='asc'>Status</div>
                <div className='asc'>Manual control</div>
                <div>Last Update</div>
                <div>Actions</div>
              </div>
            </div>
            {authors
              .filter(author => {
                if (searchTerm === '') {
                  return author
                } else if (
                  `${author.firstName} ${author.lastName}`
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ) {
                  return author
                }
              })
              .map(author => (
                <AuthorsTableRow
                  key={author.id}
                  author={author}
                  isChecked={checkedAuthors.includes(author.id)}
                  onCheckboxChange={handleCheckboxChange}
                />
              ))
              .reverse()}
            <div className='table-footer'>
              <button
                type='button'
                className='add-issue-btn add-issue-btn-purple'
                disabled='true'
              >
                <BiSlider className='add-issue-btn-icon' /> mass editing
              </button>
              <button
                type='button'
                className='add-issue-btn add-issue-btn-red'
                onClick={deleteSelectedAuthors}
                disabled={checkedAuthors.length === 0}
              >
                <BiSolidTrash className='add-issue-btn-icon' /> delete selected
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AuthorsTable
