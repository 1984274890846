import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getBooks, deleteBook, reset } from '../features/books/bookSlice'
import { getPublishings } from '../features/publishings/publishingSlice'
import {
  HiCheckCircle,
  HiXCircle,
  HiDatabase,
  HiAdjustments,
  HiFilter,
} from 'react-icons/hi'
import { BiSlider, BiSolidTrash } from 'react-icons/bi'
import { FaEdit } from 'react-icons/fa'
import Navigation from '../layout/Navigation'
import Topbar from '../layout/Topbar'
import BooksTableRow from '../components/entityTables/BooksTableRow'
import Spinner from '../components/Spinner'
import { toast } from 'react-toastify'

const BooksTable = () => {
  const { books, bookLoading, bookSuccess, publishings } = useSelector(
    state => state.books
  )

  const [searchTerm, setSearchTerm] = useState('')
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [checkedBooks, setCheckedBooks] = useState([])
  const [filterFullfilled, setFilterFullfilled] = useState(false)
  const [filterUnfinished, setFilterUnfinished] = useState(false)
  const [activeFilter, setActiveFilter] = useState('all')
  const dispatch = useDispatch()

  const onChange = e => {
    setSearchTerm(e.target.value)
  }

  const handleCheckboxChange = id => {
    if (checkedBooks.includes(id)) {
      const updatedCheckedBooks = checkedBooks.filter(bookId => bookId !== id)
      setCheckedBooks(updatedCheckedBooks)
    } else {
      const updatedCheckedBooks = [...checkedBooks, id]
      setCheckedBooks(updatedCheckedBooks)
    }
  }

  // Delete fce for selected series
  const deleteSelectedBooks = async () => {
    if (window.confirm('Are you sure?'))
      try {
        const deleteRequests = checkedBooks.map(bookId =>
          dispatch(deleteBook(bookId))
        )
        await Promise.all(deleteRequests)
        dispatch(getBooks())
      } catch (error) {
        console.error('Chyba při mazání', error)
      }
    toast.success('Selected book(s) were successfully deleted!')
  }

  useEffect(() => {
    return () => {
      if (bookSuccess) {
        dispatch(reset())
      }
    }
  }, [dispatch, bookSuccess])

  useEffect(() => {
    dispatch(getBooks())
    dispatch(getPublishings())
  }, [dispatch])

  useEffect(() => {
    if (isCheckedAll) {
      setCheckedBooks(books.map(book => book.id))
    } else {
      setCheckedBooks([])
    }
  }, [isCheckedAll, books])

  if (bookLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <div className='table-bookmark'>
            <button
              className={`table-bookmark__button ${
                activeFilter === 'all' ? 'table-bookmark__button--active' : ''
              }`}
              onClick={() => {
                setActiveFilter('all')
                setFilterFullfilled(false)
                setFilterUnfinished(false)
              }}
            >
              <HiDatabase
                className={`table-bookmark__icon ${
                  activeFilter === 'all' ? 'table-bookmark__icon--active' : ''
                }`}
              />{' '}
              All books
            </button>
            <button
              className={`table-bookmark__button ${
                activeFilter === 'fullfilled'
                  ? 'table-bookmark__button--active'
                  : ''
              }`}
              onClick={() => {
                setActiveFilter('fullfilled')
                setFilterFullfilled(true)
                setFilterUnfinished(false)
              }}
            >
              <HiCheckCircle
                className={`table-bookmark__icon ${
                  activeFilter === 'fullfilled'
                    ? 'table-bookmark__icon--active'
                    : ''
                }`}
              />{' '}
              Fullfilled
            </button>
            <button
              className={`table-bookmark__button ${
                activeFilter === 'unfinished'
                  ? 'table-bookmark__button--active'
                  : ''
              }`}
              onClick={() => {
                setActiveFilter('unfinished')
                setFilterFullfilled(false)
                setFilterUnfinished(true)
              }}
            >
              <HiXCircle
                className={`table-bookmark__icon ${
                  activeFilter === 'unfinished'
                    ? 'table-bookmark__icon--active'
                    : ''
                }`}
              />{' '}
              Unfinished
            </button>
          </div>
          <div className='authors-bulk'>
            <div className='entity-search'>
              <HiFilter className='entity-search__icon' />
              <input
                type='text'
                className='entity-search__input'
                placeholder='Filter books'
                onChange={onChange}
              />
              <div className='entity-search__filter-box'>
                <HiAdjustments className='entity-search__filter-box__icon' />
              </div>
            </div>
            <button className='btn btn-l'>
              <FaEdit className='button-box__icon' />
              Bulk edit
            </button>
          </div>
          <div className='authors'>
            <div className='entity-table'>
              <div className='entity-table__header books-table-row'>
                <input
                  type='checkbox'
                  className='entity-check'
                  id='selectAll'
                  checked={isCheckedAll}
                  onChange={() => setIsCheckedAll(!isCheckedAll)}
                />
                <label htmlFor='selectAll'></label>
                <div>Name</div>
                <div>Publishing</div>
                <div className='asc'>Status</div>
                <div className='asc'>Manual control</div>
                <div>Last Updated</div>
                <div>Actions</div>
              </div>
            </div>
            {books
              .filter(book => {
                if (activeFilter === 'fullfilled' && book.checked) {
                  return true
                } else if (activeFilter === 'unfinished' && !book.checked) {
                  return true
                } else if (activeFilter === 'all') {
                  return true
                } else {
                  return false
                }
              })
              .filter(book => {
                if (searchTerm === '') {
                  return true
                } else if (
                  `${book.name}`
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ) {
                  return true
                } else {
                  return false
                }
              })
              .map(book => (
                <BooksTableRow
                  key={book.id}
                  book={book}
                  publishings={publishings}
                  isChecked={checkedBooks.includes(book.id)}
                  onCheckboxChange={handleCheckboxChange}
                />
              ))
              .reverse()}
            <div className='table-footer'>
              <button
                type='button'
                className='add-issue-btn add-issue-btn-purple'
                disabled='true'
              >
                <BiSlider className='add-issue-btn-icon' /> mass editing
              </button>
              <button
                type='button'
                className='add-issue-btn add-issue-btn-red'
                onClick={deleteSelectedBooks}
                disabled={checkedBooks.length === 0}
              >
                <BiSolidTrash className='add-issue-btn-icon' /> delete selected
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BooksTable
