import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import characterService from './characterService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  characters: [],
  character: {},
  characterError: false,
  characterSuccess: false,
  characterLoading: false,
  message: '',
}

// Create character
export const createCharacter = createAsyncThunk(
  'characters/create',
  async (character, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await characterService.createCharacter(character, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get all characters
export const getCharacters = createAsyncThunk(
  'characters/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await characterService.getCharacters(token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get character
export const getCharacter = createAsyncThunk(
  'characters/get',
  async (characterId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await characterService.getCharacter(characterId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Update character
export const updateCharacter = createAsyncThunk(
  'characters/update',
  async (character, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await characterService.updateCharacter(
        character.characterId,
        character.characterData,
        token
      )
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Delete character
export const deleteCharacter = createAsyncThunk(
  'characters/delete',
  async (characterId, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await characterService.deleteCharacter(characterId, token)
  }
)

export const characterSlice = createSlice({
  name: 'character',
  initialState,
  reducers: {
    reset: state => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(createCharacter.pending, state => {
        state.characterLoading = true
      })
      .addCase(createCharacter.fulfilled, (state, action) => {
        state.characterLoading = false
        state.characterSuccess = true
        state.character = action.payload
      })
      .addCase(createCharacter.rejected, (state, action) => {
        state.characterLoading = false
        state.characterError = true
        state.message = action.payload
        state.character = null
      })
      .addCase(getCharacters.pending, state => {
        state.characterLoading = true
      })
      .addCase(getCharacters.fulfilled, (state, action) => {
        state.characterLoading = false
        state.characterSuccess = true
        state.characters = action.payload
      })
      .addCase(getCharacters.rejected, (state, action) => {
        state.characterLoading = false
        state.characterError = true
        state.message = action.payload
      })
      .addCase(getCharacter.pending, state => {
        state.characterLoading = true
      })
      .addCase(getCharacter.fulfilled, (state, action) => {
        state.characterLoading = false
        state.characterSuccess = true
        state.character = action.payload
      })
      .addCase(getCharacter.rejected, (state, action) => {
        state.characterLoading = false
        state.characterError = true
        state.message = action.payload
      })
      .addCase(updateCharacter.pending, state => {
        state.characterLoading = true
      })
      .addCase(updateCharacter.fulfilled, (state, action) => {
        state.characterLoading = false
        state.characterSuccess = true
        state.character = action.payload
      })
      .addCase(updateCharacter.rejected, (state, action) => {
        state.characterLoading = false
        state.characterError = true
        state.message = action.payload
      })
      .addCase(deleteCharacter.fulfilled, state => {
        state.characterSuccess = true
      })
  },
})

export const { reset } = characterSlice.actions
export default characterSlice.reducer
