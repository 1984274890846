const DashRank = () => {
  return (
    <>
      <div className='item rank'>
        <div className='rank__heading'>
          <h3 className='heading-tertiary-dark'>Publishing Rank</h3>
          <div className='overview__filter'>Series</div>
        </div>

        <div className='rank__container'>
          <div className='rank__item'>
            <div className='rank__name'>Marvel Comics</div>
            <div className='rank__value'>2.987</div>
          </div>
          <div className='rank__item'>
            <div className='rank__name'>DC Comics</div>
            <div className='rank__value'>2.690</div>
          </div>
          <div className='rank__item'>
            <div className='rank__name'>Dark Horse Comics</div>
            <div className='rank__value'>988</div>
          </div>
          <div className='rank__item'>
            <div className='rank__name'>Image Comics</div>
            <div className='rank__value'>512</div>
          </div>
          <div className='rank__item'>
            <div className='rank__name'>IDW Publishing</div>
            <div className='rank__value'>499</div>
          </div>
          <div className='rank__item'>
            <div className='rank__name'>Valiant Comics</div>
            <div className='rank__value'>312</div>
          </div>
          <div className='rank__item'>
            <div className='rank__name'>Dynamite Comics</div>
            <div className='rank__value'>272</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashRank
