import axios from 'axios'

const backendURL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${backendURL}/api/v1/crossovers/`

// Create crossover
const createCrossover = async (crossover, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.post(API_URL, crossover, config)
  return response.data
}

// Get all crossovers
const getCrossovers = async token => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.get(API_URL, config)
  return response.data
}

// Get crossover
const getCrossover = async (crossoverId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + crossoverId, config)
  return response.data
}

// Update crossover
const updateCrossover = async (crossoverId, crossoverData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.put(API_URL + crossoverId, crossoverData, config)

  return response.data
}

// Delete crossover
const deleteCrossover = async (crossoverId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + crossoverId, config)
  return response.data
}

const crossoverService = {
  createCrossover,
  getCrossovers,
  getCrossover,
  updateCrossover,
  deleteCrossover,
}

export default crossoverService
