import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authorRoleService from './authorRoleService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  authorRoles: [],
  authorRole: {},
  authorRoleError: false,
  authorRoleSuccess: false,
  authorRoleLoading: false,
  message: '',
}

// Create Author Role
export const createAuthorRole = createAsyncThunk(
  'authorRoles/create',
  async (authorRole, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await authorRoleService.createAuthorRole(authorRole, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get all Author Roles
export const getAuthorRoles = createAsyncThunk(
  'authorRoles/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await authorRoleService.getAuthorRoles(token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Get Author Role
export const getAuthorRole = createAsyncThunk(
  'authorRoles/get',
  async (authorRoleId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await authorRoleService.getAuthorRole(authorRoleId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Update Author Role
export const updateAuthorRole = createAsyncThunk(
  'authorRoles/update',
  async (authorRole, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await authorRoleService.updateAuthorRole(
        authorRole.authorRoleId,
        authorRole.authorRoleData,
        token
      )
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// Delete publishing
export const deleteAuthorRole = createAsyncThunk(
  'authorRoles/delete',
  async (authorRoleId, thunkAPI) => {
    const token = thunkAPI.getState().users.auth.credentials.refreshToken
    return await authorRoleService.deleteAuthorRole(authorRoleId, token)
  }
)

export const authorRoleSlice = createSlice({
  name: 'authorRole',
  initialState,
  reducers: {
    reset: state => initialState,
    addNewAuthorRoleToState: (state, action) => {
      state.authorRoles.push(action.payload) // Assuming action.payload contains new Earth data
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createAuthorRole.pending, state => {
        state.authorRoleLoading = true
      })
      .addCase(createAuthorRole.fulfilled, (state, action) => {
        state.authorRoleLoading = false
        state.authorRoleSuccess = true
        authorRoleSlice.caseReducers.addNewAuthorRoleToState(state, action)
        state.authorRole = action.payload
      })
      .addCase(createAuthorRole.rejected, (state, action) => {
        state.authorRoleLoading = false
        state.authorRoleError = true
        state.message = action.payload
        state.authorRole = null
      })
      .addCase(getAuthorRoles.pending, state => {
        state.authorRoleLoading = true
      })
      .addCase(getAuthorRoles.fulfilled, (state, action) => {
        state.authorRoleLoading = false
        state.authorRoleSuccess = true
        state.authorRoles = action.payload
      })
      .addCase(getAuthorRoles.rejected, (state, action) => {
        state.authorRoleLoading = false
        state.authorRoleError = true
        state.message = action.payload
      })
      .addCase(getAuthorRole.pending, state => {
        state.authorRoleLoading = true
      })
      .addCase(getAuthorRole.fulfilled, (state, action) => {
        state.authorRoleLoading = false
        state.authorRoleSuccess = true
        state.authorRole = action.payload
      })
      .addCase(getAuthorRole.rejected, (state, action) => {
        state.authorRoleLoading = false
        state.authorRoleError = true
        state.message = action.payload
      })
      .addCase(updateAuthorRole.pending, state => {
        state.authorRoleLoading = true
      })
      .addCase(updateAuthorRole.fulfilled, (state, action) => {
        state.authorRoleLoading = false
        state.authorRoleSuccess = true
        state.authorRole = action.payload
      })
      .addCase(updateAuthorRole.rejected, (state, action) => {
        state.authorRoleLoading = false
        state.authorRoleError = true
        state.message = action.payload
      })
      .addCase(deleteAuthorRole.fulfilled, state => {
        state.authorRoleSuccess = true
      })
  },
})

export const { reset, addNewAuthorRoleToState } = authorRoleSlice.actions
export default authorRoleSlice.reducer
