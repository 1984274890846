import axios from 'axios'

const backendURL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${backendURL}/api/v1/tags/`

// Create tag
const createTag = async (tag, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.post(API_URL, tag, config)
  return response.data
}

// Get all tags
const getTags = async token => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.get(API_URL, config)
  return response.data
}

// Get tag
const getTag = async (tagId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL + tagId, config)
  return response.data
}

// Update tag
const updateTag = async (tagId, tagData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  const response = await axios.put(API_URL + tagId, tagData, config)

  return response.data
}

// Delete tag
const deleteTag = async (tagId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + tagId, config)
  return response.data
}

const authorService = {
  createTag,
  getTags,
  getTag,
  updateTag,
  deleteTag,
}

export default authorService
