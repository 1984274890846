import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Navigation from '../layout/Navigation';
import Topbar from '../layout/Topbar';
import Spinner from '../components/Spinner';
import { toast } from 'react-toastify';
import StoryBlock from '../components/StoryBlock';
import Select from 'react-select';
import ImageUploader from '../components/ImageUploader';
import CreatableSelect from 'react-select/creatable';
import { MdDelete } from 'react-icons/md';
import { HiSave, HiOfficeBuilding } from 'react-icons/hi';
import {
  FaTags,
  FaCalendarAlt,
  FaPenAlt,
  FaCodeBranch,
  FaBookOpen,
} from 'react-icons/fa'
import { HiXCircle } from 'react-icons/hi'
import { SiUnity } from 'react-icons/si'
import { getTags, createTag, addNewTagToState } from '../features/tags/tagSlice'
import { getIssue, updateIssue } from '../features/issues/issueSlice'
import {
  getPublishings,
  getPublishing,
} from '../features/publishings/publishingSlice'
import { createStory, deleteStory } from '../features/stories/storySlice'
import {
  getEvents,
  createEvent,
  addNewEventToState,
} from '../features/events/eventSlice';
import {
  getStoryArcs,
  createStoryArc,
  addNewStoryArcToState,
} from '../features/storyArcs/storyArcSlice'
import { getAuthors } from '../features/authors/authorSlice'
import { uploadImage } from '../features/images/imageSlice'
import { format } from 'date-fns'
import Breadcrumbs from '../layout/Breadcrumbs'

const IssueDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { issueId } = params;

  useEffect(() => {
    dispatch(getIssue(issueId))
    dispatch(getPublishings())
    dispatch(getTags())
    dispatch(getEvents())
    dispatch(getStoryArcs())
    dispatch(getAuthors())
  }, [dispatch, issueId])

  const { issue, issueError, issueLoading, message } = useSelector(
    state => state.issues
  )

  useEffect(() => {
    if (issue.publishing) {
      dispatch(getPublishing(issue.publishing.id))
    }
  }, [dispatch, issue])

  const { serie } = useSelector(state => state.series)
  const { publishings } = useSelector(state => state.publishings)
  const { tags } = useSelector(state => state.tags)
  const { events } = useSelector(state => state.events)
  const { storyArcs } = useSelector(state => state.storyArcs)
  const { authors } = useSelector(state => state.authors)

  const { authorRoles } = useSelector(state => state.publishings.publishing)

  const [name, setName] = useState('')
  const [releaseDate, setReleaseDate] = useState('')
  const [publishing, setPublishing] = useState('')
  const [stories, setStories] = useState([])
  const [imageId, setImageId] = useState()
  const [imageURL, setImageURL] = useState('')
  const [eventIds, setEventIds] = useState([])
  const [storyArcIds, setStoryArcIds] = useState([])
  const [tagIds, setTagIds] = useState([])


  // CHECKBOX SELECT - DISABLE / AVAILABLE STATUS
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // Set default form data from authors reducer
  useEffect(() => {
    setName(issue.name && issue.name);
    setReleaseDate(
      issue.releaseDate
        ? format(new Date(issue.releaseDate), 'yyyy-MM-dd')
        : undefined
    );
    setImageURL(!issue.imageUrl ? '' : issue.imageUrl);
    setStories(!issue.stories ? [] : issue.stories);
    setTagIds(issue.tags?.map((tag) => ({ id: tag.id, name: tag.name })));
    setPublishing(!issue.publishing ? null : issue.publishing);
    setEventIds(
      issue.events?.map((event) => ({ id: event.id, name: event.name }))
    );
    setStoryArcIds(!issue.storyArcs ? [] : issue.storyArcs[0]);
  }, [dispatch, issue]);

  const addStory = () => {
    const newStory = {
      name: 'Story',
      issueId,
    };

    const updatedStories = [...stories, newStory];
    setStories(updatedStories);

    dispatch(
      createStory({
        name: newStory.name,
        issueId: newStory.issueId,
      })
    );
  };

  const onDeleteStory = (storyId) => {
    const confirmed = window.confirm('Are you sure?');

    if (confirmed) {
      const updatedStories = stories.filter((story) => story.id !== storyId);
      setStories(updatedStories);
      dispatch(deleteStory(storyId));
    }
  };

  // UPLOAD ISSUES MAIN COVER
  const handleImageUpload = async (file) => {
    try {
      const response = await dispatch(uploadImage(file));
      const uploadedImage = response.payload[0];

      if (uploadedImage) {
        setImageId(uploadedImage.id);
        toast.success('Image uploaded successfully!');
      } else {
        console.error('Failed to create image or no ID returned.');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleImageChange = () => {
    setImageURL('');
  };

  // CREATE NEW EVENT AND ASSIGNE IT TO ISSUE
  const handleCreateEvent = async (inputValue) => {
    try {
      const { payload: createdEvent } = await dispatch(
        createEvent({ name: inputValue })
      );
      if (createdEvent && createdEvent.id) {
        dispatch(addNewEventToState(createdEvent));
        setEventIds((prevEventIds) => [
          ...prevEventIds,
          { id: createdEvent.id, name: createdEvent.name },
        ]);
      } else {
        console.error('Failed to create Event or no Event ID returned.');
      }
    } catch (error) {
      console.error('Error creating Event:', error);
    }
  };

  // CREATE NEW STORY ARC AND ASSIGNE IT TO ISSUE
  const handleCreateStoryArc = async (inputValue) => {
    try {
      const { payload: createdStoryArc } = await dispatch(
        createStoryArc({ name: inputValue })
      );
      if (createdStoryArc && createdStoryArc.id) {
        dispatch(addNewStoryArcToState(createdStoryArc));
        setStoryArcIds(createdStoryArc); // Update directly with the object
      } else {
        console.error(
          'Failed to create Story Arc or no Story Arc ID returned.'
        );
      }
    } catch (error) {
      console.error('Error creating Story Arc:', error);
    }
  };

  // CREATE NEW TAG AND ASSIGNE IT TO PUBLISHING
  const handleCreateTag = async (inputValue) => {
    try {
      const { payload: createdTag } = await dispatch(
        createTag({ name: inputValue })
      );
      if (createdTag && createdTag.id) {
        dispatch(addNewTagToState(createdTag));
        setTagIds((prevTagIds) => [
          ...prevTagIds,
          { id: createdTag.id, name: createdTag.name },
        ]);
      } else {
        console.error('Failed to create Tag or no Tag ID returned.');
      }
    } catch (error) {
      console.error('Error creating Tag:', error);
    }
  };

  // UPDATE ISSUE
  const onSubmit = async e => {
    e.preventDefault()

    const issueData = {
      name,
      releaseDate: new Date(releaseDate),
      mainCoverImageId: imageId,
      eventIds: !eventIds ? null : eventIds.map(({ id }) => id),
      storyArcIds: !storyArcIds ? [] : [storyArcIds.id],
      tagIds: tagIds.map(({ id }) => id),
      publishingId: !publishing ? undefined : publishing.id,
    }
    await dispatch(getPublishing(issueData.publishingId))
    await dispatch(updateIssue({ issueId, issueData }))

    if (issueError) {
      toast.error(message);
    } else {
      toast.success('Series successfully updated!');
    }
  };

  const breadcrumbsPath = ['series', `${serie.name}`, `${issue.name}`];

  if (issueLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <Breadcrumbs path={breadcrumbsPath} />
          <form onSubmit={onSubmit}>
            <div className='detail-form grid-1'>
              <div className='detail-form__heading'>
                <div className='heading-form'>
                  <FaBookOpen className='detail-form__icon' /> {issue.name}
                </div>
                <div className='entity-info bold'>
                  <p className='entity-info__id'>ID: {issue.id}</p>
                  <div className='entity-info__slug mar-left-xs'>
                    {' '}
                    {issue.slug}
                  </div>
                </div>
              </div>

              <div className='detail-form__user-info'>
                <p className='pad-bottom-xs mar-left-s'>
                  Created:{' '}
                  <span className='bold'>
                    {new Date(issue.createdAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by <span className='bold'>Stan Lee</span>
                </p>
                <p className='mar-left-s'>
                  Updated:{' '}
                  <span className='bold'>
                    {new Date(issue.updatedAt).toLocaleString('en-GB')}
                  </span>{' '}
                  by <span className='bold'>Stan Lee</span>
                </p>
              </div>

              <div className='detail-form__button-box'>
                <button type='submit' className='btn-new btn-save mar-right-xs'>
                  <HiSave className='button-icon-new' />
                </button>
                <button
                  type='button'
                  className='btn-new btn-delete'
                  // onClick={deleteHandler}
                >
                  <MdDelete className='button-icon-new' />
                </button>
              </div>

              {!imageURL ? (
                <ImageUploader onImageUpload={handleImageUpload} />
              ) : (
                <>
                  <div className='image-upload container'>
                    <label className='entity-label'>cover</label>
                    <img
                      src={imageURL}
                      alt='Main preview'
                      className='img-entity'
                    />
                    <HiXCircle
                      className='btn-img-del'
                      onClick={handleImageChange}
                    />
                  </div>
                </>
              )}

              <div className='detail-form__main container'>
                <label htmlFor='basicInfo' className='entity-label'>
                  Basic Info
                </label>
                <div className='input-box'>
                  <FaBookOpen className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='name' className='input__label'>
                      Issue Title
                    </label>
                    <input
                      type='text'
                      className='input'
                      id='title'
                      placeholder='Issue Title'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete='off'
                      required
                    />
                  </div>
                  <div className='required-mark-box'>
                    <span className='required-mark'>&#10044;</span>
                  </div>
                </div>
                <div className='input-box'>
                  <FaCalendarAlt className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='date' className='input__label'>
                      Release Date
                    </label>
                    <input
                      type='date'
                      className='input'
                      id='date'
                      placeholder='Date'
                      autoComplete='off'
                      value={releaseDate}
                      onChange={(e) => setReleaseDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className='input-box-1'>
                  <FaTags className='input__icon' />
                  <div className='input-area-multi'>
                    <label htmlFor='tags' className='input__label'>
                      Tags
                    </label>
                    <CreatableSelect
                      className='react-select-container'
                      classNamePrefix='react-select'
                      placeholder='Add Tags'
                      isMulti
                      options={tags}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      value={tagIds}
                      onCreateOption={handleCreateTag} // Handle tag creation
                      onChange={(e) => setTagIds(e)}
                      isValidNewOption={(inputValue) => inputValue}
                      getNewOptionData={(inputValue) => ({
                        value: inputValue,
                        name: `Create ${inputValue}`,
                      })}
                      menuPortalTarget={document.body} // Set menuPortalTarget to document.body
                    />
                  </div>
                </div>
                <div className='input-box'>
                  <HiOfficeBuilding className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='publishing' className='input__label'>
                      Publishing
                    </label>
                    <Select
                      className='react-single-select'
                      classNamePrefix='react-single-select'
                      placeholder='Add Publishing'
                      options={publishings}
                      value={publishing}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      onChange={setPublishing}
                      menuPortalTarget={document.body} // Set menuPortalTarget to document.body
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <SiUnity className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='publishing' className='input__label'>
                      Event
                    </label>
                    <CreatableSelect
                      className='react-single-select'
                      classNamePrefix='react-single-select'
                      placeholder='Add Events'
                      isMulti
                      options={events}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      value={eventIds}
                      onCreateOption={handleCreateEvent}
                      onChange={(e) => setEventIds(e)}
                      isValidNewOption={(inputValue) => inputValue}
                      getNewOptionData={(inputValue) => ({
                        value: inputValue,
                        name: `Create ${inputValue}`,
                      })}
                      menuPortalTarget={document.body} // Set menuPortalTarget to document.body
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <FaPenAlt className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='publishing' className='input__label'>
                      Story-Arc
                    </label>
                    <CreatableSelect
                      className='react-single-select'
                      classNamePrefix='react-single-select'
                      placeholder='Add Story-Arc'
                      options={storyArcs}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      value={storyArcIds}
                      onCreateOption={handleCreateStoryArc}
                      onChange={(e) => setStoryArcIds(e)}
                      isValidNewOption={(inputValue) => inputValue}
                      getNewOptionData={(inputValue) => ({
                        value: inputValue,
                        name: `Create ${inputValue}`,
                      })}
                      menuPortalTarget={document.body} // Set menuPortalTarget to document.body
                    />
                  </div>
                </div>

                <div className='input-box'>
                  <FaCodeBranch className='input__icon' />
                  <div className='input-area'>
                    <label htmlFor='publishing' className='input__label'>
                      Story Tie-In
                    </label>
                    <Select
                      className='react-single-select'
                      classNamePrefix='react-single-select'
                      placeholder='Add Story Tie-In'
                      // options={publishings}
                      // value={publishingId}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      // onChange={setPublishingId}
                      // menuPortalTarget={document.body} // Set menuPortalTarget to document.body
                      // ref={selectRef} // Attach the ref to CreatableSelect
                    />
                  </div>
                </div>

                <div
                  className={isChecked ? 'input-box' : 'input-box-unchecked'}
                >
                  <input
                    type='checkbox'
                    className='entity-check'
                    id='ch-reprint'
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor='ch-reprint'></label>
                  <div className='input-area-checkbox'>
                    <label
                      htmlFor='reprint'
                      className={
                        isChecked ? 'input__label' : 'input__label-disabled'
                      }
                    >
                      Reprint
                    </label>
                    <Select
                      id='reprint'
                      className='react-single-select'
                      classNamePrefix='react-single-select'
                      placeholder='Choose original issue'
                      isDisabled={!isChecked}
                      // value={defaultEarth}
                      // options={publishingEarths}
                      // getOptionLabel={({ name }) => name}
                      // getOptionValue={({ id }) => id}
                      // onChange={(e) => setDefaultEarth(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              {stories.map((story) => (
                <StoryBlock
                  key={story.id}
                  story={story}
                  onDeleteStory={onDeleteStory}
                  initialAuthorRoles={authorRoles}
                  authors={authors}
                  //onDeleteVolume={onDeleteVolume} // Pass the deleteVolume function
                />
              ))}
              <div className='add-volume-block'>
                <button
                  type='button'
                  className='add-volume-btn'
                  onClick={addStory}
                >
                  +
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default IssueDetail;
