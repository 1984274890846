const DashLastAdded = () => {
  return (
    <>
      <div className='item last-added'>
        <h3 className='heading-3--light'>Last Added Series</h3>
      </div>
    </>
  )
}

export default DashLastAdded
