import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getEarths, deleteEarth, reset } from '../features/earths/earthSlice'
import { getPublishings } from '../features/publishings/publishingSlice'
import {
  HiCheckCircle,
  HiXCircle,
  HiDatabase,
  HiAdjustments,
  HiFilter,
} from 'react-icons/hi'
import { FaEdit } from 'react-icons/fa'
import { BiSlider, BiSolidTrash } from 'react-icons/bi'
import Navigation from '../layout/Navigation'
import Topbar from '../layout/Topbar'
import EarthsTableRow from '../components/entityTables/EarthsTableRow'
import Spinner from '../components/Spinner'
import { toast } from 'react-toastify'

const EarthsTable = () => {
  const { earths, earthLoading, earthSuccess, publishings } = useSelector(
    state => state.earths
  )

  const [searchTerm, setSearchTerm] = useState('')
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [checkedEarths, setCheckedEarths] = useState([])

  const dispatch = useDispatch()

  const onChange = e => {
    setSearchTerm(e.target.value)
  }

  const handleCheckboxChange = id => {
    if (checkedEarths.includes(id)) {
      const updatedCheckedEarths = checkedEarths.filter(
        earthId => earthId !== id
      )
      setCheckedEarths(updatedCheckedEarths)
    } else {
      const updatedCheckedEarths = [...checkedEarths, id]
      setCheckedEarths(updatedCheckedEarths)
    }
  }

  // Delete fce for selected earths
  const deleteSelectedEarths = async () => {
    if (
      window.confirm(
        'You want to delete the earths connected to the publisher, are you sure??? '
      )
    )
      try {
        const deleteRequests = checkedEarths.map(earthId =>
          dispatch(deleteEarth(earthId))
        )
        await Promise.all(deleteRequests)
        dispatch(getEarths())
      } catch (error) {
        console.error('Chyba při mazání earthu', error)
      }
    toast.success('Selected earth were successfully deleted!')
  }

  useEffect(() => {
    return () => {
      if (earthSuccess) {
        dispatch(reset())
      }
    }
  }, [dispatch, earthSuccess])

  useEffect(() => {
    dispatch(getEarths())
    dispatch(getPublishings())
  }, [dispatch])

  useEffect(() => {
    if (isCheckedAll) {
      setCheckedEarths(earths.map(earth => earth.id))
    } else {
      setCheckedEarths([])
    }
  }, [isCheckedAll, earths])

  if (earthLoading) {
    return <Spinner />
  }

  return (
    <>
      <div className='page-container'>
        <Navigation />
        <Topbar />
        <div className='content'>
          <div className='table-bookmark'>
            <button className='table-bookmark__button table-bookmark__button--active'>
              <HiDatabase className='table-bookmark__icon table-bookmark__icon--active' />{' '}
              All Earths
            </button>
            <button className='table-bookmark__button'>
              <HiCheckCircle className='table-bookmark__icon' /> Fullfilled
            </button>
            <button className='table-bookmark__button'>
              <HiXCircle className='table-bookmark__icon' /> Unfinished
            </button>
          </div>
          <div className='authors-bulk'>
            <div className='entity-search'>
              <HiFilter className='entity-search__icon' />
              <input
                type='text'
                className='entity-search__input'
                placeholder='Filter earths'
                onChange={onChange}
              />
              <div className='entity-search__filter-box'>
                <HiAdjustments className='entity-search__filter-box__icon' />
              </div>
            </div>
            <button className='btn btn-l'>
              <FaEdit className='button-box__icon' />
              Bulk edit
            </button>
          </div>
          <div className='authors'>
            <div className='entity-table'>
              <div className='entity-table__header earths-table-row'>
                <input
                  type='checkbox'
                  className='entity-check'
                  id='selectAll'
                  checked={isCheckedAll}
                  onChange={() => setIsCheckedAll(!isCheckedAll)}
                />
                <label htmlFor='selectAll'></label>
                <div>Name</div>
                <div>Publishings</div>
                <div className='asc'>Status</div>
                <div className='asc'>Manual control</div>
                <div>Last Updated</div>
                <div>Actions</div>
              </div>
            </div>
            {earths
              .filter(earth => {
                if (searchTerm === '') {
                  return earth
                } else if (
                  `${earth.name}`
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ) {
                  return earth
                }
              })
              .map(earth => (
                <EarthsTableRow
                  key={earth.id}
                  earth={earth}
                  publishings={publishings}
                  isChecked={checkedEarths.includes(earth.id)}
                  onCheckboxChange={handleCheckboxChange}
                />
              ))
              .reverse()}
            <div className='table-footer'>
              <button
                type='button'
                className='add-issue-btn add-issue-btn-purple'
                disabled='true'
              >
                <BiSlider className='add-issue-btn-icon' /> mass editing
              </button>
              <button
                type='button'
                className='add-issue-btn add-issue-btn-red'
                onClick={deleteSelectedEarths}
                disabled={checkedEarths.length === 0}
              >
                <BiSolidTrash className='add-issue-btn-icon' /> delete selected
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EarthsTable
